import { Button, HotelBaseInfo } from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

import * as S from "./ProfileOfferHotelDetails.styled";

interface ProfileOfferHotelDetailsProps {
  id: number;
  setActiveHotel: (id: number) => void;
}

const ProfileOfferHotelDetails = ({
  id,
  setActiveHotel,
}: ProfileOfferHotelDetailsProps) => {
  const { t } = useTranslation();

  return (
    <S.DetailsWrapper>
      <div>
        <S.Heading>{t("profileOffer.hotel")}</S.Heading>
        <HotelBaseInfo id={id} />
      </div>
      <Button variant="outlined" onClick={() => setActiveHotel(id)}>
        {t("profileOffer.hotelDetails")}
      </Button>
    </S.DetailsWrapper>
  );
};

export default ProfileOfferHotelDetails;
