import { ActivityOptionsParams, ActivityService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useActivityOptions = (params: ActivityOptionsParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["activity_options", params],
    queryFn: () => ActivityService.getOptions(params),
    enabled:
      !!params?.searchId &&
      !!params?.id &&
      !!params?.date &&
      (params?.pickUpRequired ? !!params?.pickUpLocation : true),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useActivityOptions;
