import {
  ApiResponseSearchResults,
  PlaceType,
  RateType,
  TransferSearchQuery,
  TransferTripType,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";

const TRANSFER_API_PATH = "transfers";

export enum TransferDirection {
  Outbound = 1,
  Return = 2,
}

// @todo: can we move to a numbers enum?
export enum TransferType {
  Private = "private",
  Shared = "shared",
}

export enum TransferConfirmationStatus {
  NotConfirmed = 1,
  ConfirmedWithNumber = 2,
  ConfirmedWithoutNumber = 3,
  NoFlightTracking = 4,
}

export interface TransferInner {
  price: number;
  priceNet: number;
  priceBase: number;
  remarks: string[];
  provider: string;
  // @note: The whole model is returned, commented out on purpose
  // date: null;
  // time: null;
  // pickup: {
  //   date: null;
  //   time: null;
  //   images: null;
  //   remarks: string;
  // };
  // emergencyNumber: null;
}

export interface TransferOutbound extends TransferInner {
  direction: TransferDirection.Outbound;
}

export interface TransferReturn extends TransferInner {
  direction: TransferDirection.Return;
}

export interface Transfer {
  id: number;
  name: string;
  type: TransferType;
  tripType: TransferTripType;
  image: string;
  vehicle: string;
  deadline: string;
  provider: string;
  rateType: RateType;
  modificationTime: number;
  cancellationTime: number;
  minPassengers: number;
  maxPassengers: number;
  price: number;
  priceNet: number;
  priceBase: number;
  outboundTransfer: TransferOutbound;
  returnTransfer: TransferReturn;
  // @note: The whole model is returned, commented out on purpose
  // pickUp: null;
  // dropOff: null;
  // adults: null;
  // children: null;
  // names: null;
  // search: null;
  // flights: null;
  // nameType: null;
  // phoneCode: null;
  // phoneNumber: null;
}

export interface TransferLocation {
  placeId: number;
  placeType: PlaceType;
}

export interface TransferSearchPayload extends TransferSearchQuery {
  useCache?: boolean;
}

export interface TransferSearchResponse
  extends ApiResponseSearchResults<Transfer> {
  // @todo: add the correct typings for the filters
  filters: any;
}

export interface SearchInfoResponse extends TransferSearchPayload {}

const TransferService = {
  search: (payload: TransferSearchPayload) => {
    return FetchService.post<TransferSearchResponse>(
      `${TRANSFER_API_PATH}/availability`,
      payload,
    );
  },
  getSearchInfo: (searchId: number) => {
    return FetchService.get<SearchInfoResponse>(
      `${TRANSFER_API_PATH}/availability/${searchId}`,
    );
  },
  getDetails: (id: string) => {
    return FetchService.get<Transfer>(`${TRANSFER_API_PATH}/${id}`);
  },
};

export default TransferService;
