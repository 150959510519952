import { borderRadius, pxToRem } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { ifProp, theme } from "styled-tools";

export const Wrapper = styled.div<{ $isActive: boolean | undefined }>`
  display: inline-block;
  background-color: ${ifProp(
    "$isActive",
    theme("palette.sky"),
    theme("palette.ocean"),
  )};
  padding: ${pxToRem(2)} ${pxToRem(6)};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: ${borderRadius.medium}px;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 2px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14);

  line-height: 1;
  font-size: ${pxToRem(14)};
  font-weight: 600;
  color: ${theme("palette.white")};
`;
