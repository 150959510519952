import { useReservation } from "@hotelspoint/api";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import ProfileReservationSummary from "../ProfileReservationSummary";
import * as S from "./ProfileReservationActivityBaseInfo.styled";

interface ProfileReservationActivityBaseInfoProps {
  id: number;
}

const ProfileReservationActivityBaseInfo = ({
  id,
}: ProfileReservationActivityBaseInfoProps) => {
  const { t } = useTranslation();

  const [reservation, isLoading] = useReservation(id);

  const combinedPassengerData = useMemo(() => {
    if (!reservation?.itemData) return [];

    return reservation.itemData.passengers.flatMap((group, groupIndex) =>
      group.map(
        (entry: { pickupOption: any; name: string; surname: string }) => ({
          ticketName: reservation.itemData.tickets[groupIndex].name,
          fullName: `${entry.name} ${entry.surname}`,
          ...(entry.pickupOption && {
            pickupOptionName: reservation.itemData.tickets[
              groupIndex
            ].requirements.pickupOptions.find(
              (item: { id: string }) => item.id === entry.pickupOption,
            ).name,
          }),
          ...entry,
        }),
      ),
    );
  }, [reservation]);

  const headers = useMemo(() => {
    if (!combinedPassengerData.length) return [];

    return Array.from(
      new Set(combinedPassengerData?.flatMap(item => Object.keys(item))),
    );
  }, [combinedPassengerData]);

  return (
    <S.Container>
      <S.Card>
        <ProfileReservationSummary id={id} />
        <div>
          <S.Headline>
            {t("profileReservationActivityBaseInfo.passengersInfo")}
          </S.Headline>
          <S.PassengersWrapper>
            {headers
              .filter(
                header =>
                  !["title", "name", "surname", "pickupOption"].includes(
                    header,
                  ),
              )
              .map(header => (
                <div key={header}>
                  <S.Title>
                    {t(`profileReservationActivityBaseInfo.headers.${header}`)}
                  </S.Title>
                  {combinedPassengerData?.map((row, rowIndex) => (
                    <p key={rowIndex} style={{ marginBottom: 8 }}>
                      {header === "nationality"
                        ? t(`countries.${row[header]}`)
                        : (row[header] ?? "-")}
                    </p>
                  ))}
                </div>
              ))}
          </S.PassengersWrapper>
        </div>
        <S.Divider />
        <div>
          <S.Headline>
            {t("profileReservationActivityBaseInfo.contacts.title")}
          </S.Headline>
          <S.ContactsWrapper>
            <div>
              <S.ContactsTitle>
                {t("profileReservationActivityBaseInfo.contacts.name")}
              </S.ContactsTitle>
              {isLoading ? (
                <Skeleton />
              ) : (
                <p>
                  {reservation?.itemData?.contacts?.name}{" "}
                  {reservation?.itemData?.contacts?.surname}
                </p>
              )}
            </div>
            <div>
              <S.ContactsTitle>
                {t("profileReservationActivityBaseInfo.contacts.phone")}
              </S.ContactsTitle>
              {isLoading ? (
                <Skeleton />
              ) : (
                <p>
                  {reservation?.itemData?.contacts?.phoneCode}{" "}
                  {reservation?.itemData?.contacts?.phoneNumber}
                </p>
              )}
            </div>
            <div>
              <S.ContactsTitle>
                {t("profileReservationActivityBaseInfo.contacts.email")}
              </S.ContactsTitle>
              {isLoading ? (
                <Skeleton />
              ) : (
                <p>{reservation?.itemData?.contacts?.email}</p>
              )}
            </div>
          </S.ContactsWrapper>
        </div>
        {reservation?.itemData?.pickUpLocation?.code !== "undefined" && (
          <>
            <S.Divider />
            <div>
              <S.Headline>
                {t("profileReservationActivityBaseInfo.pickupLocation")}
              </S.Headline>
              <p>{reservation?.itemData?.pickUpLocation?.name}</p>
            </div>
          </>
        )}
        {reservation?.itemData?.questions.length !== 0 && (
          <>
            <S.Divider />
            <div>
              <S.Headline>
                {t("profileReservationActivityBaseInfo.questions")}
              </S.Headline>
              {reservation?.itemData?.questions.map((question, questionKey) => {
                const matchingOption =
                  reservation?.itemData?.options?.questions.find(
                    (option: { code: string }) => option.code === question.code,
                  );

                if (!matchingOption) return null;

                return (
                  <div key={questionKey}>
                    <S.Question>{matchingOption.text}</S.Question>
                    <S.Answer>{question.value}</S.Answer>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {/* @todo: Implement this, after the API is ready */}
        {/* {reservation?.itemData?.remarks.length !== 0 && (
          <>
            <S.Divider />
            <div>
              <S.Headline>
                {t("profileReservationActivityBaseInfo.importantInformation")}
              </S.Headline>
              {reservation?.itemData?.remarks.map((remark, remarkKey) => (
                <p key={remarkKey}>{remark}</p>
              ))}
            </div>
          </>
        )} */}
      </S.Card>
    </S.Container>
  );
};

export default ProfileReservationActivityBaseInfo;
