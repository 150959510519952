import {
  ButtonEdit,
  Chip,
  TABLE_COLUMN_SIZE,
  TableCell,
  TableCellEmpty,
} from "@hotelspoint/components";
import { AgencyInList } from "@hotelspoint/types";
import {
  formatDate,
  getAgencyStatusColor,
  getAgencyStatusName,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { type TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<AgencyInList>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminAgencies.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("name", {
    header: t("adminAgencies.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("email", {
    header: t("adminAgencies.columns.email"),
    size: TABLE_COLUMN_SIZE.EMAIL,
    cell: props => props.getValue() || <TableCellEmpty />,
  }),
  columnHelper.accessor("status", {
    header: t("adminAgencies.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getAgencyStatusColor(status)}>
          {t(getAgencyStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("markupHotelsBase", {
    header: t("adminAgencies.columns.markupHotelsBase"),
    size: 60,
  }),
  columnHelper.accessor("markupHotels", {
    header: t("adminAgencies.columns.markupHotels"),
    size: 60,
  }),
  columnHelper.accessor("markupTransfersBase", {
    header: t("adminAgencies.columns.markupTransfersBase"),
    size: 60,
  }),
  columnHelper.accessor("markupTransfers", {
    header: t("adminAgencies.columns.markupTransfers"),
    size: 60,
  }),
  columnHelper.accessor("dateCreated", {
    header: t("adminAgencies.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <Link to={`/admin/agencies/${id}`}>
            <ButtonEdit />
          </Link>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
