import {
  handleError,
  useAdminSupplierStatement,
  useUpdateAdminSupplierStatement,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSelect,
  FormTextArea,
  LoaderBlock,
} from "@hotelspoint/components";
import {
  CHIP_COLORS,
  STATEMENT_SUPPLIER_OPTIONS,
  SUPPLIER_STATUS_OPTIONS,
} from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import { getSupplierStatementStatusColor } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminSupplierStatementForm.util";

interface AdminSupplierStatementProps {
  id: number;
}

const AdminSupplierStatementForm = ({ id }: AdminSupplierStatementProps) => {
  const { t } = useTranslation();

  const [statement, isLoading] = useAdminSupplierStatement(id);
  const [updateStatement, isUpdating] = useUpdateAdminSupplierStatement(id);

  const formValues = useMemo(() => {
    if (!statement) return defaultValues;

    return entity2Form(statement);
  }, [statement]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await updateStatement(payload);
        toast.success(t("toast.adminSupplierStatement.update"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, updateStatement],
  );

  const statementSupplierOptions = STATEMENT_SUPPLIER_OPTIONS.map(option => ({
    ...option,
    label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
  }));

  const statusOptions = useMemo(
    () =>
      SUPPLIER_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getSupplierStatementStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  if (isLoading) {
    return <LoaderBlock />;
  }

  return (
    <Form defaultValues={formValues} validationSchema={validationSchema}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="supplierId"
              label={t("adminSupplierStatement.form.supplierId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  options={statementSupplierOptions}
                  readOnly
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="supplierStatementNumber"
              label={t(
                "adminSupplierStatement.form.supplierStatementNumber.label",
              )}
            >
              {props => <FormInput {...props} />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminSupplierStatement.form.status.label")}
            >
              {props => (
                <FormSelect {...props} options={statusOptions} readOnly />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="totalAmount"
              label={t("adminSupplierStatement.form.totalAmount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="remainingAmount"
              label={t("adminSupplierStatement.form.remainingAmount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="payedAmount"
              label={t("adminSupplierStatement.form.payedAmount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="reservationsAmount"
              label={t("adminSupplierStatement.form.reservationsAmount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="additionalCharges"
              label={t("adminSupplierStatement.form.additionalCharges.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                  readOnly
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="comments"
              label={t("adminSupplierStatement.form.comments.label")}
            >
              {props => <FormTextArea {...props} readOnly />}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isUpdating}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminSupplierStatement.form.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminSupplierStatementForm;
