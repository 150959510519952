import {
  handleError,
  InvoiceStatus,
  PaymentType,
  useApproveInvoice,
  useInvoice,
  useRegenerateInvoice,
  useRejectInvoice,
  useUploadInvoiceFile,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  ConfirmDialog,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormDatePickerSingle,
  FormInput,
  FormLayout,
  FormSelect,
  FormUploadButton,
  LoaderBlock,
  StatusChip,
  StatusFlavour,
} from "@hotelspoint/components";
import {
  INVOICE_STATUS_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  RESERVATION_STATUS_OPTIONS,
} from "@hotelspoint/constants";
import {
  createFormData,
  getInvoiceStatusColor,
  getReservationStatusColor,
  isNumeric,
} from "@hotelspoint/utils";
import { useCallback, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import useInvoiceQueryParams from "../useInvoiceQueryParams";
import {
  defaultValues,
  entity2Form,
  form2EntityApprove,
  form2EntityRegenerate,
  FormValues,
  getValidationSchema,
} from "./AdminInvoiceForm.util";

interface AdminInvoiceFormProps {
  id: number;
}

const AdminInvoiceFormInner = ({ id }: AdminInvoiceFormProps) => {
  const { t } = useTranslation();

  const [, setQuery] = useInvoiceQueryParams();
  const [amount] = useWatch({ name: ["amount"] });

  const [invoice] = useInvoice(id);

  const [regenerateInvoice, isRegenerating] = useRegenerateInvoice(id);
  const [approveInvoice, isApproving] = useApproveInvoice(id);
  const [rejectInvoice, isRejecting] = useRejectInvoice(id);
  const [uploadFile, isUploadingFile] = useUploadInvoiceFile(id);

  const isCreditCardPayment = useMemo(
    () => invoice?.paymentType === PaymentType.CreditCard,
    [invoice],
  );

  const handleRegenerate = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2EntityRegenerate(formValues);

        // Upload a file if the user has selected one
        if (formValues.file) {
          const formData = createFormData(formValues.file);

          await uploadFile(formData);
        }

        await regenerateInvoice(payload);

        toast.success(t("toast.adminInvoice.regenerate"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, uploadFile, regenerateInvoice],
  );

  const handleApprove = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2EntityApprove(formValues);

        await approveInvoice(payload);

        toast.success(t("toast.adminInvoice.approve"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, approveInvoice],
  );

  const handleReject = useCallback(async () => {
    try {
      await rejectInvoice();
      toast.success(t("toast.adminInvoice.reject"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, rejectInvoice]);

  const typeOptions = useMemo(
    () =>
      PAYMENT_TYPE_OPTIONS.map(type => ({
        ...type,
        label: t(type.label),
      })),
    [t],
  );

  const invoiceStatusOptions = useMemo(
    () =>
      INVOICE_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <StatusChip $variant={getInvoiceStatusColor(status.value)}>
            {t(status.label)}
          </StatusChip>
        ),
      })),
    [t],
  );

  const reservationStatusOptions = useMemo(
    () =>
      RESERVATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getReservationStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  // Set the form value to the query param for the invoice amount
  useEffect(() => {
    if (isNumeric(amount)) {
      setQuery({ amount });
    }
  }, [amount, setQuery]);

  if (!invoice) return null;

  return (
    <FormLayout>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="id" label={t("adminInvoice.form.id.label")}>
            {props => <FormInput {...props} readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="fullNumber"
            label={t("adminInvoice.form.fullNumber.label")}
          >
            {props => <FormInput {...props} readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="reservationId"
            label={t("adminInvoice.form.reservationId.label")}
          >
            {props => <FormInput {...props} readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="reservationStatus"
            label={t("adminInvoice.form.reservationStatus.label")}
          >
            {props => (
              <FormSelect
                {...props}
                options={reservationStatusOptions}
                readOnly
                disabled
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="paymentId"
            label={t("adminInvoice.form.paymentId.label")}
          >
            {props => <FormInput {...props} readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="paymentType"
            label={t("adminInvoice.form.paymentType.label")}
          >
            {props => <FormSelect {...props} options={typeOptions} readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="status"
            label={t("adminInvoice.form.status.label")}
          >
            {props => (
              <FormSelect
                {...props}
                readOnly
                disabled
                options={invoiceStatusOptions}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="agencyName"
            label={t("adminInvoice.form.agencyName.label")}
          >
            {props => <FormInput {...props} readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="mainInvoiceId"
            label={t("adminInvoice.form.mainInvoiceId.label")}
          >
            {props => <FormInput {...props} readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="amount"
            label={t("adminInvoice.form.amount.label")}
          >
            {props => (
              <FormInput
                {...props}
                adornmentStyle="filled"
                endAdornment={invoice?.currency}
                readOnly={invoice.status === InvoiceStatus.Cancelled}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="dateIssued"
            label={t("adminInvoice.form.dateIssued.label")}
          >
            {props => (
              <FormDatePickerSingle
                {...props}
                readOnly={
                  invoice.status === InvoiceStatus.Pending ||
                  invoice.status === InvoiceStatus.Cancelled
                }
              />
            )}
          </FormAdapter>
        </Box>
        {isCreditCardPayment && (
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="transactionId"
              label={t("adminInvoice.form.transactionId.label")}
            >
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
        )}
        <Box width={1} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="file" label={t("adminInvoice.form.file.label")}>
            {props => (
              <FormUploadButton
                {...props}
                isDisabled={invoice.status !== InvoiceStatus.Completed}
              />
            )}
          </FormAdapter>
        </Box>
      </Flex>
      <FormContext<FormValues>
        render={({ handleSubmit }) => (
          <div
            style={{
              display: "flex",
              gap: "8px",
            }}
          >
            {invoice.status === InvoiceStatus.Completed && (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isRegenerating || isUploadingFile}
                onClick={handleSubmit(handleRegenerate)}
              >
                {t("adminInvoice.actions.regenerate")}
              </Button>
            )}
            {invoice.status === InvoiceStatus.Pending && (
              <>
                <ConfirmDialog
                  title={t("adminInvoice.actions.approve.modal.title")}
                  description={t(
                    "adminInvoice.actions.approve.modal.description",
                  )}
                  onConfirm={handleSubmit(handleApprove)}
                  isLoading={isApproving}
                  status={StatusFlavour.Success}
                >
                  <Button variant="success">
                    {t("adminInvoice.actions.approve.button")}
                  </Button>
                </ConfirmDialog>
                <ConfirmDialog
                  title={t("adminInvoice.actions.reject.modal.title")}
                  description={t(
                    "adminInvoice.actions.reject.modal.description",
                  )}
                  onConfirm={handleReject}
                  isLoading={isRejecting}
                  status={StatusFlavour.Error}
                >
                  <Button variant="error">
                    {t("adminInvoice.actions.reject.button")}
                  </Button>
                </ConfirmDialog>
              </>
            )}
          </div>
        )}
      />
    </FormLayout>
  );
};

const AdminInvoiceForm = (props: AdminInvoiceFormProps) => {
  const [invoice, isLoadingInvoice] = useInvoice(props.id);

  const formValues = useMemo(() => {
    if (!invoice) return defaultValues;

    return entity2Form(invoice);
  }, [invoice]);

  const validationSchema = useMemo(() => {
    return getValidationSchema({
      dateRequired: invoice?.status === InvoiceStatus.Completed,
    });
  }, [invoice]);

  if (!invoice || isLoadingInvoice) {
    return <LoaderBlock />;
  }

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => <AdminInvoiceFormInner {...props} {...formMethods} />}
    </Form>
  );
};

export default AdminInvoiceForm;
