import { useUserCurrentOfferStore } from "@hotelspoint/store";
import { useMemo } from "react";

import * as S from "./UserCurrentOffer.styled";

const UserCurrentOffer = () => {
  const offer = useUserCurrentOfferStore(state => state.offer);

  const count = useMemo(() => {
    if (!offer || !offer?.items) return null;

    return offer.items.length;
  }, [offer]);

  if (!count) {
    return (
      <S.Wrapper>
        <S.IconInactive size={24} />
        <S.Count>0</S.Count>
      </S.Wrapper>
    );
  }

  return (
    <S.Link to="/profile/offers/create">
      <S.IconActive size={24} />
      <S.Count>{count}</S.Count>
    </S.Link>
  );
};

export default UserCurrentOffer;
