import { useHotelRate } from "@hotelspoint/api";
import { StatusBlock, StatusFlavour } from "@hotelspoint/components";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import SearchHotelBookForm from "./SearchHotelBookForm";
import * as S from "./SearchHotelBookForm/SearchHotelBookForm.styled";

interface SearchHotelBookContentProps {
  hotelId: number;
  searchId: number;
  rateId: number;
}

const SearchHotelBookContent = ({
  hotelId,
  searchId,
  rateId,
}: SearchHotelBookContentProps) => {
  const { t } = useTranslation();

  const [hotelRate, isLoadingHotelRate] = useHotelRate(
    searchId as number,
    hotelId as number,
    rateId as number,
  );

  if (!hotelRate) {
    return null;
  }

  return (
    <S.Card>
      <S.Title>
        {isLoadingHotelRate ? (
          <S.FlexGrow>
            <Skeleton />
          </S.FlexGrow>
        ) : (
          t("searchHotelBook.content.room", {
            count: hotelRate?.rooms.length,
          })
        )}
      </S.Title>

      {isLoadingHotelRate ? (
        <S.FlexGrow>
          <Skeleton height={60} />
        </S.FlexGrow>
      ) : (
        hotelRate?.warnings?.map((warning, warningIndex) => (
          <S.StatusBlockWrapper>
            <StatusBlock key={warningIndex} status={StatusFlavour.Warning}>
              <p>{warning}</p>
            </StatusBlock>
          </S.StatusBlockWrapper>
        ))
      )}

      <SearchHotelBookForm
        hotelRate={hotelRate}
        isLoading={isLoadingHotelRate}
      />
    </S.Card>
  );
};

export default SearchHotelBookContent;
