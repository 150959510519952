import "mapbox-gl/dist/mapbox-gl.css";

import { forwardRef } from "react";
import MapComponent, { MapProps } from "react-map-gl";

const Map = forwardRef<any, Partial<MapProps>>((props, ref) => {
  return (
    <MapComponent
      ref={ref}
      mapboxAccessToken={import.meta.env.VITE_APP_MAPBOX_TOKEN}
      attributionControl={false} // @todo: Check if this violates Mapbox's TOS
      mapStyle="mapbox://styles/mapbox/streets-v12"
      {...props}
    />
  );
});

export default Map;
