import { BGN, EUR } from "@dinero.js/currencies";
import { UserCurrency } from "@hotelspoint/types";
import { isNumeric, toFixedPrecise } from "@hotelspoint/utils";
import { Dinero, dinero, toSnapshot } from "dinero.js";

import { PriceObject, PriceValue } from "./types";

export const parseCurrencyToInt = (value: number): number => {
  const val = toFixedPrecise(value, 2);

  return val ? Math.round(parseFloat(val) * 100) : 0;
};

export const getDineroCurrency = (currency: UserCurrency) => {
  switch (currency) {
    case UserCurrency.BGN:
      return BGN;
    case UserCurrency.EUR:
      return EUR;

    default:
      throw new Error(
        `Price: Unable to parse the user's currency into the 'dinero' currency type - "${currency}"`,
      );
  }
};

export const createPriceObject = (value: number, currency: UserCurrency) => {
  return dinero({
    amount: parseCurrencyToInt(value),
    currency: getDineroCurrency(currency),
  });
};

export const isDineroObject = (value: any): boolean => {
  try {
    toSnapshot(value);
  } catch {
    return false;
  }

  return true;
};

export const priceFactoryFn = (value: PriceValue) => {
  if (typeof value === "number") {
    return createPriceObject(value, UserCurrency.EUR);
  }

  // Temporary workaround for API payloads prices being returned as strings
  if (typeof value === "string" && isNumeric(value)) {
    return createPriceObject(Number(value), UserCurrency.EUR);
  }

  if (isDineroObject(value)) {
    return value as Dinero<number>;
  }

  if (typeof value === "object") {
    const obj = value as PriceObject;
    return createPriceObject(obj.amount, obj.currency);
  }

  console.warn(`Price: Invalid price format - "${value}"!`);
  return createPriceObject(0, UserCurrency.EUR);
};
