import { mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${theme("palette.white")};
  /* @todo: make sure correct z-index is assigned */
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing(1.5)}px;
  border-bottom: 1px solid ${theme("palette.silver")};
`;

export const Title = styled.h5`
  font-weight: 700;
`;

export const InnerWrapper = styled.div`
  display: flex;
`;

export const Sidebar = styled.aside`
  @media ${mediaQuery.tablet} {
    width: 100%;
    max-width: ${theme("custom.sidebar.width")}px;
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(2.5)}px;
  }
`;

export const Content = styled.section`
  position: relative;

  @media ${mediaQuery.tablet} {
    display: block;
    width: calc(100% - ${theme("custom.sidebar.width")}px);
  }
`;

export const UpperControls = styled.div`
  position: absolute;
  top: ${spacing(2)}px;
  right: ${spacing(2)}px;
  left: ${spacing(2)}px;
`;

export const UpperMapControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
