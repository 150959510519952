import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
  container-type: inline-size;
  container-name: container;
`;

export const Wrapper = styled.div`
  @container container (min-width: 350px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: ${spacing(2)}px;
  }
`;

export const DatesWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing(2)}px;

  @container container (min-width: 350px) {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
  }
`;

export const SingleDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h6`
  margin-bottom: ${spacing(2)}px;
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const BoldHeadline = styled.h6`
  font-weight: 600;
`;

export const NightsWrapper = styled.div`
  display: flex;
  gap: ${spacing(4)}px;
  align-items: center;
  justify-content: flex-start;

  @container container (min-width: 350px) {
    justify-content: space-between;
  }
`;

export const Strong = styled.strong`
  font-weight: 700;
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 ${spacing(3)}px;
  background: ${theme("palette.silver")};
`;
