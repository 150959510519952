import { useHotelDetails } from "@hotelspoint/api";
import { Rating } from "@hotelspoint/components";
import Skeleton from "react-loading-skeleton";

import * as S from "./HotelBaseInfo.styled";

interface HotelBaseInfoProps {
  id: number;
}

const HotelBaseInfo = ({ id }: HotelBaseInfoProps) => {
  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(id);

  return (
    <S.Header>
      <S.HeaderRow>
        <S.FlexLength $loading={isLoadingHotelDetails}>
          <h3>{isLoadingHotelDetails ? <Skeleton /> : hotelDetails?.name}</h3>
        </S.FlexLength>
        <S.FlexLength $loading={isLoadingHotelDetails}>
          <Rating value={hotelDetails?.rating as number} />
        </S.FlexLength>
      </S.HeaderRow>
      <p>
        {isLoadingHotelDetails ? (
          <Skeleton />
        ) : (
          [hotelDetails?.address, hotelDetails?.cityName]
            .filter(Boolean)
            .join(", ")
        )}
      </p>
    </S.Header>
  );
};

export default HotelBaseInfo;
