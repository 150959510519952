import {
  ActivityContent,
  handleError,
  ReservationStatus,
  useCancelReservation,
  useReservation,
} from "@hotelspoint/api";
import {
  ActivityBaseInfo,
  ActivityBookPriceBreakdown,
  Button,
  CancelationPolicy,
  ConfirmDialog,
  StatusFlavour,
} from "@hotelspoint/components";
import { RateType } from "@hotelspoint/types";
import { IconCalendarX, IconTagFilled } from "@tabler/icons-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as S from "./ProfileReservationActivitySidebar.styled";

interface ProfileReservationActivitySidebarProps {
  id: number;
}

const ProfileReservationActivitySidebar = ({
  id,
}: ProfileReservationActivitySidebarProps) => {
  const { t } = useTranslation();

  const [reservation, isLoadingReservation] = useReservation(id);

  const [cancelReservation, isCancelingReservation] = useCancelReservation(id);

  const handleCancelReservation = useCallback(async () => {
    try {
      await cancelReservation();

      toast.success(t("toast.profileReservations.cancel"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, cancelReservation]);

  return (
    <div>
      <ActivityBaseInfo
        name={reservation?.itemData.name as string}
        place={reservation?.itemData.location as string}
        price={reservation?.itemData.price as number}
        priceNet={reservation?.itemData.priceNet as number}
        content={reservation?.itemData.content as ActivityContent[]}
        images={reservation?.itemData.images as string[]}
        session={reservation?.itemData.session}
        languages={reservation?.itemData.language}
        date={reservation?.dateFrom as string}
        optionName={reservation?.itemData.option.name}
        isLoading={isLoadingReservation}
      />
      <S.HeadlineWrapper>
        <IconCalendarX size={18} />
        <h6>{t("profileReservation.sidebar.cancelationPolicy")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <CancelationPolicy
          rateType={reservation?.itemData.rateType as RateType}
          deadline={reservation?.dateDeadline as string}
          isLoading={isLoadingReservation}
          footer={
            <ConfirmDialog
              title={t(
                "profileReservation.actions.cancelReservation.modal.title",
              )}
              description={t(
                "profileReservation.actions.cancelReservation.modal.description",
              )}
              onConfirm={handleCancelReservation}
              isLoading={isCancelingReservation}
              status={StatusFlavour.Error}
            >
              <Button
                variant="error"
                isDisabled={
                  reservation?.status === ReservationStatus.ConfirmedReservation
                }
              >
                {t("profileReservation.actions.cancelReservation.button")}
              </Button>
            </ConfirmDialog>
          }
        />
      </S.Card>
      <S.HeadlineWrapper>
        <IconTagFilled size={18} />
        <h6>{t("searchActivityBook.sidebar.priceBreakdown.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <ActivityBookPriceBreakdown
          tickets={reservation?.itemData.tickets as any[]}
        />
      </S.Card>
    </div>
  );
};

export default ProfileReservationActivitySidebar;
