import { NumberParam, StringParam, useQueryParams } from "use-query-params";

const useAdminSupplierStatementsParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    status: NumberParam,
    supplierId: NumberParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminSupplierStatementsParams;
