import { isValidElement } from "react";
import { type FilterOptionOption } from "react-select/dist/declarations/src/filters";

import { Option } from "../../common";

export const getSelectedOption = (
  options: Option[],
  selectedOption: Pick<Option, "value">,
) => {
  if (!selectedOption) return undefined;

  return options?.find(option => option.value === selectedOption.value);
};

export const getSelectedOptions = (
  options: Option[],
  selectedOptions: Pick<Option, "value">[],
) => {
  if (!selectedOptions || !Array.isArray(selectedOptions)) return [];

  return options.filter(({ value: optionValue }) => {
    return selectedOptions.some(({ value }) => optionValue === value);
  });
};

export const getOptionFromComponent = (
  option: FilterOptionOption<Option>,
  inputValue: string,
): boolean => {
  const { label } = option;

  let labelText = "";

  if (typeof label === "string") {
    labelText = label;
  } else if (isValidElement(label)) {
    const element = label as React.ReactElement;

    labelText = element.props?.children || "";
  }

  return labelText.toLowerCase().includes(inputValue.toLowerCase());
};
