import { useAgencies } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminSubAgenciesParams from "../useAdminSubAgenciesParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminSubAgenciesFilters.form";

interface AdminSubAgenciesFiltersProps {
  isLoading: boolean;
}

const AdminSubAgenciesFilters = ({
  isLoading,
}: AdminSubAgenciesFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminSubAgenciesParams();
  const [operatorAgencies, isLoadingOperatorAgencies] = useAgencies({
    isOperator: true,
  });

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const operatorAgencyOptions = useMemo(() => {
    if (!operatorAgencies || !operatorAgencies.results) return [];

    return operatorAgencies.results.map(agency => ({
      value: agency.id,
      label: agency.name,
    }));
  }, [operatorAgencies]);

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("adminSubAgencies.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminSubAgencies.filters.id.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminSubAgencies.filters.name.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminSubAgencies.filters.name.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>

          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="registerFrom"
              label={t("adminSubAgencies.filters.registerFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminSubAgencies.filters.registerFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="registerTo"
              label={t("adminSubAgencies.filters.registerTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminSubAgencies.filters.registerTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="operatorAgencyId"
              label={t("adminSubAgencies.filters.operatorAgencyId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminSubAgencies.filters.operatorAgencyId.placeholder",
                  )}
                  options={operatorAgencyOptions}
                  isLoading={isLoadingOperatorAgencies}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminSubAgencies.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminSubAgenciesFilters;
