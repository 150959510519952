import {
  useHotelAvailabilitySearch,
  useHotelDetails,
  usePlace,
} from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Container,
  GalleryOverlay,
  HotelAmenities,
  HotelBaseInfo,
  HotelSummary,
  MosaicGallery,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  PlaceTypeIcon,
  StatusBlock,
  StatusFlavour,
  TableSkeleton,
} from "@hotelspoint/components";
import { HotelRoomGuests, PlaceType } from "@hotelspoint/types";
import { ImageType } from "@hotelspoint/utils";
import { IconBed } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { HotelRoomsTable } from "../components";
import SearchHotelsForm from "../SearchHotelsResults/SearchHotelsForm";
import useHotelSearchParams from "../useHotelSearchParams";
import * as S from "./SearchHotelPage.styled";

interface SearchHotelPageParams extends Record<string, string> {
  id: string;
}

const SearchHotelPage = () => {
  const { t } = useTranslation();

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

  const { id } = useParams<SearchHotelPageParams>();

  const hotelId = Number(id);
  const [query] = useHotelSearchParams();

  const [search, isLoadingSearch, { refetch }] = useHotelAvailabilitySearch({
    placeId: hotelId,
    placeType: PlaceType.Hotel,
    checkIn: query.checkIn as string,
    checkOut: query.checkOut as string,
    nationality: query.nationality as string,
    rooms: query.rooms as HotelRoomGuests[],
    useAsync: false,
  });

  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(hotelId);

  const [place, isLoadingPlace] = usePlace({
    id: hotelId,
    type: PlaceType.Hotel,
  });

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchHotels.pageTitle"),
        href: "/search/hotels",
        icon: () => <IconBed size={18} />,
      },
      ...(place?.parents
        ? place.parents.map(placeParent => ({
            title: placeParent.name as string,
            icon: () => (
              <PlaceTypeIcon
                type={placeParent.type as PlaceType}
                iconProps={{ size: 18 }}
              />
            ),
          }))
        : []),
      {
        title: place?.name as string,
        icon: () => (
          <PlaceTypeIcon
            type={place?.type as PlaceType}
            iconProps={{ size: 18 }}
          />
        ),
      },
    ],
    [t, place],
  );

  const handleMosaicClick = (index: number) => {
    setIsGalleryOpen(true);
    setInitialGalleryIndex(index);
  };

  return (
    <>
      <GalleryOverlay
        isOpen={isGalleryOpen}
        images={hotelDetails?.images ?? []}
        imageType={ImageType.Hotel}
        initialSlideIndex={initialGalleryIndex}
        isLoading={isLoadingHotelDetails}
        onClose={() => setIsGalleryOpen(false)}
      />
      <PageWrapper>
        <Container>
          <PageSpacer>
            <BreadcrumbContainer>
              {isLoadingPlace ? (
                <Skeleton width={120} height={10} />
              ) : (
                <Breadcrumb items={breadcrumbs} />
              )}
            </BreadcrumbContainer>
            <PageSplitLayout>
              <PageSplitLayout.Sidebar>
                <S.Wrapper>
                  <SearchHotelsForm
                    refetch={refetch}
                    isLoading={isLoadingSearch}
                  />
                </S.Wrapper>
                {/* <S.Wrapper>
                  Filters
                </S.Wrapper> */}
              </PageSplitLayout.Sidebar>
              <PageSplitLayout.Content>
                <S.Wrapper>
                  <MosaicGallery
                    images={hotelDetails?.images ?? []}
                    isLoading={isLoadingHotelDetails}
                    imageType={ImageType.Hotel}
                    handleImageClick={handleMosaicClick}
                  />
                  <HotelBaseInfo id={hotelId} />
                  <S.Divider />
                  <HotelSummary id={hotelId} />
                  <S.Divider />
                  <HotelAmenities id={hotelId} />
                  <S.Divider />
                  {isLoadingSearch ? (
                    <TableSkeleton />
                  ) : search?.results.length === 0 ? (
                    <StatusBlock status={StatusFlavour.Warning}>
                      {t("searchHotels.noAvailableRooms")}
                    </StatusBlock>
                  ) : (
                    <HotelRoomsTable
                      searchId={search?.id as number}
                      hotelId={hotelId}
                    />
                  )}
                </S.Wrapper>
              </PageSplitLayout.Content>
            </PageSplitLayout>
          </PageSpacer>
        </Container>
      </PageWrapper>
    </>
  );
};

export default SearchHotelPage;
