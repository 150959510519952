import { ActivityOptionPrice, ActivityOptionQuestion } from "@hotelspoint/api";
import { formatDateIso, SCHEMA_EMAIL } from "@hotelspoint/utils";
import { array, date, InferType, object, string } from "yup";

const mapQuestions = (
  questions: ActivityOptionQuestion[],
  values: { [key: string]: any },
) => {
  return questions.map(question => {
    return {
      code: question.code,
      value: values[question.code],
    };
  });
};

export const getDynamicValidationSchema = (
  fieldConfig: ActivityOptionQuestion[],
) => {
  const dynamicSchema = validationSchema;

  const dynamicFields = fieldConfig.reduce(
    (accumulator: { [key: string]: any }, field) => {
      accumulator[field.code] = field.required ? string().required() : string();
      return accumulator;
    },
    {},
  );

  return dynamicSchema.shape(dynamicFields);
};

export const defaultValues = {
  passengers: [],
  pickupLocation: "",
  pickupOption: "",
  phoneCode: "+359",
  phoneNumber: "",
  email: "",
  // read only
  _pickupLocation: false,
  _pickupOption: false,
};

const personSchema = object()
  .shape({
    title: string().required(
      "searchHotelBook.content.form.title.validation.required",
    ),
    name: string().required(
      "searchHotelBook.content.form.firstName.validation.required",
    ),
    surname: string().required(
      "searchHotelBook.content.form.lastName.validation.required",
    ),
    birthday: date().when("_birthdays", {
      is: true,
      then: schema =>
        schema.required(
          "searchHotelBook.content.form.dateOfBirth.validation.required",
        ),
    }),
    nationality: string().when("_nationality", {
      is: true,
      then: schema =>
        schema.required(
          "searchActivityBook.content.form.nationality.validation.required",
        ),
    }),
    passportNumbers: string().when("_passportNumbers", {
      is: true,
      then: schema =>
        schema.required(
          "searchActivityBook.content.form.passportNumbers.validation.required",
        ),
    }),
  })
  .required();

export const validationSchema = object()
  .shape({
    passengers: array().of(array().of(personSchema)).required(),
    pickupLocation: string().when("_pickupLocation", {
      is: true,
      then: schema =>
        schema.required(
          "searchActivityBook.content.form.pickupLocation.validation.required",
        ),
    }),
    pickupOption: string().when("_pickupOption", {
      is: true,
      then: schema =>
        schema.required(
          "searchActivityBook.content.form.pickupOption.validation.required",
        ),
    }),
    phoneCode: string().required(
      "searchActivityBook.content.form.phoneCode.validation.required",
    ),
    phoneNumber: string().required(
      "searchActivityBook.content.form.phoneNumber.validation.required",
    ),
    email: SCHEMA_EMAIL,
  })
  .required();

export type FormValues = InferType<typeof validationSchema>;

// Here i'm removing the unnecessary underscore props, renaming the ages prop and format the birthday date

export const form2Entity = (
  values: FormValues,
  questions: ActivityOptionQuestion[],
) => {
  return {
    passengers: values.passengers.reduce(
      (acc: { [key: number]: any[] }, passengerGroup, index) => {
        if (Array.isArray(passengerGroup) && passengerGroup.length > 0) {
          acc[index] = passengerGroup.map((passenger, passengerIndex) => {
            // Remove properties starting with "_"
            let removeUnderscoreProps = Object.entries(passenger)
              .filter(([key]) => !key.startsWith("_"))
              .reduce((obj: { [key: string]: any }, [key, value]) => {
                obj[key] = value;
                return obj;
              }, {});

            if ("ages" in removeUnderscoreProps) {
              const { ages, ...rest } = removeUnderscoreProps;
              removeUnderscoreProps = { ...rest, age: ages };
            }

            return {
              ...removeUnderscoreProps,
              ...(removeUnderscoreProps.birthday
                ? { birthday: formatDateIso(removeUnderscoreProps.birthday) }
                : {}),
              ...(index === 0 && passengerIndex === 0 && values.pickupLocation
                ? { pickupLocation: values.pickupLocation }
                : {}),
              ...(index === 0 && passengerIndex === 0 && values.pickupOption
                ? { pickupOption: values.pickupOption }
                : {}),
            };
          });
        }
        return acc;
      },
      {},
    ),
    contacts: {
      email: values.email,
      phoneCode: values.phoneCode,
      phoneNumber: values.phoneNumber,
    },
    questions: mapQuestions(questions, values),
  };
};

// Because of the dynamic fields i'm adding a new prop for every dynamic field that need to be validate. That prop is with the same name but is starting with underscore

// Also i add the phone code that comes from the current agency.

export const entity2Form = (
  prices: ActivityOptionPrice[],
  phoneCode?: string,
): FormValues => {
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    passengers: prices.reduce((acc: { [key: number]: any[] }, item) => {
      acc[item.key] = Array.from({ length: item.numTickets }).map(
        (_, index) => {
          const passenger: { [key: string]: any } = {};

          for (const [key, value] of Object.entries(item.requirements)) {
            passenger[key === "ages" ? key : `_${key}`] = Array.isArray(value)
              ? value[index].toString()
              : value;
          }
          return passenger;
        },
      );
      return acc;
    }, []),
    pickupLocation: "",
    pickupOption: "",
    phoneCode: phoneCode ?? "+359",
    phoneNumber: "",
    email: "",
    // read only
    _pickupLocation: prices.some(
      item => item.requirements?.pickupLocation === true,
    ),
    _pickupOption: prices.some(
      item => item.requirements?.pickupOptions?.length > 0,
    ),
  };
};
