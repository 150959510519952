import { TransferSearchPayload } from "@hotelspoint/api";
import { formatTimeUtc } from "@hotelspoint/utils";
import { array, date, InferType, mixed, number, object, string } from "yup";

import { FormType } from "./types";

const personSchema = object({
  title: string().required(
    "searchTransferBook.content.form.title.validation.required",
  ),
  name: string().required(
    "searchTransferBook.content.form.firstName.validation.required",
  ),
  surname: string().required(
    "searchTransferBook.content.form.lastName.validation.required",
  ),
  age: number(),
}).required();

export const defaultValues = {
  type: FormType.AllPassengers,
  leader: [],
  adults: [],
  children: [],
  phoneCode: "+359",
  phoneNumber: "",
  outboundFlightNumber: "",
  returnFlightNumber: "",
  outboundDate: undefined,
  outboundTime: "",
  returnDate: undefined,
  returnTime: "",
};

export const validationSchema = object()
  .shape({
    type: mixed<FormType>().oneOf(
      Object.values(FormType).map(e => e as FormType),
    ),
    leader: array().when("type", {
      is: FormType.LeaderOnly,
      then: schema => schema.of(personSchema).required(),
    }),
    adults: array().when("type", {
      is: FormType.AllPassengers,
      then: schema => schema.of(personSchema).required(),
    }),
    children: array().when("type", {
      is: FormType.AllPassengers,
      then: schema => schema.of(personSchema).required(),
    }),
    phoneCode: string().required(
      "searchTransferBook.content.form.phoneCode.validation.required",
    ),
    phoneNumber: string().required(
      "searchTransferBook.content.form.phoneNumber.validation.required",
    ),
    outboundFlightNumber: string().required(
      "searchTransferBook.content.form.outboundFlightNumber.validation.required",
    ),
    returnFlightNumber: string().required(
      "searchTransferBook.content.form.returnFlightNumber.validation.required",
    ),
    outboundDate: date(),
    outboundTime: string(),
    returnDate: date(),
    returnTime: string(),
  })
  .required();

export type FormValues = InferType<typeof validationSchema>;

export const form2Entity = (values: FormValues) => {
  if (values.type === FormType.LeaderOnly && values.leader) {
    return {
      adults: values.leader,
      children: [],
      flights: {
        arrival: values.outboundFlightNumber,
        departure: values.returnFlightNumber,
      },
    };
  }

  return {
    adults: values.adults,
    children: values?.children,
    flights: {
      arrival: values.outboundFlightNumber,
      departure: values.returnFlightNumber,
    },
  };
};

export const entity2Form = (
  children: number[],
  formValues: TransferSearchPayload,
): FormValues => {
  return {
    type: FormType.AllPassengers,
    leader: [],
    adults: [],
    children: children.map((child: number) => {
      return {
        title: "",
        name: "",
        surname: "",
        age: child,
      };
    }),
    phoneCode: defaultValues.phoneCode,
    phoneNumber: "",
    outboundFlightNumber: "",
    returnFlightNumber: "",
    outboundDate: new Date(formValues.outboundDate),
    outboundTime: formValues.outboundDate
      ? formatTimeUtc(formValues.outboundDate)
      : undefined,
    returnDate: formValues.returnDate
      ? new Date(formValues.returnDate)
      : undefined,
    returnTime: formValues.returnDate
      ? formatTimeUtc(formValues.returnDate)
      : undefined,
  };
};
