import {
  ActivityContent,
  useActivityDetails,
  useActivityOptions,
  useActivitySearchInfo,
} from "@hotelspoint/api";
import {
  ActivityBaseInfo,
  ActivityBookPriceBreakdown,
  CancelationPolicy,
} from "@hotelspoint/components";
import { RateType } from "@hotelspoint/types";
import { IconCalendarX, IconTagFilled } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useSearchActivityBookParams from "../useSearchActivityBookParams";
import * as S from "./SearchActivityBookSidebar.styled";

interface SearchActivityBookSidebarParams extends Record<string, string> {
  id: string;
}

const SearchActivityBookSidebar = () => {
  const { t } = useTranslation();

  const { id } = useParams<SearchActivityBookSidebarParams>();
  const activityId = Number(id);

  const [{ searchId, date, session, languages, option, tickets }] =
    useSearchActivityBookParams();

  const [activityDetails, isLoadingActivityDetails] = useActivityDetails(
    searchId as number,
    activityId,
  );

  const [searchInfo, isLoadingSearchInfo] = useActivitySearchInfo(
    searchId as number,
  );

  const [activityOptions, isLoadingActivityOptions] = useActivityOptions({
    searchId: searchId as number,
    id: activityId,
    date: date as string,
  });

  const selectedOption = useMemo(() => {
    return activityOptions?.options.find(val => val.key === option);
  }, [activityOptions?.options, option]);

  return (
    <div>
      <ActivityBaseInfo
        name={activityDetails?.activity.name as string}
        place={searchInfo?.search.place.name as string}
        price={activityDetails?.activity.price as number}
        priceNet={activityDetails?.activity.priceNet as number}
        content={activityDetails?.activity.content as ActivityContent[]}
        images={activityDetails?.activity.images as string[]}
        session={session}
        languages={languages}
        date={date as string}
        optionName={selectedOption?.name}
        isLoading={isLoadingActivityDetails || isLoadingSearchInfo}
      />
      <S.HeadlineWrapper>
        <IconCalendarX size={18} />
        <h6>{t("searchActivityBook.sidebar.cancelationPolicy.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <CancelationPolicy
          rateType={
            selectedOption?.isRefundable
              ? RateType.Refundable
              : RateType.NonRefundable
          }
          deadline={selectedOption?.deadline as string}
          isLoading={isLoadingActivityOptions}
        />
      </S.Card>
      <S.HeadlineWrapper>
        <IconTagFilled size={18} />
        <h6>{t("searchActivityBook.sidebar.priceBreakdown.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <ActivityBookPriceBreakdown
          searchId={searchId as number}
          activityId={activityId}
          date={date as string}
          option={option as number}
          tickets={tickets as string}
        />
      </S.Card>
    </div>
  );
};

export default SearchActivityBookSidebar;
