import { formatDate, getNightsCount } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import StatusChip from "../../elements/StatusChip";
import VisibleDesktop from "../../misc/VisibleDesktop";
import * as S from "./HotelStayDurationDetails.styled";

interface HotelStayDurationDetailsProps {
  checkIn: string;
  checkOut: string;
  isLoading?: boolean;
}

const HotelStayDurationDetails = ({
  checkIn,
  checkOut,
  isLoading = false,
}: HotelStayDurationDetailsProps) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Wrapper>
        <S.DatesWrapper>
          <S.SingleDateWrapper>
            <S.Heading>
              {t("components.hotelStayDurationDetails.dates.checkIn")}
            </S.Heading>
            <S.BoldHeadline>
              {isLoading ? <Skeleton /> : formatDate(checkIn)}
            </S.BoldHeadline>
          </S.SingleDateWrapper>
          <VisibleDesktop>
            <S.Divider />
          </VisibleDesktop>
          <S.SingleDateWrapper>
            <S.Heading>
              {t("components.hotelStayDurationDetails.dates.checkOut")}
            </S.Heading>
            <S.BoldHeadline>
              {isLoading ? <Skeleton /> : formatDate(checkOut)}
            </S.BoldHeadline>
          </S.SingleDateWrapper>
        </S.DatesWrapper>
        <S.NightsWrapper>
          <p>{t("components.hotelStayDurationDetails.dates.lengthOfStay")}</p>
          <StatusChip>
            <S.Strong>
              {isLoading ? (
                <Skeleton />
              ) : (
                t("components.hotelStayDurationDetails.dates.night", {
                  count: getNightsCount(checkIn, checkOut),
                })
              )}
            </S.Strong>
          </StatusChip>
        </S.NightsWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default HotelStayDurationDetails;
