import { Paper } from "@hotelspoint/components";
import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
  container-type: inline-size;
  container-name: container;
`;

export const InformationWrapper = styled.div`
  @container container (min-width: 600px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const InformationActionWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(2)}px;
`;

export const InformationBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;
  @container container (min-width: 600px) {
    flex-direction: row;
    row-gap: 0;
    column-gap: ${spacing(2)}px;
    margin-bottom: 0;
  }
`;

export const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
`;

export const InformationBlockRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing(2)}px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme("palette.silver")};
`;

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(3)}px;
  padding: ${spacing(2)}px;
`;

export const Title = styled.h6`
  margin-bottom: ${spacing(2)}px;
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const RoomGuestsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing(2)}px;
  column-gap: ${spacing(2)}px;
`;

export const Room = styled.div`
  flex: 1;
  margin-bottom: ${spacing(1)}px;
`;

export const RoomNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing(0.5)}px;
`;

export const RoomName = styled.h6`
  font-weight: 600;
`;

export const RoomIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Guests = styled.div`
  flex: 1;
`;

export const GuestsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing(1.5)}px;
`;

export const GuestName = styled(RoomName)``;

export const VerticalDivider = styled.div`
  flex-basis: 1px;
  height: auto;
  background: ${theme("palette.silver")};
`;

export const VouchersWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(2)}px;
`;

export const VoucherActions = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(2)}px;
`;

export const StatusBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
`;

export const StatusBlockHeader = styled.h6`
  font-size: ${pxToRem(14)};
  font-weight: 600;
`;

export const StatusBlockCopy = styled.p`
  font-size: ${pxToRem(13)};
`;

export const MealTitle = styled.p`
  margin-bottom: ${spacing(0.5)}px;
`;
