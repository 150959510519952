import { ActivityContent } from "@hotelspoint/api";
import {
  SearchActivitiesResultsModalState,
  SearchActivitiesResultsModalType,
} from "@hotelspoint/types";
import {
  IconCalendar,
  IconClock,
  IconLanguage,
  IconMapPin,
  IconTicket,
} from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import Button from "../../elements/Button";
import ActivityDetailsGalleryModal from "../ActivityDetailsGalleryModal";
import * as S from "./ActivityBaseInfo.styled";

interface ActivityBaseInfoProps {
  name: string;
  price: number;
  priceNet: number;
  place: string;
  content: ActivityContent[];
  images: string[];
  session?: string | null;
  languages?: string | null;
  date: string;
  optionName?: string;
  isLoading: boolean;
}

const ActivityBaseInfo = ({
  name,
  place,
  price,
  priceNet,
  content,
  images,
  session,
  languages,
  date,
  optionName,
  isLoading,
}: ActivityBaseInfoProps) => {
  const { t } = useTranslation();

  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);
  const [activeModal, setActiveModal] = useState<
    SearchActivitiesResultsModalState | undefined
  >(undefined);

  return (
    <>
      <ActivityDetailsGalleryModal
        name={name}
        place={place}
        price={price}
        priceNet={priceNet}
        content={content}
        images={images}
        isLoading={isLoading}
        modalType={activeModal?.type as SearchActivitiesResultsModalType}
        setActiveModal={setActiveModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
      />
      <S.Card>
        <S.Title>{isLoading ? <Skeleton /> : name}</S.Title>
        <S.List>
          <S.ListItem>
            {isLoading ? (
              <Skeleton />
            ) : (
              <>
                <IconMapPin size={14} />
                {place}
              </>
            )}
          </S.ListItem>
          {session && (
            <S.ListItem>
              <IconClock size={14} />
              {session}
            </S.ListItem>
          )}
          {languages && (
            <S.ListItem>
              <IconLanguage size={14} />
              {languages}
            </S.ListItem>
          )}
          <S.ListItem>
            <IconCalendar size={14} />
            {date}
          </S.ListItem>
          {!isLoading && optionName && (
            <S.ListItem>
              <IconTicket size={14} />
              {optionName}
            </S.ListItem>
          )}
        </S.List>
        <div>
          {isLoading ? (
            <Skeleton width={150} height={40} />
          ) : (
            <Button
              variant="outlined"
              onClick={() =>
                setActiveModal({
                  type: SearchActivitiesResultsModalType.Details,
                })
              }
            >
              {t("searchActivityBook.sidebar.activityDetails")}
            </Button>
          )}
        </div>
      </S.Card>
    </>
  );
};

export default ActivityBaseInfo;
