import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${spacing(1)}px;
  column-gap: ${spacing(1)}px;

  @media ${mediaQuery.desktop} {
    flex-wrap: nowrap;
    row-gap: 0;
  }
`;

export const PriceTitle = styled.p`
  font-weight: 600;
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(16)};
`;

export const PriceTotal = styled(Price)`
  font-size: ${pxToRem(20)};
`;
