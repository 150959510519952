import {
  Box,
  Flex,
  FormAdapter,
  FormClearableInput,
  FormDatePickerSingle,
  FormTimePicker,
} from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

const OutboundTransferInfo = () => {
  const { t } = useTranslation();

  return (
    <Flex mx={[0, 0, -1, -1]}>
      <Box width={[1, 1, 6 / 14]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="outboundFlightNumber"
          label={t(
            "searchTransferBook.content.form.outboundFlightNumber.label",
          )}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t(
                "searchTransferBook.content.form.outboundFlightNumber.placeholder",
              )}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 4 / 14]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="outboundDate"
          label={t("searchTransferBook.content.form.outboundDate.label")}
        >
          {props => <FormDatePickerSingle {...props} readOnly />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 2 / 14]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="outboundTime"
          label={t("searchTransferBook.content.form.outboundTime.label")}
        >
          {props => <FormTimePicker {...props} readOnly />}
        </FormAdapter>
      </Box>
    </Flex>
  );
};

export default OutboundTransferInfo;
