import { IconX } from "@tabler/icons-react";

import { ModalSize } from "../../common";
import * as S from "./Modal.styled";

interface ModalProps {
  title: React.ReactNode;
  children: React.ReactNode;
  size?: ModalSize;
  onClose?: () => void;
}

const Modal = ({
  title,
  children,
  size = ModalSize.Large,
  onClose,
}: ModalProps) => {
  return (
    <S.Wrapper $size={size}>
      <S.Header>
        <S.ModalTitle>{title}</S.ModalTitle>
        {onClose && (
          <button type="button" onClick={onClose}>
            <IconX size={24} />
          </button>
        )}
      </S.Header>
      <S.Content>{children}</S.Content>
    </S.Wrapper>
  );
};

Modal.Actions = S.Actions;

export default Modal;
