import { BGN } from "@dinero.js/currencies";
import { EUR_BGN_RATE } from "@hotelspoint/constants";
import { useUserCurrencyStore } from "@hotelspoint/store";
import { UserCurrency } from "@hotelspoint/types";
import { convert, haveSameCurrency, transformScale } from "dinero.js";

import { format } from "./format";
import { PriceValue } from "./types";
import { createPriceObject, isDineroObject, priceFactoryFn } from "./utils";

interface PriceProps {
  value: PriceValue;
}

const Price = ({ value }: PriceProps): string => {
  const currency = useUserCurrencyStore(state => state.currency);

  const price = priceFactoryFn(value);

  if (currency === UserCurrency.BGN) {
    // @todo: this check may be expensive, reconsider it
    if (
      isDineroObject(price) &&
      haveSameCurrency([price, createPriceObject(0, UserCurrency.BGN)])
    ) {
      return format(price);
    }

    return format(
      transformScale(
        convert(price, BGN, {
          BGN: {
            // EUR to BGN rate is using 3 decimal places
            amount: EUR_BGN_RATE * 1000,
            scale: 3,
          },
        }),
        2,
      ),
    );
  }

  return format(price);
};

export default Price;
