import {
  handleError,
  HotelRateStatusChangeType,
  HotelRateStatusChangeValue,
  useAddOfferRate,
  useDeleteOfferItem,
  useHotelRateStatus,
  useHotelRooms,
} from "@hotelspoint/api";
import {
  Accordion,
  AccordionTriggerBase,
  Button,
  Dialog,
  MarkupPrice,
  Modal,
  ModalSize,
  Price,
  StatusFlavour,
  StatusText,
  Table,
  TableSkeleton,
} from "@hotelspoint/components";
import { useUserCurrentOfferStore } from "@hotelspoint/store";
import { ReservationType } from "@hotelspoint/types";
import { formatDate } from "@hotelspoint/utils";
import qs from "query-string";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import getColumns from "./HotelRoomsTable.columns";
import * as S from "./HotelRoomsTable.styled";

interface HotelRoomsTableProps {
  searchId: number;
  hotelId: number;
}

const HotelRoomsTable = ({ searchId, hotelId }: HotelRoomsTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const offer = useUserCurrentOfferStore(state => state.offer);
  const offerItems = useMemo(() => offer?.items ?? [], [offer]);

  const [targetRateId, setTargetRateId] = useState<number | null>(null);
  const [unavailableRateIds, setUnavailableRateIds] = useState<number[]>([]);

  const [isRateChangesModalOpen, setIsRateChangesModalOpen] = useState(false);
  const [rateChanges, setRateChanges] = useState<any>([]);

  const [data, isLoadingData] = useHotelRooms(searchId, hotelId);
  const [checkRateStatus, isCheckingRateStatus] = useHotelRateStatus(
    searchId,
    hotelId,
  );

  const [addRate, isAddingRate] = useAddOfferRate();
  const [deleteItem, isDeletingItem] = useDeleteOfferItem(offer?.id as number);

  const handleRedirect = useCallback(
    (id: number) => {
      const search = qs.stringify({
        searchId,
        rateId: id,
      });

      navigate({
        pathname: `/search/hotels/${hotelId}/book`,
        search,
      });
    },
    [searchId, hotelId, navigate],
  );

  const handleCheckRateStatus = useCallback(
    async (rateId: number) => {
      try {
        setTargetRateId(rateId);

        const response = await checkRateStatus(rateId);

        if (response.isAvailable) {
          if (response.isChanged) {
            const changes = Object.entries(response).filter(([key]) =>
              Object.values(HotelRateStatusChangeType).includes(key as any),
            );

            setIsRateChangesModalOpen(true);
            setRateChanges(changes);
          } else {
            handleRedirect(rateId);
          }
        } else {
          setUnavailableRateIds(rateIds => [...rateIds, rateId]);

          toast.error(t("toast.hotelRoomsTable.unavailableRate"));
        }

        if (!response.isChanged) {
          setTargetRateId(null);
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, checkRateStatus, handleRedirect],
  );

  const handleAddToOffer = useCallback(
    async (rateId: number) => {
      try {
        setTargetRateId(rateId);

        await addRate({
          itemId: hotelId,
          itemType: ReservationType.Hotel,
          searchId,
          rateId,
        });
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetRateId(null);
      }
    },
    [t, addRate, hotelId, searchId],
  );

  const handleRemoveFromOffer = useCallback(
    async (rateId: number, offerItemId: number) => {
      setTargetRateId(rateId);

      try {
        await deleteItem(offerItemId);
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetRateId(null);
      }
    },
    [t, deleteItem],
  );

  const renderChangeInfo = (change: any) => {
    const [type, value] = change;

    switch (type) {
      case HotelRateStatusChangeType.Price:
        return (
          <StatusText status={StatusFlavour.Warning}>
            <Trans i18nKey="searchHotelResults.rateChangesModal.price">
              Price has changed from <Price value={value?.before} /> to{" "}
              <Price value={value?.after} />.
            </Trans>
          </StatusText>
        );

      case HotelRateStatusChangeType.Cancellation:
        return (
          <StatusText status={StatusFlavour.Warning}>
            {t("searchHotelResults.rateChangesModal.cancellation", {
              dateFrom: formatDate(value?.before),
              dateTo: formatDate(value?.after),
            })}
          </StatusText>
        );

      case HotelRateStatusChangeType.HotelName:
        return (
          <StatusText status={StatusFlavour.Warning}>
            {t("searchHotelResults.rateChangesModal.hotelName", {
              name: value,
            })}
          </StatusText>
        );
      default:
        return null;
    }
  };

  const columns = useMemo(() => {
    return getColumns({
      t,
      searchId,
      hotelId,
      targetRateId,
      unavailableRateIds,
      offerItems,
      handleCheckRateStatus,
      handleAddToOffer,
      handleRemoveFromOffer,
      isCheckingRateStatus,
      isAddingRate,
      isDeletingItem,
    });
  }, [
    t,
    hotelId,
    searchId,
    targetRateId,
    unavailableRateIds,
    offerItems,
    handleCheckRateStatus,
    handleAddToOffer,
    handleRemoveFromOffer,
    isCheckingRateStatus,
    isAddingRate,
    isDeletingItem,
  ]);

  if (isLoadingData) {
    return <TableSkeleton />;
  }

  return (
    <>
      <Dialog open={isRateChangesModalOpen}>
        <Dialog.Content>
          <Modal
            title={t("searchHotelResults.rateChangesModal.title")}
            onClose={() => {
              setTargetRateId(null);
              setIsRateChangesModalOpen(false);
            }}
            size={ModalSize.Small}
          >
            <S.ModalContent>
              {rateChanges?.map(
                (change: HotelRateStatusChangeValue, index: number) => (
                  <Fragment key={index}>{renderChangeInfo(change)}</Fragment>
                ),
              )}
            </S.ModalContent>
            <Modal.Actions>
              <Button
                variant="outlined"
                onClick={() => {
                  setTargetRateId(null);
                  setIsRateChangesModalOpen(false);
                }}
              >
                {t("searchHotelResults.rateChangesModal.cancel")}
              </Button>
              <Button onClick={() => handleRedirect(targetRateId as number)}>
                {t("searchHotelResults.rateChangesModal.continue")}
              </Button>
            </Modal.Actions>
          </Modal>
        </Dialog.Content>
      </Dialog>
      {data?.rooms.map((room, index) => (
        <Fragment key={index}>
          <S.Wrapper>
            <S.Headline>{room.name}</S.Headline>
            <MarkupPrice
              value={{
                total: room.minPrice,
                net: room.minPriceNet,
              }}
            >
              <S.PriceWrap>
                <S.Emphasized>
                  {t("searchHotelResults.hotelRoomsModal.from")}
                </S.Emphasized>
                <S.Price>
                  <Price value={room.minPrice} />
                </S.Price>
              </S.PriceWrap>
            </MarkupPrice>
          </S.Wrapper>
          <Accordion
            isInitiallyOpen={index === 0 ? true : false}
            head={({ isOpen }) => (
              <AccordionTriggerBase
                isOpen={isOpen}
                showText={t("searchHotelResults.hotelRoomsModal.showAll")}
                hideText={t("searchHotelResults.hotelRoomsModal.hideAll")}
              />
            )}
          >
            <S.TableWrapper>
              <Table
                data={room.rates}
                columns={columns}
                isLoading={isLoadingData}
              />
            </S.TableWrapper>
          </Accordion>
          {index !== data.rooms.length - 1 && <S.Divider />}
        </Fragment>
      ))}
    </>
  );
};

export default HotelRoomsTable;
