import { useHotelDetails } from "@hotelspoint/api";
import { GalleryOverlay, HotelDetailsModal } from "@hotelspoint/components";
import {
  SearchHotelsResultsModalState,
  SearchHotelsResultsModalType,
} from "@hotelspoint/types";
import { ImageType } from "@hotelspoint/utils";

interface HotelDetailsGalleryModalProps {
  id: number;
  modalType: SearchHotelsResultsModalType;
  setActiveModal: (modal?: SearchHotelsResultsModalState) => void;
  initialGalleryIndex?: number;
  setInitialGalleryIndex?: (index: number) => void;
}

const HotelDetailsGalleryModal = ({
  id,
  modalType,
  setActiveModal,
  initialGalleryIndex = 0,
  setInitialGalleryIndex,
}: HotelDetailsGalleryModalProps) => {
  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(id);

  const handleImageClick = (index: number) => {
    setInitialGalleryIndex && setInitialGalleryIndex(index);
    setActiveModal({
      id,
      type: SearchHotelsResultsModalType.Gallery,
    });
  };

  const handleCloseModal = () => {
    setActiveModal(undefined);
  };

  return (
    <>
      <GalleryOverlay
        isOpen={
          modalType === SearchHotelsResultsModalType.Gallery &&
          hotelDetails?.images?.length !== 0
        }
        images={hotelDetails?.images ?? []}
        imageType={ImageType.Hotel}
        initialSlideIndex={initialGalleryIndex}
        isLoading={isLoadingHotelDetails}
        onClose={handleCloseModal}
      />
      <HotelDetailsModal
        id={id}
        handleImageClick={handleImageClick}
        isOpen={modalType === SearchHotelsResultsModalType.Details}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default HotelDetailsGalleryModal;
