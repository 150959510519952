import {
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  PaginationOptions,
  UserCurrency,
} from "@hotelspoint/types";

import { UPLOAD_CONFIG } from "../constants";
import FetchService from "./base/FetchService";
import { ReservationStatus } from "./ReservationService";

const PAYMENT_API_PATH = "payments";

export enum PaymentType {
  BankTransfer = 1,
  CreditCard = 2,
  CreditLine = 3,
}

export enum PaymentStatus {
  Progress = 1,
  Reserved = 2,
  Confirmed = 3,
  Cancelled = 4,
  Refunded = 5,
  Failed = 6,
  PendingRefund = 7,
}

export enum PaymentCreditStatus {
  Pending = 1,
  Paid = 2,
}

export enum RecipientType {
  System = "system",
}

export interface Payment {
  id: number;
  type: PaymentType;
  status: PaymentStatus;
  amount: number;
  currency: UserCurrency;
  reservationId: number;
  agencyId: number;
  agencyName: string;
  code: number | null;
  fileId: number;
  creditStatus: PaymentCreditStatus;
  dateCreated: string;
}

export interface PaymentListParams extends PaginationOptions {
  id?: number;
  reservationId?: number;
  agencyId?: number;
  type?: PaymentType;
  status?: PaymentStatus;
  amountFrom?: number;
  amountTo?: number;
  dateFrom?: string;
  dateTo?: string;
  recipient?: RecipientType;
}

export type PaymentListResponse = ApiResponseSuccessResultList<Payment>;

export interface PaymentCreatePayload {
  reservationId: number;
  paymentType: PaymentType;
  paymentCurrency?: UserCurrency;
  paymentFileId?: number;
}

export interface PaymentCreateResponse {
  status: PaymentStatus;
  amount: number;
  paymentId: number;
  paymentType: PaymentType;
  paymentUrl: string;
  paymentUrlParams: Record<string, any>;
}

export interface PaymentProcessResponse {
  paymentStatus: PaymentStatus;
  reservationId: number;
  reservationStatus: ReservationStatus;
}

export type PaymentUpdatePayload = Partial<
  Pick<Payment, "status" | "creditStatus">
>;

export interface PaymentUploadFileResponse {
  fileId: number;
}

export type PaymentRefundPayload = Pick<Payment, "amount">;

const PaymentService = {
  get: (id: number) => {
    return FetchService.get<Payment>(`${PAYMENT_API_PATH}/${id}`);
  },
  getList: (params?: PaymentListParams) => {
    return FetchService.get<PaymentListResponse>(PAYMENT_API_PATH, params);
  },
  create: (payload: PaymentCreatePayload) => {
    return FetchService.post<PaymentCreateResponse>(PAYMENT_API_PATH, payload);
  },
  process: (id: number) => {
    return FetchService.post<PaymentProcessResponse>(
      `${PAYMENT_API_PATH}/${id}/process`,
    );
  },
  update: (id: number, payload: PaymentUpdatePayload) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${PAYMENT_API_PATH}/${id}`,
      payload,
    );
  },
  uploadFile: (payload: FormData) => {
    return FetchService.post<PaymentUploadFileResponse>(
      `${PAYMENT_API_PATH}/upload`,
      payload,
      undefined,
      UPLOAD_CONFIG,
    );
  },
  approve: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${PAYMENT_API_PATH}/${id}/approve`,
    );
  },
  reject: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${PAYMENT_API_PATH}/${id}/reject`,
    );
  },
  cardDeposit: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${PAYMENT_API_PATH}/${id}/cardDeposit`,
    );
  },
  cardReturn: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${PAYMENT_API_PATH}/${id}/cardReturn`,
    );
  },
  cardRefund: (id: number, payload: PaymentRefundPayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${PAYMENT_API_PATH}/${id}/cardRefund`,
      payload,
    );
  },
};

export default PaymentService;
