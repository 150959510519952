import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ModalSize, StatusFlavour } from "../../common";
import Button from "../../elements/Button";
import StatusText from "../../elements/StatusText";
import Dialog from "../Dialog";
import Modal from "../Modal";

interface ConfirmDialogProps {
  title: string;
  description: string;
  children: React.ReactNode;
  onConfirm: () => any;
  isLoading?: boolean;
  status?: StatusFlavour;
}

const ConfirmDialog = ({
  title,
  description,
  children,
  onConfirm,
  isLoading = false,
  status = StatusFlavour.Warning,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    if (typeof onConfirm === "function") {
      onConfirm();
    }

    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content>
        <Modal
          title={
            <StatusText status={status} iconProps={{ size: 24 }}>
              <h6>{title}</h6>
            </StatusText>
          }
          size={ModalSize.Small}
          onClose={() => setIsOpen(false)}
        >
          <div>{description}</div>
          <Modal.Actions>
            <Button variant="outlined" onClick={handleCancel}>
              {t("components.confirmDialog.cancel")}
            </Button>
            <Button isLoading={isLoading} onClick={handleConfirm}>
              {t("components.confirmDialog.confirm")}
            </Button>
          </Modal.Actions>
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default ConfirmDialog;
