import {
  useFetchAgency,
  useFetchCurrentOffer,
  useFetchProfile,
  useLogout,
} from "@hotelspoint/api";
import { LoaderOverlay } from "@hotelspoint/components";
import {
  useResetUserStores,
  useUserAgencyStore,
  useUserCurrentOfferStore,
  useUserProfileStore,
  useUserTokenStore,
} from "@hotelspoint/store";
import ms from "ms";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isFetchingProfile = useRef(false);
  const [isAppReady, setIsAppReady] = useState(false);

  const token = useUserTokenStore(state => state.token);
  const loggedIn = useUserProfileStore(state => !!state.profile);
  const setProfile = useUserProfileStore(state => state.set);
  const setAgency = useUserAgencyStore(state => state.set);
  const setCurrentOffer = useUserCurrentOfferStore(state => state.set);

  const resetUserStores = useResetUserStores();

  const [fetchProfile] = useFetchProfile();
  const [fetchAgency] = useFetchAgency();
  const [fetchCurrentOffer] = useFetchCurrentOffer();
  const [logoutUser] = useLogout();

  useIdleTimer({
    timeout: ms("4h"),
    disabled: !loggedIn,
    onIdle: async () => {
      try {
        await logoutUser();
      } catch (error: any) {
        resetUserStores();
      } finally {
        navigate("/");
        toast.warn(t("toast.sessionExpired"), { autoClose: false });
      }
    },
  });

  // Fetch the user profile if the token is present
  useEffect(() => {
    const fetchUserState = async () => {
      try {
        const profile = await fetchProfile();
        setProfile(profile);

        const agency = await fetchAgency(profile.agencyId);
        setAgency(agency);

        const offer = await fetchCurrentOffer();
        setCurrentOffer(offer);

        setIsAppReady(true);

        toast.dismiss();
      } catch (e) {
        // Prevent the user stores to be reset when developing locally - sometimes the request is cancelled because of HMR
        if (import.meta.env.PROD) {
          resetUserStores();
        }

        setIsAppReady(true);
      }
    };

    if (token && !isFetchingProfile.current) {
      isFetchingProfile.current = true;

      // Handle profile loading error by resetting the user state
      fetchUserState();
    }
  }, [
    token,
    fetchProfile,
    setProfile,
    fetchAgency,
    setAgency,
    fetchCurrentOffer,
    setCurrentOffer,
    resetUserStores,
  ]);

  // Hook to manage the app ready state
  useEffect(() => {
    if (!token) {
      setIsAppReady(true);
    }
  }, [token, loggedIn]);

  if (!isAppReady) {
    return <LoaderOverlay />;
  }

  return <>{children}</>;
};

export default AuthProvider;
