import {
  handleError,
  useAdminAreaLocation,
  useUpdateAdminAreaLocation,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSwitch,
  LoaderBlock,
} from "@hotelspoint/components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminAreaLocationForm.util";

interface AdminAreaLocationProps {
  id: number;
}

const AdminAreaLocationForm = ({ id }: AdminAreaLocationProps) => {
  const { t } = useTranslation();

  const [location, isLoadingLocation] = useAdminAreaLocation(id);
  const [updateLocation, isUpdating] = useUpdateAdminAreaLocation(id);

  const formValues = useMemo(() => {
    if (!location) return defaultValues;

    return entity2Form(location);
  }, [location]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await updateLocation(payload);
        toast.success(t("toast.adminAreaLocation.updateArea"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, updateLocation],
  );

  if (isLoadingLocation) {
    return <LoaderBlock />;
  }

  return (
    <Form defaultValues={formValues} validationSchema={validationSchema}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="id" label={t("adminAreaLocation.form.id.label")}>
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminAreaLocation.form.name.label")}
            >
              {props => <FormInput {...props} disabled={isUpdating} />}
            </FormAdapter>
          </Box>
          <Box
            width={[1, 1, 1 / 2, 1 / 3]}
            px={[0, 0, 1, 1]}
            py={1}
            display="flex"
            alignItems="center"
          >
            <FormAdapter
              name="isActive"
              label={t("adminAreaLocation.form.isActive.label")}
            >
              {props => <FormSwitch {...props} disabled={isUpdating} />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="latitude"
              label={t("adminAreaLocation.form.latitude.label")}
            >
              {props => <FormInput {...props} disabled={isUpdating} />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="longitude"
              label={t("adminAreaLocation.form.longitude.label")}
            >
              {props => <FormInput {...props} disabled={isUpdating} />}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isUpdating}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminAreaLocation.form.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminAreaLocationForm;
