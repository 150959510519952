import "normalize.css/normalize.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./tools/i18n";
import "./tools/sentry";

import { NewVersionToast } from "@hotelspoint/components";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { toast } from "react-toastify";
// @ts-expect-error: Cannot find module 'virtual:pwa-register' or its corresponding type declarations.
import { registerSW } from "virtual:pwa-register";

import App from "./App";
import ErrorOverlay from "./routes/ErrorOverlay";
import queryClient from "./tools/queryClient";

const updateSW = registerSW({
  onNeedRefresh() {
    // Dismiss & clear queue for toasts before showing the new version one
    toast.dismiss();
    toast.clearWaitingQueue();

    toast(<NewVersionToast onConfirm={() => updateSW()} />, {
      autoClose: false,
      closeButton: false,
    });
  },
});

const reactRouterFFs = {
  v7_relativeSplatPath: true,
  v7_startTransition: true,
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary fallback={ErrorOverlay} showDialog={false}>
        <BrowserRouter future={reactRouterFFs}>
          <App />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </QueryClientProvider>
  </StrictMode>,
);
