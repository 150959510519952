import { InferType, object, string } from "yup";

export const validationSchema = object({
  locations: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  locations: undefined,
};
