import { pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const IconWrapper = styled.div`
  position: relative;
`;

export const Superscript = styled.sup`
  top: -1em;
  font-size: ${pxToRem(12)};
  text-transform: uppercase;
  font-weight: 700;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(0.5)}px;
  padding: ${spacing(0.5)}px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: ${spacing(4)}px;
`;

export const Value = styled.span`
  font-weight: 600;
  color: ${theme("palette.dodgerBlue")};
`;

export const NegativeValue = styled(Value)`
  color: ${theme("error.primary")};
`;
