import { useHotelRate, useHotelSearchInfo } from "@hotelspoint/api";
import {
  Button,
  CancelationPolicy,
  HotelBaseInfo,
  HotelBookPriceBreakdown,
  HotelDetailsGalleryModal,
  HotelStayDurationDetails,
} from "@hotelspoint/components";
import {
  HotelRoomRate,
  SearchHotelsResultsModalState,
  SearchHotelsResultsModalType,
} from "@hotelspoint/types";
import {
  IconCalendar,
  IconCalendarX,
  IconTag,
  IconToolsKitchen2,
} from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import * as S from "./SearchHotelBookSidebar.styled";

interface SearchHotelBookSidebarProps {
  hotelId: number;
  searchId: number;
  rateId?: number;
}

const SearchHotelBookSidebar = ({
  hotelId,
  searchId,
  rateId,
}: SearchHotelBookSidebarProps) => {
  const { t } = useTranslation();

  const [searchInfo, isLoadingSearchInfo] = useHotelSearchInfo(
    searchId as number,
  );

  const [hotelRate, isLoadingHotelRate] = useHotelRate(
    searchId as number,
    hotelId as number,
    rateId as number,
  );

  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);
  const [activeModal, setActiveModal] = useState<
    SearchHotelsResultsModalState | undefined
  >(undefined);

  const { checkIn, checkOut } = searchInfo?.search || {};

  return (
    <div>
      <HotelDetailsGalleryModal
        id={hotelId}
        modalType={activeModal?.type as SearchHotelsResultsModalType}
        setActiveModal={setActiveModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
      />
      <S.Card>
        <HotelBaseInfo id={hotelId} />
        <Button
          variant="outlined"
          onClick={() =>
            setActiveModal({
              id: hotelId as number,
              type: SearchHotelsResultsModalType.Details,
            })
          }
        >
          {t("searchHotelBook.sidebar.hotelDetails")}
        </Button>
      </S.Card>
      <S.HeadlineWrapper>
        <IconCalendar size={18} />
        <h6>{t("searchHotelBook.sidebar.dates.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <HotelStayDurationDetails
          checkIn={checkIn as string}
          checkOut={checkOut as string}
          isLoading={isLoadingSearchInfo}
        />
      </S.Card>
      <S.HeadlineWrapper>
        <IconToolsKitchen2 size={18} />
        <h6>{t("searchHotelBook.sidebar.meals.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <S.Flex>
          <p>{t("searchHotelBook.sidebar.meals.meal")}</p>
          {isLoadingHotelRate ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <p>{hotelRate?.mealName}</p>
          )}
        </S.Flex>
      </S.Card>
      <S.HeadlineWrapper>
        <IconCalendarX size={18} />
        <h6>{t("searchHotelBook.sidebar.cancelationPolicy.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <CancelationPolicy
          rateType={hotelRate?.rateType as number}
          deadline={hotelRate?.cancellation?.deadline as string}
          isLoading={isLoadingHotelRate}
        />
      </S.Card>
      <S.HeadlineWrapper>
        <IconTag size={18} />
        <h6>{t("searchHotelBook.sidebar.priceBreakdown.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <HotelBookPriceBreakdown
          rooms={hotelRate?.rooms as HotelRoomRate[]}
          isLoading={isLoadingHotelRate}
        />
      </S.Card>
    </div>
  );
};

export default SearchHotelBookSidebar;
