import {
  handleError,
  useAdminAreaLocation,
  useDeleteAdminAreaLocationItem,
} from "@hotelspoint/api";
import {
  Table,
  TableFooter,
  TableLayout,
  TableResultsCount,
} from "@hotelspoint/components";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import getColumns from "./AdminAreaLocationItems.columns";

interface AdminAreaLocationItemsProps {
  id: number;
}

const AdminAreaLocationItems = ({ id }: AdminAreaLocationItemsProps) => {
  const { t } = useTranslation();

  const [targetId, setTargetId] = useState<number | null>(null);

  const [location, isLoading] = useAdminAreaLocation(id);
  const [deleteItem, isDeleting] = useDeleteAdminAreaLocationItem(id);

  const handleDelete = useCallback(
    async (id: number) => {
      try {
        setTargetId(id);

        await deleteItem(id);
        toast.success(t("toast.adminAreaLocation.locationItem.deleteLocation"));
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetId(null);
      }
    },
    [t, deleteItem],
  );

  const data = useMemo(() => location?.items ?? [], [location]);

  const columns = useMemo(() => {
    return getColumns({
      t,
      targetId,
      handleDelete,
      isDeleting,
    });
  }, [t, targetId, handleDelete, isDeleting]);

  return (
    <TableLayout>
      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        useReactTableProps={{
          state: {
            columnPinning: {
              left: ["id"],
              right: ["actions"],
            },
          },
        }}
      />
      <TableFooter>
        <div>
          <TableResultsCount value={location?.items?.length} />
        </div>
        <div />
        <div />
      </TableFooter>
    </TableLayout>
  );
};

export default AdminAreaLocationItems;
