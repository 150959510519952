import {
  handleError,
  HotelRateResponse,
  ReservationCreatePayload,
  useCreateReservation,
  useHotelSearchInfo,
} from "@hotelspoint/api";
import {
  add,
  Box,
  Button,
  ConfirmDialog,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormLayout,
  FormRadio,
  ImportantInfo,
  MarkupPrice,
  PassengerInfo,
  PassengerType,
  Price,
  priceToDecimal,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { ReservationType } from "@hotelspoint/types";
import { IconManFilled, IconUserFilled } from "@tabler/icons-react";
import { useMemo, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import useSearchHotelBookParams from "../../useSearchHotelBookParams";
import FlightDetails from "../FlightDetails";
import LeaderOnlyInfo from "../LeaderOnlyInfo";
import * as S from "./SearchHotelBookForm.styled";
import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchHotelBookForm.util";
import { FormType } from "./types";

interface SearchHotelBookParams extends Record<string, string> {
  id: string;
}

interface SearchHotelBookFormProps {
  hotelRate?: HotelRateResponse;
  isLoading: boolean;
}

interface SearchHotelBookFormInner extends SearchHotelBookFormProps {}

const SearchHotelBookFormInner = ({
  hotelRate,
  isLoading,
}: SearchHotelBookFormInner) => {
  const { t } = useTranslation();

  const { id } = useParams<SearchHotelBookParams>();

  const hotelId = Number(id);
  const [{ searchId, rateId }] = useSearchHotelBookParams();

  const [createReservation] = useCreateReservation();

  const dialogTriggerRef = useRef<HTMLDivElement>(null);

  const hasTransfers = useMemo(() => hotelRate?.hasTransfers, [hotelRate]);

  const roomIds = useMemo(() => {
    if (!hotelRate) {
      return [];
    }

    return hotelRate.rooms.reduce((acc: number[], item) => {
      return acc.concat(Array.from({ length: item.numRooms }, () => item.id));
    }, []);
  }, [hotelRate]);

  const [formType] = useWatch({ name: ["type"] });

  // Open the confirmation dialog that will create the reservation
  const onSubmit = () => {
    (dialogTriggerRef.current as HTMLElement).click();
  };

  const handleConfirm = async (formValues: FormValues) => {
    try {
      const formPayload = form2Entity(formValues, roomIds);

      const payload: ReservationCreatePayload = {
        type: ReservationType.Hotel,
        rateId: rateId as number,
        hotelId: hotelId,
        searchId: searchId as number,
        ...formPayload,
      };

      const response = await createReservation(payload);

      console.log(response.status);
      // redirect
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <>
      <FormContext<FormValues>
        render={({ handleSubmit }) => (
          <ConfirmDialog
            title={t("searchHotelBook.content.actions.book.modal.title")}
            description={t(
              "searchHotelBook.content.actions.book.modal.description",
            )}
            onConfirm={handleSubmit(handleConfirm)}
            isLoading={false}
          >
            <div ref={dialogTriggerRef} />
          </ConfirmDialog>
        )}
      />
      <FormLayout>
        {hotelRate?.allowLeaderOnly && (
          <S.RadioGroup>
            {isLoading ? (
              <Skeleton />
            ) : (
              <Flex alignItems={"center"}>
                <Box width={[2 / 14]}>
                  <p>{t("searchHotelBook.content.radioGroup.type")}</p>
                </Box>
                <Box width={[12 / 14]}>
                  <FormAdapter name="type">
                    {props => (
                      <Flex alignItems={"center"}>
                        <Box width={[6 / 12]}>
                          <FormRadio
                            {...props}
                            id={FormType.LeaderOnly}
                            value={FormType.LeaderOnly}
                            label={t(
                              "searchHotelBook.content.radioGroup.leaderOnly",
                            )}
                            checked={formType === FormType.LeaderOnly}
                          />
                        </Box>
                        <Box width={[6 / 12]} px={[0, 0, 1, 1]}>
                          <FormRadio
                            {...props}
                            id={FormType.AllPassengers}
                            value={FormType.AllPassengers}
                            label={t(
                              "searchHotelBook.content.radioGroup.allPassengers",
                            )}
                            checked={formType === FormType.AllPassengers}
                          />
                        </Box>
                      </Flex>
                    )}
                  </FormAdapter>
                </Box>
              </Flex>
            )}
          </S.RadioGroup>
        )}
        {isLoading ? (
          <>
            <S.FlexWrapper>
              <S.FlexGrow>
                <S.RoomName>
                  <Skeleton />
                </S.RoomName>
              </S.FlexGrow>
              <S.FlexGrow>
                <S.Price>
                  <Skeleton />
                </S.Price>
              </S.FlexGrow>
            </S.FlexWrapper>
            <S.SectionTitle>
              <IconUserFilled size={18} />
              <S.FlexGrow>
                <Skeleton />
              </S.FlexGrow>
            </S.SectionTitle>
            <PassengerInfo isLoading={isLoading} />
          </>
        ) : formType === FormType.LeaderOnly ? (
          <LeaderOnlyInfo hotelRate={hotelRate} />
        ) : (
          (() => {
            let roomIndex = 0;
            return hotelRate?.rooms.map(room => (
              <div key={roomIndex}>
                <S.FlexWrapper>
                  <S.RoomName>
                    {room.numRooms !== 1 && `${room.numRooms}x `}
                    {room.roomName}
                  </S.RoomName>
                  <S.Price>
                    <Price value={room.price} />
                  </S.Price>
                </S.FlexWrapper>

                {Array.from({ length: room.numRooms }).map(
                  (_, numRoomIndex) => {
                    const roomInstance = (
                      <>
                        <S.SectionTitle>
                          <IconUserFilled size={18} />
                          <span>
                            {t("searchHotelBook.content.adult", {
                              count: room.adults,
                            })}
                          </span>
                        </S.SectionTitle>
                        <div key={numRoomIndex}>
                          {Array.from({ length: room.adults }).map(
                            (_, adultIndex) => {
                              const name = `guests.${roomIndex}.${adultIndex}`;
                              return (
                                <PassengerInfo key={adultIndex} name={name} />
                              );
                            },
                          )}
                          {room.children.length !== 0 && (
                            <S.SectionTitle>
                              <IconManFilled size={18} />
                              <span>
                                {t("searchHotelBook.content.children")}
                              </span>
                            </S.SectionTitle>
                          )}
                          {room.children.map((_, childIndex) => {
                            const name = `children.${roomIndex}.${childIndex}`;
                            return (
                              <PassengerInfo
                                key={childIndex}
                                name={name}
                                type={PassengerType.Children}
                              />
                            );
                          })}
                          {numRoomIndex !== room.numRooms - 1 && <S.Divider />}
                        </div>
                      </>
                    );
                    roomIndex += 1;
                    return roomInstance;
                  },
                )}

                {room.promotions && (
                  <S.StatusBlockWrapper>
                    <StatusBlock status={StatusFlavour.Info}>
                      <S.StatusBlockHeader>
                        {t("searchHotelBook.content.promoOffer")}
                      </S.StatusBlockHeader>
                      {Array.isArray(room.promotions) &&
                        room.promotions.map(promotion => (
                          <S.StatusBlockCopy key={promotion.name}>
                            {promotion.name}
                            {promotion.amount && (
                              <>
                                : <Price value={promotion.amount} />
                              </>
                            )}
                          </S.StatusBlockCopy>
                        ))}
                    </StatusBlock>
                  </S.StatusBlockWrapper>
                )}
              </div>
            ));
          })()
        )}
        {hasTransfers && <FlightDetails isLoading={isLoading} />}
        <S.Divider />
        <ImportantInfo remarks={hotelRate?.remarks} isLoading={isLoading} />
        <S.FormFooter>
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <S.FormPriceWrap>
              <p>{t("searchHotelBook.content.totalPrice")}</p>
              <MarkupPrice
                value={{
                  total: priceToDecimal(
                    add(
                      ...(hotelRate?.rooms.map(room => room.price) as number[]),
                    ),
                  ),
                  net: priceToDecimal(
                    add(
                      ...(hotelRate?.rooms.map(
                        room => room.priceNet,
                      ) as number[]),
                    ),
                  ),
                }}
              >
                <S.PriceTotal>
                  <Price
                    value={add(
                      ...(hotelRate?.rooms.map(room => room.price) as number[]),
                    )}
                  />
                </S.PriceTotal>
              </MarkupPrice>
            </S.FormPriceWrap>
          )}
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t("searchHotelBook.content.form.submit")}
              </Button>
            )}
          />
        </S.FormFooter>
      </FormLayout>
    </>
  );
};

const SearchHotelBookForm = ({
  hotelRate,
  isLoading,
}: SearchHotelBookFormProps) => {
  const [{ searchId }] = useSearchHotelBookParams();
  const [searchInfo, isLoadingSearchInfo] = useHotelSearchInfo(
    searchId as number,
  );

  const formValues = useMemo(() => {
    if (!hotelRate || !searchInfo) return defaultValues;

    return entity2Form(hotelRate, searchInfo);
  }, [hotelRate, searchInfo]);

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => (
        <SearchHotelBookFormInner
          hotelRate={hotelRate}
          isLoading={isLoading || isLoadingSearchInfo}
          {...formMethods}
        />
      )}
    </Form>
  );
};

export default SearchHotelBookForm;
