import times from "lodash/times";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "styled-components";

import * as S from "./Table/Table.styled";

const FAKE_ROWS = 8;
const FAKE_COLUMNS = 6;
const FAKE_COLUMNS_WIDTH = [10, 40, 10, 15, 20, 5];

const TableSkeleton = () => {
  const theme = useTheme();

  return (
    <S.Table>
      <thead>
        <tr>
          {times(FAKE_COLUMNS, index => (
            <th
              key={`head:${index}`}
              style={{ width: `${FAKE_COLUMNS_WIDTH[index]}%` }}
            >
              <Skeleton />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {times(FAKE_ROWS, rowIndex => {
          const fakeRow = (
            <Skeleton
              baseColor={
                rowIndex % 2 !== 0 ? theme.palette.silverChalice : undefined
              }
            />
          );

          return (
            <tr key={`row:${rowIndex}`}>
              {times(FAKE_COLUMNS, cellIndex => (
                <td key={`cell:${rowIndex}:${cellIndex}`}>{fakeRow}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </S.Table>
  );
};

export default TableSkeleton;
