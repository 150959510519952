import { toFixedPrecise } from "@hotelspoint/utils";
import {
  add as dineroAdd,
  Dinero,
  multiply as dineroMultiply,
  subtract as dineroSubtract,
  toDecimal,
} from "dinero.js";

import { PriceValue } from "./types";
import { priceFactoryFn } from "./utils";

export const add = (...args: PriceValue[]) => {
  return args.map(priceFactoryFn).reduce(dineroAdd);
};

export const subtract = (...args: PriceValue[]) => {
  return args.map(priceFactoryFn).reduce(dineroSubtract);
};

export const multiply = (value: PriceValue, multiplier: number) => {
  return dineroMultiply(priceFactoryFn(value), multiplier);
};

// Function will ignore digits after 2 decimal points
export const priceToDecimal = (price: Dinero<any>) => {
  return Number(toFixedPrecise(Number(toDecimal(price)), 2));
};
