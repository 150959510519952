import { Paper } from "@hotelspoint/components";
import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const HeadlineWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(1)}px;

  h6 {
    font-weight: 600;
  }
`;

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(3)}px;
  margin-bottom: ${spacing(3)}px;
  padding: ${spacing(2)}px;
`;
