import { Paper } from "@hotelspoint/components";
import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
  container-type: inline-size;
  container-name: container;
`;

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(3)}px;
  padding: ${spacing(2)}px;
`;

export const PassengersWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(4)}px;
  & > div {
    max-width: 160px;
  }
`;

export const TicketsPassengersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: ${spacing(2)}px;
`;

export const Tickets = styled.div`
  flex: 1;
  margin-bottom: ${spacing(1)}px;
`;

export const Title = styled.h6`
  margin-bottom: ${spacing(2)}px;
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const Headline = styled.h6`
  margin-bottom: ${spacing(2)}px;
  font-weight: 600;
`;

export const TicketNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing(0.5)}px;
`;

export const VerticalDivider = styled.div`
  flex-basis: 1px;
  height: auto;
  background: ${theme("palette.silver")};
`;

export const Passengers = styled.div`
  flex: 1;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme("palette.silver")};
`;

export const ContactsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: ${spacing(6)}px;
`;

export const ContactsTitle = styled(Title)`
  margin-bottom: ${spacing(1)}px;
`;

export const Question = styled.p`
  font-weight: 600;
`;

export const Answer = styled.p`
  font-style: italic;
`;
