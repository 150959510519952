import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailsWrapper = styled(Flex)`
  padding: ${spacing(2)}px 0;
  align-items: flex-end;
`;

export const StayWrapper = styled.div`
  padding: ${spacing(2)}px 0;
  border-style: solid;
  border-color: ${theme("palette.silver")};
  border-width: 1px 0 1px 0;
`;

export const PersonsWrapper = styled.div`
  display: flex;
`;

export const RoomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(2)}px;
  @media ${mediaQuery.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const RatesWrapper = styled.div`
  padding: ${spacing(2)}px 0;
`;

export const ActionsWrapper = styled(Flex)`
  gap: ${spacing(1)}px;
`;

export const THeadWrapper = styled.div`
  display: none;
  @media ${mediaQuery.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 ${spacing(3)}px;
  background: ${theme("palette.silver")};
`;

export const Flex1 = styled.div`
  flex: 1;
`;

export const Heading = styled.h6`
  margin-bottom: ${spacing(2)}px;
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const TabletHeading = styled(Heading)`
  @media ${mediaQuery.tablet} {
    display: none;
  }
`;

export const Strong = styled.strong`
  font-weight: 700;
`;

export const BoldHeadline = styled.h6`
  font-weight: 600;
`;

export const NameCell = styled.div`
  @media ${mediaQuery.tablet} {
    width: 30%;
  }
`;

export const PersonsCell = styled.div`
  @media ${mediaQuery.tablet} {
    width: 5%;
  }
`;

export const PricePolicyCell = styled.div`
  @media ${mediaQuery.tablet} {
    width: 30%;
  }
`;

export const ActionsCell = styled.div`
  @media ${mediaQuery.tablet} {
    width: 25%;
  }
`;
