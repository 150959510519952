import { handleError, useMessage, useUpdateMessage } from "@hotelspoint/api";
import { MESSAGES_PRIORITY_OPTIONS } from "@hotelspoint/constants";
import { getMessagesPriorityColor } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ModalSize } from "../../common";
import Button from "../../elements/Button";
import Chip from "../../elements/Chip";
import Form from "../../form/Form";
import FormAdapter from "../../form/FormAdapter";
import FormContext from "../../form/FormContext";
import FormInput from "../../form/FormInput";
import FormLayout from "../../form/FormLayout";
import FormSelect from "../../form/FormSelect";
import Dialog from "../Dialog";
import Modal from "../Modal";
import {
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./MessagesPriorityDialog.util";

interface MessagesPriorityDialogProps {
  id: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const MessagesPriorityDialog = ({
  id,
  isOpen,
  setIsOpen,
}: MessagesPriorityDialogProps) => {
  const { t } = useTranslation();

  const [message, isLoading] = useMessage(id);
  const [updateMessage, isUpdating] = useUpdateMessage();

  const formValues = useMemo(() => {
    if (!message) return {};

    return entity2Form(message);
  }, [message]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await updateMessage({
          id,
          payload: {
            priority: payload.priority,
          },
        });
        setIsOpen(false);

        toast.success(t("toast.messages.updatePriority"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, updateMessage, id, setIsOpen],
  );

  const handleCancel = () => {
    setIsOpen(false);
  };

  const priorityOptions = useMemo(
    () =>
      MESSAGES_PRIORITY_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getMessagesPriorityColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  if (!message || isLoading) return null;

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content>
          <Modal
            title={t("components.messagesPriorityDialog.title")}
            size={ModalSize.Small}
            onClose={() => setIsOpen(false)}
          >
            <FormLayout>
              <FormAdapter
                name="id"
                label={t("components.messagesPriorityDialog.id.label")}
              >
                {props => <FormInput {...props} readOnly />}
              </FormAdapter>
              <FormAdapter
                name="priority"
                label={t("components.messagesPriorityDialog.priority.label")}
              >
                {props => (
                  <FormSelect
                    {...props}
                    placeholder={t(
                      "components.messagesPriorityDialog.priority.placeholder",
                    )}
                    options={priorityOptions}
                    isClearable
                  />
                )}
              </FormAdapter>
            </FormLayout>
            <Modal.Actions>
              <Button variant="outlined" onClick={handleCancel}>
                {t("components.messagesPriorityDialog.cancel")}
              </Button>
              <FormContext<FormValues>
                render={({ handleSubmit }) => (
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isUpdating}
                  >
                    {t("components.messagesPriorityDialog.confirm")}
                  </Button>
                )}
              />
            </Modal.Actions>
          </Modal>
        </Dialog.Content>
      </Dialog>
    </Form>
  );
};

export default MessagesPriorityDialog;
