import {
  Activity,
  ActivityContent,
  ActivitySearchPayload,
  useActivityAvailabilitySearch,
  useActivityDetails,
  useActivitySearchResults,
  usePlace,
} from "@hotelspoint/api";
import {
  ActivityDetailsGalleryModal,
  ActivityListing,
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Container,
  EmptySearchState,
  LoaderBlock,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import {
  PlaceType,
  SearchActivitiesResultsModalState,
  SearchActivitiesResultsModalType,
} from "@hotelspoint/types";
import { useDocumentTitle } from "@hotelspoint/utils";
import { IconMapStar, IconReload } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import useActivitySearchParams from "../useActivitySearchParams";
import ActivityOptionsModal from "./ActivityOptionsModal";
import SearchActivitiesForm from "./SearchActivitiesForm";
import * as S from "./SearchActivitiesResults.styled";

const SearchActivitiesResults = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchActivitiesResults.pageTitle"));

  const [query] = useActivitySearchParams();

  const [searchId, setSearchId] = useState<number | undefined>(undefined);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activitiesCount, setActivitiesCount] = useState<number | undefined>(
    undefined,
  );

  const [pageIndex, setPageIndex] = useState(1);
  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

  const [activeModal, setActiveModal] = useState<
    SearchActivitiesResultsModalState | undefined
  >(undefined);

  const [activityDetails, isLoadingActivityDetails] = useActivityDetails(
    searchId as number,
    activeModal?.id as number,
  );

  const [place, isLoadingPlace] = usePlace({
    id: query.placeId as number,
    type: query.placeType as PlaceType,
  });

  const [initialSearch, isLoadingInitialSearch, { refetch }] =
    useActivityAvailabilitySearch(query as ActivitySearchPayload);

  const [searchResults, isLoadingSearchResults] = useActivitySearchResults(
    searchId as number,
    { pageIndex },
  );

  const handleCloseModal = () => {
    setActiveModal(undefined);
  };

  const isSearchCompleted = useMemo(() => {
    return initialSearch?.isCompleted || searchResults?.isCompleted;
  }, [initialSearch, searchResults]);

  const canLoadMore = useMemo(() => {
    return activities?.length !== 0 && activities?.length !== activitiesCount;
  }, [activities, activitiesCount]);

  const isLoading = isLoadingInitialSearch || isLoadingSearchResults;

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchActivities.pageTitle"),
        href: "/search/activities",
        icon: () => <IconMapStar size={18} />,
      },
      ...(place?.parents
        ? place.parents.map(placeParent => ({
            title: placeParent.name as string,
            icon: () => (
              <PlaceTypeIcon
                type={placeParent.type as PlaceType}
                iconProps={{ size: 18 }}
              />
            ),
          }))
        : []),
      {
        title: place?.name as string,
        icon: () => (
          <PlaceTypeIcon
            type={place?.type as PlaceType}
            iconProps={{ size: 18 }}
          />
        ),
      },
    ],
    [t, place],
  );

  const isEmptyState = useMemo(() => {
    return isSearchCompleted && activitiesCount === 0;
  }, [activitiesCount, isSearchCompleted]);

  useEffect(() => {
    if (initialSearch?.id) {
      const { isCompleted, id, results, total } = initialSearch;

      // Set the searchId when the initial call is not complete
      if (!isCompleted) {
        setSearchId(id);
      }

      setActivities(results);
      setActivitiesCount(total);

      // Reset the page index when a new search is made
      setPageIndex(1);
    }
  }, [initialSearch]);

  // Update the internal state when results are fetched
  useEffect(() => {
    if (searchResults) {
      const { results } = searchResults;

      if (pageIndex === 1) {
        setActivities(results);
      } else {
        setActivities(prevResults => [...prevResults, ...results]);
      }

      setActivitiesCount(searchResults.total);
    }
  }, [searchResults, pageIndex]);

  return (
    <PageWrapper>
      <ActivityDetailsGalleryModal
        id={activeModal?.id as number}
        name={activityDetails?.activity.name as string}
        place={activityDetails?.activity.location as string}
        price={activityDetails?.activity.price as number}
        priceNet={activityDetails?.activity.priceNet as number}
        content={activityDetails?.activity.content as ActivityContent[]}
        images={activityDetails?.activity.images as string[]}
        isLoading={isLoadingActivityDetails}
        modalType={activeModal?.type as SearchActivitiesResultsModalType}
        setActiveModal={setActiveModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
      />
      <ActivityOptionsModal
        isOpen={activeModal?.type === SearchActivitiesResultsModalType.Book}
        activityId={activeModal?.id as number}
        searchId={searchId as number}
        onClose={handleCloseModal}
      />
      <Container>
        <PageSpacer>
          <BreadcrumbContainer>
            {isLoadingPlace ? (
              <Skeleton width={120} height={10} />
            ) : (
              <Breadcrumb items={breadcrumbs} />
            )}
          </BreadcrumbContainer>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <S.Wrapper>
                <SearchActivitiesForm refetch={refetch} />
              </S.Wrapper>
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              {isSearchCompleted && !isEmptyState && (
                <h3>
                  {t("searchActivitiesResults.count", {
                    count: activitiesCount,
                    place: place?.name,
                  })}
                </h3>
              )}
              <S.ActivityListingWrapper>
                {isEmptyState && (
                  <EmptySearchState
                    title={t("searchActivitiesResults.emptyStateMessage.title")}
                    description={t(
                      "searchActivitiesResults.emptyStateMessage.description",
                    )}
                  />
                )}
                {isLoading ? (
                  <LoaderBlock>
                    <h6>{t("searchActivitiesResults.loading")}</h6>
                  </LoaderBlock>
                ) : (
                  <>
                    {activities.map(activity => (
                      <ActivityListing
                        key={activity.id}
                        id={activity.id}
                        searchId={searchId as number}
                        name={activity.name}
                        location={activity.location}
                        summary={activity.summary}
                        provider={activity.provider}
                        thumbnail={activity.thumbnail}
                        categories={activity.categories}
                        price={activity.price}
                        priceNet={activity.priceNet}
                        handleShowInformation={() => {
                          setActiveModal({
                            id: activity.id,
                            type: SearchActivitiesResultsModalType.Details,
                          });
                        }}
                        handleThumbnailClick={() => {
                          setInitialGalleryIndex(0);
                          setActiveModal({
                            id: activity.id,
                            type: SearchActivitiesResultsModalType.Gallery,
                          });
                        }}
                        handleShowActivity={() => {
                          setActiveModal({
                            id: activity.id,
                            type: SearchActivitiesResultsModalType.Book,
                          });
                        }}
                      />
                    ))}
                    {canLoadMore && (
                      <S.LoadMoreWrapper>
                        <Button
                          variant="outlined"
                          onClick={() => setPageIndex(pageIndex + 1)}
                          isLoading={isLoadingSearchResults}
                        >
                          {!isLoadingSearchResults && <IconReload size={18} />}
                          <span>{t("searchActivitiesResults.loadMore")}</span>
                        </Button>
                      </S.LoadMoreWrapper>
                    )}
                  </>
                )}
              </S.ActivityListingWrapper>
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchActivitiesResults;
