import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import {
  CHIP_COLORS,
  STATEMENT_SUPPLIER_OPTIONS,
  SUPPLIER_STATUS_OPTIONS,
} from "@hotelspoint/constants";
import { getSupplierStatementStatusColor } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminSupplierStatementsParams from "../useAdminSupplierStatementsParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminSupplierStatementsFilters.form";

interface AdminSupplierStatementsFiltersProps {
  isLoading: boolean;
}

const AdminSupplierStatementsFilters = ({
  isLoading,
}: AdminSupplierStatementsFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminSupplierStatementsParams();

  const statusOptions = useMemo(
    () =>
      SUPPLIER_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getSupplierStatementStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const statementSupplierOptions = STATEMENT_SUPPLIER_OPTIONS.map(option => ({
    ...option,
    label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
  }));

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("adminSupplierStatements.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminSupplierStatements.filters.id.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminSupplierStatements.filters.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminSupplierStatements.filters.status.placeholder",
                  )}
                  options={statusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateFrom"
              label={t("adminSupplierStatements.filters.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminSupplierStatements.filters.dateFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateTo"
              label={t("adminSupplierStatements.filters.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminSupplierStatements.filters.dateTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="supplierId"
              label={t("adminSupplierStatements.filters.supplierId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminSupplierStatements.filters.supplierId.placeholder",
                  )}
                  options={statementSupplierOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminSupplierStatements.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminSupplierStatementsFilters;
