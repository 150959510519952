import { useHotelDetails } from "@hotelspoint/api";
import {
  Dialog,
  HotelAmenities,
  HotelBaseInfo,
  HotelSummary,
  Modal,
  MosaicGallery,
} from "@hotelspoint/components";
import { ImageType } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

import * as S from "./HotelDetailsModal.styled";

interface HotelDetailsModalProps {
  id: number;
  isOpen: boolean;
  handleImageClick: (index: number) => void;
  onClose: () => void;
}

const HotelDetailsModal = ({
  id,
  isOpen,
  handleImageClick,
  onClose,
}: HotelDetailsModalProps) => {
  const { t } = useTranslation();

  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(id);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Modal
          title={t("components.hotelDetailsModal.title")}
          onClose={onClose}
        >
          <div>
            <MosaicGallery
              images={hotelDetails?.images ?? []}
              isLoading={isLoadingHotelDetails}
              imageType={ImageType.Hotel}
              handleImageClick={handleImageClick}
            />
            <HotelBaseInfo id={id} />
            <S.Divider />
            <HotelSummary id={id} />
            <S.Divider />
            <HotelAmenities id={id} />
          </div>
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default HotelDetailsModal;
