import {
  handleError,
  ReservationStatus,
  useCancelReservation,
  useReservation,
} from "@hotelspoint/api";
import {
  Button,
  CancelationPolicy,
  ConfirmDialog,
  HotelBookPriceBreakdown,
  HotelStayDurationDetails,
  StatusFlavour,
} from "@hotelspoint/components";
import { HotelRoomRate, RateType } from "@hotelspoint/types";
import { IconCalendar, IconCalendarX, IconTag } from "@tabler/icons-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import ReservationHotelInfo from "../ReservationHotelInfo";
import * as S from "./ProfileReservationHotelSidebar.styled";

interface ProfileReservationSidebarProps {
  id: number;
}

const ProfileReservationHotelSidebar = ({
  id,
}: ProfileReservationSidebarProps) => {
  const { t } = useTranslation();

  const [reservation, isLoadingReservation] = useReservation(id);
  const [cancelReservation, isCancelingReservation] = useCancelReservation(id);

  const handleCancelReservation = useCallback(async () => {
    try {
      await cancelReservation();

      toast.success(t("toast.profileReservations.cancel"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, cancelReservation]);

  return (
    <div>
      <ReservationHotelInfo id={id} />
      <S.HeadlineWrapper>
        <IconCalendar size={18} />
        <h6>{t("profileReservation.sidebar.dates")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <HotelStayDurationDetails
          checkIn={reservation?.dateFrom as string}
          checkOut={reservation?.dateTo as string}
          isLoading={isLoadingReservation}
        />
      </S.Card>
      <S.HeadlineWrapper>
        <IconCalendarX size={18} />
        <h6>{t("profileReservation.sidebar.cancelationPolicy")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <CancelationPolicy
          rateType={reservation?.itemData.rateType as RateType}
          deadline={reservation?.dateDeadline as string}
          isLoading={isLoadingReservation}
          footer={
            <ConfirmDialog
              title={t(
                "profileReservation.actions.cancelReservation.modal.title",
              )}
              description={t(
                "profileReservation.actions.cancelReservation.modal.description",
              )}
              onConfirm={handleCancelReservation}
              isLoading={isCancelingReservation}
              status={StatusFlavour.Error}
            >
              <Button
                variant="error"
                isDisabled={
                  reservation?.status === ReservationStatus.ConfirmedReservation
                }
              >
                {t("profileReservation.actions.cancelReservation.button")}
              </Button>
            </ConfirmDialog>
          }
        />
      </S.Card>
      <S.HeadlineWrapper>
        <IconTag size={18} />
        <h6>{t("profileReservation.sidebar.priceBreakdown")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <HotelBookPriceBreakdown
          rooms={reservation?.itemData.rooms as HotelRoomRate[]}
          isLoading={isLoadingReservation}
        />
      </S.Card>
    </div>
  );
};

export default ProfileReservationHotelSidebar;
