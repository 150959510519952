import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { useMemo } from "react";

import MosaicLayout from "../MosaicLayout";
import * as S from "./MosaicGallery.styled";

interface MosaicGalleryProps {
  images: string[];
  isLoading: boolean;
  imageType: ImageType;
  mosaicMaxImages?: number;
  handleImageClick: (index: number) => void;
}

const MosaicGallery = ({
  images,
  isLoading,
  imageType,
  mosaicMaxImages = 3,
  handleImageClick,
}: MosaicGalleryProps) => {
  const slicedImages = useMemo(() => {
    if (!images) return [];

    return images.slice(0, mosaicMaxImages).map(img => ({
      image: getImagePathFromCDN(img, imageType),
      alt: `Image ${img}`,
    }));
  }, [imageType, images, mosaicMaxImages]);

  if (images?.length === 0) return null;

  return (
    <S.MosaicWrapper>
      <MosaicLayout
        value={slicedImages}
        height={120}
        isLoading={isLoading}
        onClick={handleImageClick}
      />
    </S.MosaicWrapper>
  );
};

export default MosaicGallery;
