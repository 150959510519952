import { MapProps } from "react-map-gl";

import Map from "./Map";

const StaticMap = ({ children, ...mapProps }: Partial<MapProps>) => (
  <Map interactive={false} style={{ height: 420 }} {...mapProps}>
    {children}
  </Map>
);

export default StaticMap;
