import {
  handleError,
  useAdminCityLocation,
  useUpdateAdminCityLocation,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  LoaderBlock,
} from "@hotelspoint/components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminCityLocationForm.util";

interface AdminCityLocationProps {
  id: number;
}

const AdminCityLocationForm = ({ id }: AdminCityLocationProps) => {
  const { t } = useTranslation();

  const [location, isLoadingLocation] = useAdminCityLocation(id);
  const [updateLocation, isUpdatingLocation] = useUpdateAdminCityLocation(id);

  const formValues = useMemo(() => {
    if (!location) return defaultValues;

    return entity2Form(location);
  }, [location]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await updateLocation(payload);

        toast.success(t("toast.adminCityLocation.update"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [updateLocation, t],
  );

  if (isLoadingLocation) {
    return <LoaderBlock />;
  }

  return (
    <Form defaultValues={formValues} validationSchema={validationSchema}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="id" label={t("adminCityLocation.form.id.label")}>
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminCityLocation.form.name.label")}
            >
              {props => <FormInput {...props} />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="countryName"
              label={t("adminCityLocation.form.countryName.label")}
            >
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isUpdatingLocation}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminCityLocation.form.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminCityLocationForm;
