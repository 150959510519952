import {
  ButtonEdit,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { AgencyInList } from "@hotelspoint/types";
import { formatDate } from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<AgencyInList>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminSubAgencies.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("name", {
    header: t("adminSubAgencies.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("operatorAgencyName", {
    header: t("adminSubAgencies.columns.operatorAgencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("dateCreated", {
    header: t("adminSubAgencies.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <Link to={`/admin/sub-agencies/${id}`}>
            <ButtonEdit />
          </Link>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
