import {
  ApiResponseSearchResultsPartial,
  ApiResponseSuccessResult,
  Hotel,
  HotelRate,
  HotelRateCancellation,
  HotelRoom,
  HotelRoomGuests,
  HotelSearch,
  HotelSearchQuery,
  PaginationOptions,
  PlaceType,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";
import { Place } from "./PlaceService";

const HOTEL_API_PATH = "hotels";

export interface HotelCountByPlaceParams {
  placeId?: number;
  placeType?: PlaceType;
}

export interface HotelCountByPlaceResponse {
  total: number;
}

export interface HotelSearchPayload extends HotelSearchQuery {
  useCache?: boolean;
  useAsync?: boolean;
}

export interface HotelSearchResponse
  extends ApiResponseSearchResultsPartial<HotelSearch> {
  // @todo: add the correct typings for the filters
  filters: {
    price: {
      min: number | null;
      max: number | null;
    };
    meal: any[];
    rate: any[];
    rating: any[];
    transfers: any[];
  };
}

export type HotelMapSearchResponse =
  ApiResponseSearchResultsPartial<HotelSearch>;

export interface HotelSearchInfoResponse {
  id: number;
  search: {
    place: Place;
    checkIn: string;
    checkOut: string;
    nationality: string;
    rooms: HotelRoomGuests[];
  };
  isActive: boolean;
  isCompleted: boolean;
}

export interface HotelRoomsResponse {
  hotelId: number;
  searchId: number;
  rooms: HotelRoom[];
}

export interface HotelRateResponse extends HotelRate {
  allowLeaderOnly: boolean;
  cancellation: HotelRateCancellation;
  warnings?: string[];
  remarks: string[];
}

export interface HotelRateFeesResponse extends HotelRateCancellation {}

export enum HotelRateStatusChangeType {
  Price = "price",
  Cancellation = "cancellation",
  HotelName = "hotelName",
}

export type HotelRateStatusChangeValue =
  | string
  | {
      before: number | string;
      after: number | string;
    };

export interface HotelRateStatusResponse {
  isChanged: boolean;
  isAvailable: boolean;

  // Changes in the rate
  [HotelRateStatusChangeType.Price]?: {
    before: number;
    after: number;
  };
  [HotelRateStatusChangeType.Cancellation]?: {
    before: string;
    after: string;
  };
  [HotelRateStatusChangeType.HotelName]?: string;
}

const HotelService = {
  getCountByPlace: (params?: HotelCountByPlaceParams) => {
    return FetchService.get<HotelCountByPlaceResponse>(
      `${HOTEL_API_PATH}/count`,
      params,
    );
  },
  search: (payload: HotelSearchPayload) => {
    return FetchService.post<HotelSearchResponse>(
      `${HOTEL_API_PATH}/availability`,
      payload,
    );
  },
  getResultBySearchId: (searchId: number, params?: PaginationOptions) => {
    return FetchService.get<HotelSearchResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels`,
      params,
    );
  },
  getMapResultBySearchId: (searchId: number) => {
    return FetchService.get<HotelMapSearchResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/map`,
    );
  },
  getSearchInfo: (searchId: number) => {
    return FetchService.get<HotelSearchInfoResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}`,
    );
  },
  getDetails: (id: number) => {
    return FetchService.get<Hotel>(`${HOTEL_API_PATH}/${id}`);
  },
  getRooms: (searchId: number, hotelId: number) => {
    return FetchService.get<HotelRoomsResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/${hotelId}/rooms`,
    );
  },
  getRate: (searchId: number, hotelId: number, rateId: number) => {
    return FetchService.get<HotelRateResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/${hotelId}/rates/${rateId}`,
    );
  },
  getRateFees: (searchId: number, hotelId: number, rateId: number) => {
    return FetchService.get<HotelRateFeesResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/${hotelId}/rates/${rateId}/fees`,
    );
  },
  getRateStatus: (searchId: number, hotelId: number, rateId: number) => {
    return FetchService.get<HotelRateStatusResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/${hotelId}/rates/${rateId}/check`,
    );
  },
  addToFavorites: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${HOTEL_API_PATH}/${id}/favorite`,
    );
  },
  removeFromFavorites: (id: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${HOTEL_API_PATH}/${id}/favorite`,
    );
  },
};

export default HotelService;
