import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${spacing(1)}px;
  column-gap: ${spacing(1)}px;
`;

export const FlexGrow = styled.div`
  flex: 1;
`;

export const Strong = styled.strong`
  font-weight: 700;
`;
