import { useActivityOptions } from "@hotelspoint/api";
import { Price } from "@hotelspoint/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import MarkupPrice from "../MarkupPrice";
import * as S from "./ActivityBookPriceBreakdown.styled";
import { calculateTotalPrice } from "./ActivityBookPriceBreakdown.util";

interface ActivityBookPriceBreakdownProps {
  searchId?: number;
  activityId?: number;
  date?: string;
  option?: number;
  tickets: string | any[];
}

const ActivityBookPriceBreakdown = ({
  searchId,
  activityId,
  date,
  option,
  tickets,
}: ActivityBookPriceBreakdownProps) => {
  const { t } = useTranslation();

  const [activityOptions, isLoadingActivityOptions] = useActivityOptions({
    searchId: searchId as number,
    id: activityId as number,
    date: date as string,
  });

  const selectedOption = useMemo(() => {
    return activityOptions?.options.find(val => val.key === option);
  }, [activityOptions?.options, option]);

  const selectedTicketsList = useMemo(() => {
    if (Array.isArray(tickets)) {
      return tickets;
    }
    return tickets?.split(",").map(ticket => {
      const [key, numTickets] = ticket.split(":").map(Number);
      const item = selectedOption?.prices.find(d => d.key === key);

      return {
        numTickets: numTickets,
        price: item ? item.price : 0,
      };
    });
  }, [selectedOption, tickets]);

  // @todo: Make sure calculation is correct
  const totalPrice = useMemo(() => {
    if (Array.isArray(tickets)) {
      return tickets.reduce(
        (acc, ticket) => {
          acc.totalPrice += ticket.price * ticket.numTickets;
          acc.totalPriceNet += ticket.priceNet * ticket.numTickets;
          return acc;
        },
        { totalPrice: 0, totalPriceNet: 0 },
      );
    }
    return calculateTotalPrice(selectedOption, tickets);
  }, [selectedOption, tickets]);

  return (
    <>
      {isLoadingActivityOptions ? (
        <Skeleton />
      ) : (
        <>
          <div>
            {selectedTicketsList?.map((ticket, index) => (
              <S.Flex key={index}>
                <p>
                  {ticket.numTickets}x {ticket.name}
                </p>
                <S.Price>
                  <Price value={ticket.price} />
                </S.Price>
              </S.Flex>
            ))}
          </div>
          <S.Flex>
            <S.PriceTitle>
              {t("searchActivityBook.sidebar.totalPrice")}
            </S.PriceTitle>
            <MarkupPrice
              value={{
                total: totalPrice.totalPrice,
                net: totalPrice.totalPriceNet,
              }}
            >
              <S.PriceTotal>
                <Price value={totalPrice.totalPrice} />
              </S.PriceTotal>
            </MarkupPrice>
          </S.Flex>
        </>
      )}
    </>
  );
};

export default ActivityBookPriceBreakdown;
