import { ActivityOptionPrice, ActivityPickupLocation } from "@hotelspoint/api";
import {
  Box,
  Flex,
  FormAdapter,
  FormClearableInput,
  FormSelect,
} from "@hotelspoint/components";
import { IconMapPinFilled } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import * as S from "../../SearchActivityBook/SearchActivityBookContent/SearchActivityBookForm/SearchActivityBookForm.styled";

interface ActivityPickupDetailsProps {
  prices: ActivityOptionPrice[];
}

const mapLocations = (locations: ActivityPickupLocation[]) => {
  return locations.map(location => ({
    label: location.name,
    value: location.id,
  }));
};

const ActivityPickupDetails = ({ prices }: ActivityPickupDetailsProps) => {
  const { t } = useTranslation();

  const hasPickupLocation = useMemo(() => {
    return prices.some(price => price.requirements.pickupLocation);
  }, [prices]);

  const pickupOptions = useMemo(() => {
    let foundFirst = false;

    return prices
      .map(item => {
        if (!foundFirst && item.requirements?.pickupOptions) {
          foundFirst = true;
          return item.requirements?.pickupOptions;
        }
        return null;
      })
      .filter(options => options !== null)
      .flat();
  }, [prices]);

  const mappedLocations = useMemo(() => {
    return mapLocations(pickupOptions);
  }, [pickupOptions]);

  if (!hasPickupLocation && pickupOptions.length === 0) {
    return null;
  }

  return (
    <>
      <S.SectionTitle>
        <IconMapPinFilled size={18} />
        <span>{t("searchActivityBook.content.location")}</span>
      </S.SectionTitle>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={[1, 1, 6 / 12]} px={[0, 0, 1, 1]} py={1}>
          {hasPickupLocation && (
            <FormAdapter
              name="pickupLocation"
              label={t("searchActivityBook.content.form.pickupLocation.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "searchActivityBook.content.form.pickupLocation.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          )}
          {pickupOptions.length > 0 && (
            <FormAdapter
              name="pickupOption"
              label={t("searchActivityBook.content.form.pickupOption.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  options={mappedLocations}
                  isSearchable={false}
                  placeholder={t(
                    "searchActivityBook.content.form.pickupOption.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default ActivityPickupDetails;
