import { useHotelCountByPlace, usePlace, usePlaces } from "@hotelspoint/api";
import {
  Button,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormChildrenMethods,
  FormContext,
  FormDatePickerRange,
  FormLayout,
  FormRoomSelector,
  FormSelect,
  OptionGroup,
  PlaceTypeIcon,
  TextItalic,
} from "@hotelspoint/components";
import { countryGroups } from "@hotelspoint/constants";
import { useRecentHotelSearchesStore } from "@hotelspoint/store";
import { HotelSearchQuery, PlaceType } from "@hotelspoint/types";
import { addDays, getPlaceTypeName, isDateValid } from "@hotelspoint/utils";
import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import qs from "query-string";
import { useCallback, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { PLACE_TYPE_ORDER, PLACE_TYPES } from "../../constants";
import {
  form2Entity,
  FormValues,
  validationSchema,
} from "../../SearchHotelsForm/SearchHotelsForm.form";
import * as S from "../../SearchHotelsForm/SearchHotelsForm.styled";
import { SearchHotelsViewState } from "../../types";
import useHotelSearchParams from "../../useHotelSearchParams";
import { entity2Form } from "./SearchHotelsForm.util";

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

interface SearchHotelsFormParams extends Record<string, string> {
  id: string;
}

interface SearchHotelsFormProps {
  refetch: () => Promise<any>;
  isLoading: boolean;
}

interface SearchHotelsFormInnerProps
  extends SearchHotelsFormProps,
    FormChildrenMethods<FormValues> {}

const SearchHotelsFormInner = ({
  refetch,
  setValue,
  getValues,
  isLoading,
}: SearchHotelsFormInnerProps) => {
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);
  const currentYear = useMemo(() => dateToday.getFullYear(), [dateToday]);

  const navigate = useNavigate();

  const [query, setQuery] = useHotelSearchParams();

  const [search, place, dates] = useWatch({
    name: ["search", "place", "dates"],
  });
  const [debouncedSearch] = useDebounce(search, 350);

  const [hotelsCount, isLoadingHotelsCount] = useHotelCountByPlace({
    placeId: place?.id,
    placeType: place?.type !== PlaceType.Hotel ? place?.type : undefined,
  });

  const [places, isLoadingPlaces] = usePlaces({
    types: PLACE_TYPES,
    search: debouncedSearch ?? "",
  });

  const [placeDetails, isLoadingPlaceDetails] = usePlace({
    id: place?.id as number,
    type: place?.type as PlaceType,
  });

  const recentSearchesStore = useRecentHotelSearchesStore(state => state);

  const countryOptions: OptionGroup[] = useMemo(
    () =>
      countryGroups.map(group => ({
        label: t(`countries.groups.${group.label}`),
        options: group.options.map(value => ({
          value,
          label: t(`countries.${value}`),
        })),
      })),
    [t],
  );

  const placeGroupOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!places || isLoadingPlaces) return [];

    const groups = Object.entries(places)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);

    return groups.sort((a, b) => {
      return (
        PLACE_TYPE_ORDER.indexOf(a.value) - PLACE_TYPE_ORDER.indexOf(b.value)
      );
    });
  }, [t, places, isLoadingPlaces]);

  const placeOptionsFlat = useMemo(() => {
    return flatten(placeGroupOptions.map(group => group.options));
  }, [placeGroupOptions]);

  const displayCounter = useMemo(() => {
    return place?.id && place?.type !== PlaceType.Hotel;
  }, [place]);

  const defaultMonth = useMemo(() => {
    const initialDates = getValues("dates") || dates;

    if (initialDates?.from && isDateValid(initialDates?.from)) {
      const { from } = initialDates;

      return new Date(from.getFullYear(), from.getMonth());
    }

    return new Date(currentYear, dateToday.getMonth());
  }, [currentYear, dateToday, dates, getValues]);

  const disabledDates = useMemo(() => {
    if (isDateValid(dates?.from) && !dates?.to) {
      return addDays(dates.from, 1);
    }

    return dateToday;
  }, [dateToday, dates?.from, dates?.to]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const payload = form2Entity(formValues);

      const newQuery = {
        ...payload,
        view: SearchHotelsViewState.List,
        // Make sure we make fresh search every time
        useCache: false,
      };

      // Find the place option and set the name inside the meta
      const placeOption = placeOptionsFlat.find(
        p => p?.value === payload.placeId,
      );

      // Populate the recent searches store
      if (placeOption) {
        const queryAttributesToSave = omit(newQuery, ["nationality"]);

        recentSearchesStore.add({
          ...queryAttributesToSave,
          meta: {
            placeName: placeOption?.label as string,
          },
        });
      }

      // If the selected place is of type hotel, navigate to the hotel page
      const queryParams = omit(newQuery, ["view"]);
      const search = qs.stringify({
        ...queryParams,
        rooms: JSON.stringify(newQuery.rooms),
      });

      switch (newQuery.placeType) {
        case PlaceType.Hotel:
          navigate({
            pathname: `/search/hotels/${newQuery.placeId}`,
            search,
          });
          break;

        case PlaceType.City:
          navigate({
            pathname: `/search/hotels/`,
            search,
          });
          break;

        default:
          if (isEqual(query, newQuery)) {
            refetch();
          } else {
            setQuery(newQuery);
          }
      }
    },
    [placeOptionsFlat, recentSearchesStore, navigate, query, setQuery, refetch],
  );

  const handleSelectPlace = useCallback(
    (option: any) => {
      if (option) {
        setValue("place", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("place", {});
      }
    },
    [setValue],
  );

  // Set the search value when the place details are loadead
  useEffect(() => {
    if (place?.id && placeDetails?.name) {
      setValue("search", placeDetails?.name);
    }
  }, [place?.id, placeDetails?.name, setValue]);

  return (
    <FormLayout>
      <FormAdapter name="search" label={t("searchHotels.search.label")}>
        {props => (
          <FormAutoComplete
            {...props}
            placeholder={t("searchHotels.search.placeholder")}
            options={placeGroupOptions}
            isLoading={isLoadingPlaces || isLoadingPlaceDetails}
            renderOption={option => (
              <S.PlaceOption key={option.value}>
                <PlaceTypeIcon
                  type={option.meta.type}
                  iconProps={{ size: 22 }}
                />
                <span>{option.label}</span>
              </S.PlaceOption>
            )}
            onSelect={handleSelectPlace}
          />
        )}
      </FormAdapter>
      <FormAdapter name="dates" label={t("searchHotels.dates.label")}>
        {props => (
          <FormDatePickerRange
            {...props}
            placeholder={t("searchHotels.dates.placeholder")}
            dayPickerProps={{
              disabled: [{ before: disabledDates }],
              defaultMonth: defaultMonth,
              fromYear: currentYear,
            }}
          />
        )}
      </FormAdapter>
      <FormAdapter name="rooms" label={t("searchHotels.rooms.label")}>
        {props => (
          <FormRoomSelector
            {...props}
            placeholder={t("searchHotels.rooms.placeholder")}
          />
        )}
      </FormAdapter>
      <FormAdapter
        name="nationality"
        label={t("searchHotels.nationality.label")}
      >
        {props => (
          <FormSelect
            {...props}
            placeholder={t("searchHotels.nationality.placeholder")}
            options={countryOptions}
            isClearable={false}
          />
        )}
      </FormAdapter>
      {displayCounter && (
        <TextItalic>
          {isLoadingHotelsCount ? (
            <Skeleton height={10} />
          ) : (
            t("searchHotels.count", {
              count: hotelsCount,
            })
          )}
        </TextItalic>
      )}
      <FormContext<FormValues>
        render={({ handleSubmit }) => (
          <Button
            type="submit"
            variant="secondary"
            fullWidth
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t("searchHotels.submit")}
          </Button>
        )}
      />
    </FormLayout>
  );
};

const SearchHotelsForm = (props: SearchHotelsFormProps) => {
  const [searchParams] = useHotelSearchParams();

  const { id } = useParams<SearchHotelsFormParams>();

  const formValues = useMemo(() => {
    if (id) {
      const updatedSearchParams = {
        ...searchParams,
        placeId: Number(id),
        placeType: PlaceType.Hotel,
      };

      return entity2Form(updatedSearchParams as HotelSearchQuery);
    }
    return entity2Form(searchParams as HotelSearchQuery);
  }, [id, searchParams]);

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
    >
      {formMethods => <SearchHotelsFormInner {...props} {...formMethods} />}
    </Form>
  );
};

export default SearchHotelsForm;
