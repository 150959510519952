import { IconClockX } from "@tabler/icons-react";

import * as S from "./EmptyRecentSearchState.styled";

interface EmptyRecentSearchStateProps {
  title: string;
  description?: string;
}

const EmptyRecentSearchState = ({
  title,
  description,
}: EmptyRecentSearchStateProps) => {
  return (
    <S.Wrapper>
      <IconClockX size={40} />
      <S.InnerWrapper>
        <S.Title>{title}</S.Title>
        {description && <p>{description}</p>}
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default EmptyRecentSearchState;
