import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: ${spacing(2)}px;
  min-height: 200px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
