import { MessageType } from "@hotelspoint/api";
import {
  ReservationInvoicesTable,
  ReservationMessages,
  ReservationPaymentsTable,
} from "@hotelspoint/components";

import ProfileReservationActivityBaseInfo from "../ProfileReservationActivityBaseInfo";

interface ProfileReservationActivityContentProps {
  id: number;
}

const ProfileReservationActivityContent = ({
  id,
}: ProfileReservationActivityContentProps) => {
  return (
    <>
      <ProfileReservationActivityBaseInfo id={id} />
      <ReservationPaymentsTable id={id} />
      <ReservationInvoicesTable id={id} />
      {Object.values(MessageType).map(type => (
        <ReservationMessages key={type} id={id} type={type} />
      ))}
    </>
  );
};

export default ProfileReservationActivityContent;
