import { IconSearch } from "@tabler/icons-react";

import * as S from "./EmptySearchState.styled";

interface EmptySearchStateProps {
  title: string;
  description?: string;
}

const EmptySearchState = ({ title, description }: EmptySearchStateProps) => {
  return (
    <S.Wrapper>
      <IconSearch size={48} />
      <S.InnerWrapper>
        <h3>{title}</h3>
        {description && <p>{description}</p>}
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default EmptySearchState;
