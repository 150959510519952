import {
  handleError,
  InvoiceStatus,
  InvoiceType,
  useAddCreditNoteInvoice,
  useAddDebitNoteInvoice,
  useInvoice,
} from "@hotelspoint/api";
import { AmountDialog, Button, LinkExternal } from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { getApiUrl } from "@hotelspoint/utils";
import { IconPdf } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useInvoiceQueryParams from "../useInvoiceQueryParams";
import {
  getCreditNoteValidationSchema,
  getDebitNoteValidationSchema,
} from "./AdminInvoiceActions.util";

interface AdminInvoiceActionsProps {
  id: number;
}

const AdminInvoiceActions = ({ id }: AdminInvoiceActionsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [query] = useInvoiceQueryParams();

  const [isDebitNoteDialogOpen, setIsDebitNoteDialogOpen] = useState(false);
  const [isCreditNoteDialogOpen, setIsCreditNoteDialogOpen] = useState(false);

  const token = useUserTokenStore(state => state.token);

  const [invoice, isLoadingInvoice] = useInvoice(id);
  const [addDebitNote, isAddingDebitNote] = useAddDebitNoteInvoice(id);
  const [addCreditNote, isAddingCreditNote] = useAddCreditNoteInvoice(id);

  const previewUrl = useMemo(() => {
    return getApiUrl(`invoices/${id}/view`, {
      token,
      amount: query.amount,
    });
  }, [id, token, query]);

  const handleAddDebitNote = useCallback(
    async (amount: number) => {
      try {
        const response = await addDebitNote({ amount });
        toast.success(t("toast.adminInvoice.addDebitNote"));

        if (response.id) {
          navigate(`/admin/invoices/${response.id}`);
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, addDebitNote, navigate],
  );

  const handleAddCreditNote = useCallback(
    async (amount: number) => {
      try {
        const response = await addCreditNote({ amount });
        toast.success(t("toast.adminInvoice.addCreditNote"));

        if (response.id) {
          navigate(`/admin/invoices/${response.id}`);
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, addCreditNote, navigate],
  );

  if (!invoice || isLoadingInvoice) return null;

  return (
    <>
      <AmountDialog
        isOpen={isDebitNoteDialogOpen}
        setIsOpen={setIsDebitNoteDialogOpen}
        title={t("adminInvoice.actions.addDebitNote.modal.title")}
        onConfirm={handleAddDebitNote}
        isLoading={isAddingDebitNote}
        validationSchema={getDebitNoteValidationSchema({
          maxAmount: invoice.amount,
        })}
      />
      <AmountDialog
        isOpen={isCreditNoteDialogOpen}
        setIsOpen={setIsCreditNoteDialogOpen}
        title={t("adminInvoice.actions.addCreditNote.modal.title")}
        onConfirm={handleAddCreditNote}
        isLoading={isAddingCreditNote}
        initialValue={-invoice.amount}
        validationSchema={getCreditNoteValidationSchema({
          minAmount: -invoice.amount,
        })}
      />
      {invoice.type === InvoiceType.Invoice &&
        invoice.status === InvoiceStatus.Completed && (
          <>
            <Button
              small
              variant="success"
              onClick={() => setIsDebitNoteDialogOpen(true)}
            >
              {t("adminInvoice.actions.addDebitNote.button")}
            </Button>
            <Button
              small
              variant="error"
              onClick={() => setIsCreditNoteDialogOpen(true)}
            >
              {t("adminInvoice.actions.addCreditNote.button")}
            </Button>
          </>
        )}
      {invoice.status === InvoiceStatus.Completed && (
        <LinkExternal to={previewUrl}>
          <Button variant="secondary" small>
            <IconPdf size={18} />
            <span>{t("adminInvoice.actions.view")}</span>
          </Button>
        </LinkExternal>
      )}
    </>
  );
};

export default AdminInvoiceActions;
