import minimapBg from "./assets/minimap-bg.png";
import * as S from "./Minimap.styled";

interface MinimapProps {
  children: React.ReactNode;
}

const Minimap = ({ children }: MinimapProps) => {
  return <S.Wrapper $src={minimapBg}>{children}</S.Wrapper>;
};

export default Minimap;
