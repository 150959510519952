import { countryGroups } from "@hotelspoint/constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { OptionGroup } from "../../common";
import {
  FormAdapter,
  FormClearableInput,
  FormDatePickerSingle,
  FormSelect,
} from "../../form";
import { Box } from "../../grid";

interface ActivityRequirementsFormProps {
  requirements: any;
  name?: string;
}

const ActivityRequirementsForm = ({
  requirements,
  name,
}: ActivityRequirementsFormProps) => {
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);

  const placeholderMap: any = {
    birthdays: {
      label: t("searchActivityBook.content.form.birthday.label"),
      placeholder: t("searchActivityBook.content.form.birthday.placeholder"),
    },
    ages: {
      label: t("searchActivityBook.content.form.ages.label"),
    },
    nationality: {
      label: t("searchActivityBook.content.form.nationality.label"),
      placeholder: t("searchActivityBook.content.form.nationality.placeholder"),
    },
    passportNumbers: {
      label: t("searchActivityBook.content.form.passportNumbers.label"),
      placeholder: t(
        "searchActivityBook.content.form.passportNumbers.placeholder",
      ),
    },
  };

  const countryOptions: OptionGroup[] = useMemo(
    () =>
      countryGroups.map(group => ({
        label: t(`countries.groups.${group.label}`),
        options: group.options.map(value => ({
          value,
          label: t(`countries.${value}`),
        })),
      })),
    [t],
  );

  return Object.entries(requirements).reduce(
    (elements: JSX.Element[], [key], index) => {
      const placeholder = placeholderMap[key];

      let element;

      switch (key) {
        case "birthdays":
          element = (
            <Box key={index} width={[1, 1, 3 / 12]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter name={`${name}.birthday`} label={placeholder.label}>
                {props => (
                  <FormDatePickerSingle
                    {...props}
                    placeholder={placeholder.placeholder}
                    dayPickerProps={{
                      fromYear: 1900,
                      toYear: new Date().getFullYear(),
                      captionLayout: "dropdown",
                      disabled: { after: dateToday },
                    }}
                  />
                )}
              </FormAdapter>
            </Box>
          );
          break;

        case "ages":
          element = (
            <Box key={index} width={[1, 1, 1 / 12]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter name={`${name}.ages`} label={placeholder.label}>
                {props => (
                  <FormClearableInput {...props} type="number" readOnly />
                )}
              </FormAdapter>
            </Box>
          );
          break;

        case "nationality":
          element = (
            <Box key={index} width={[1, 1, 4 / 12]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name={`${name}.nationality`}
                label={t("searchActivityBook.content.form.nationality.label")}
              >
                {props => (
                  <FormSelect
                    {...props}
                    options={countryOptions}
                    placeholder={t(
                      "searchActivityBook.content.form.nationality.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </Box>
          );
          break;

        case "passportNumbers":
          element = (
            <Box key={index} width={[1, 1, 3 / 12]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name={`${name}.passportNumbers`}
                label={t(
                  "searchActivityBook.content.form.passportNumbers.label",
                )}
              >
                {props => (
                  <FormClearableInput
                    {...props}
                    placeholder={t(
                      "searchActivityBook.content.form.passportNumbers.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </Box>
          );
          break;

        default:
          return elements;
      }
      elements.push(element);
      return elements;
    },
    [],
  );
};

export default ActivityRequirementsForm;
