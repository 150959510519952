import { mediaQuery } from "@hotelspoint/theme";

import * as S from "./PageSplitLayout.styled";

interface PageSplitLayoutProps {
  children: React.ReactNode | React.ReactNode;
  wide?: boolean;
  breakpoint?: string;
}

const PageSplitLayout = ({
  children,
  wide = false,
  breakpoint = mediaQuery.tablet,
}: PageSplitLayoutProps) => {
  return (
    <S.Wrapper $wide={wide} $breakpoint={breakpoint}>
      {children}
    </S.Wrapper>
  );
};

PageSplitLayout.Sidebar = S.Sidebar;
PageSplitLayout.Content = S.Content;

export default PageSplitLayout;
