import { styled } from "styled-components";
import { theme, withProp } from "styled-tools";

const getColorByPointsCount = (count: number) => {
  if (count > 50) return theme("palette.red");
  if (count > 20) return theme("palette.sorbus");
  if (count > 10) return theme("palette.buttercup");

  return theme("palette.seaGreen");
};

export const Cluster = styled.div<{
  $count: number;
  $totalCount: number;
}>`
  cursor: pointer;
  /* todo: It may need additional adjustment of colors and proportions.  */
  position: relative;
  width: ${({ $count, $totalCount }) =>
    `${18 + ($count / $totalCount) * 200}px`};
  height: ${({ $count, $totalCount }) =>
    `${18 + ($count / $totalCount) * 200}px`};
  line-height: ${({ $count, $totalCount }) =>
    `${18 + ($count / $totalCount) * 200}px`};
  background-color: ${withProp("$count", getColorByPointsCount)};
  border-radius: 100%;
  text-align: center;
  color: ${theme("palette.black")};

  &::before {
    content: "";
    position: absolute;
    inset: -5px;
    border-radius: 50%;
    background-color: ${withProp("$count", getColorByPointsCount)};
    opacity: 0.5;
    z-index: -1;
  }
`;
