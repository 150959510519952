import {
  TransferSearchPayload,
  useTransferAvailabilitySearch,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormLayout,
  FormRadio,
  MarkupPrice,
  PassengerInfo,
  PassengerType,
  Price,
} from "@hotelspoint/components";
import {
  IconAddressBook,
  IconManFilled,
  IconPlaneArrival,
  IconPlaneDeparture,
  IconUserFilled,
} from "@tabler/icons-react";
import DOMPurify from "dompurify";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import {
  OutboundTransferInfo,
  ReturnTransferInfo,
  TransferContactsInfo,
} from "../../../components";
import useTransferSearchParams from "../../useTransferSearchParams";
import * as S from "./SearchTransferBookForm.styled";
import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchTransferBookForm.util";
import { FormType } from "./types";

interface SearchTransferBookFormParams extends Record<string, string> {
  id: string;
}

interface SearchTransferBookFormInner {
  query: TransferSearchPayload;
}

const SearchTransferBookFormInner = ({
  query,
}: SearchTransferBookFormInner) => {
  const { t } = useTranslation();

  const { id } = useParams<SearchTransferBookFormParams>();
  const transferId = Number(id);

  const [formType] = useWatch({ name: ["type"] });

  const [search, isLoadingSearch] = useTransferAvailabilitySearch(
    query as TransferSearchPayload,
  );

  const selectedTransfer = search?.results?.find(
    transfer => transfer.id === Number(transferId),
  );
  const onSubmit = (formValues: FormValues) => {
    console.log("form2Entity", form2Entity(formValues));
    console.log("book");
  };

  return (
    <FormLayout>
      <S.RadioGroup>
        {isLoadingSearch ? (
          <Skeleton />
        ) : (
          <Flex alignItems={"center"}>
            <Box width={[2 / 14]}>
              <p>{t("searchTransferBook.content.radioGroup.type")}</p>
            </Box>
            <Box width={[12 / 14]}>
              <FormAdapter name="type">
                {props => (
                  <Flex alignItems={"center"}>
                    <Box width={[6 / 12]}>
                      <FormRadio
                        {...props}
                        id={FormType.LeaderOnly}
                        value={FormType.LeaderOnly}
                        label={t(
                          "searchTransferBook.content.radioGroup.leaderOnly",
                        )}
                        checked={formType === FormType.LeaderOnly}
                      />
                    </Box>
                    <Box width={[6 / 12]} px={[0, 0, 1, 1]}>
                      <FormRadio
                        {...props}
                        id={FormType.AllPassengers}
                        value={FormType.AllPassengers}
                        label={t(
                          "searchTransferBook.content.radioGroup.allPassengers",
                        )}
                        checked={formType === FormType.AllPassengers}
                      />
                    </Box>
                  </Flex>
                )}
              </FormAdapter>
            </Box>
          </Flex>
        )}
      </S.RadioGroup>
      <S.SectionTitle>
        <IconUserFilled size={18} />
        {isLoadingSearch ? (
          <S.FlexGrow>
            <Skeleton />
          </S.FlexGrow>
        ) : (
          <span>
            {t("searchTransferBook.content.adult", {
              count: query.adults as number,
            })}
          </span>
        )}
      </S.SectionTitle>
      {isLoadingSearch ? (
        <PassengerInfo isLoading={isLoadingSearch} />
      ) : formType === FormType.LeaderOnly ? (
        <PassengerInfo name="leader.0" />
      ) : (
        Array.from({ length: query.adults as number }).map((_, adultIndex) => {
          const name = `adults.${adultIndex}`;
          return <PassengerInfo key={adultIndex} name={name} />;
        })
      )}
      {formType !== FormType.LeaderOnly && query.children.length !== 0 && (
        <>
          <S.SectionTitle>
            <IconManFilled size={18} />
            <span>{t("searchTransferBook.content.children")}</span>
          </S.SectionTitle>
          {query.children.map((_: number, childIndex: number) => {
            const name = `children.${childIndex}`;
            return (
              <PassengerInfo
                key={childIndex}
                name={name}
                type={PassengerType.Children}
              />
            );
          })}
        </>
      )}
      <S.SectionTitle>
        <IconAddressBook size={18} />
        {isLoadingSearch ? (
          <S.FlexGrow>
            <Skeleton />
          </S.FlexGrow>
        ) : (
          <span>{t("searchTransferBook.content.contacts")}</span>
        )}
      </S.SectionTitle>
      {isLoadingSearch ? (
        <PassengerInfo isLoading={isLoadingSearch} />
      ) : (
        <TransferContactsInfo />
      )}
      <S.Divider />
      {selectedTransfer?.outboundTransfer.remarks.length !== 0 && (
        <>
          <S.Title>{t("searchTransferBook.content.importantInfo")}</S.Title>
          <S.SectionTitle>
            <IconPlaneDeparture size={18} />
            {isLoadingSearch ? (
              <S.FlexGrow>
                <Skeleton />
              </S.FlexGrow>
            ) : (
              <span>{t("searchTransferBook.content.outboundTransfer")}</span>
            )}
          </S.SectionTitle>
          <OutboundTransferInfo />
          {selectedTransfer?.outboundTransfer?.remarks?.map((remark, index) => (
            <div key={index}>
              <span
                key={index}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(remark),
                }}
              />
            </div>
          ))}
        </>
      )}
      <S.Divider />
      <S.SectionTitle>
        <IconPlaneArrival size={18} />
        {isLoadingSearch ? (
          <S.FlexGrow>
            <Skeleton />
          </S.FlexGrow>
        ) : (
          <span>{t("searchTransferBook.content.returnTransfer")}</span>
        )}
      </S.SectionTitle>
      <ReturnTransferInfo />
      {selectedTransfer?.returnTransfer.remarks.length !== 0 && (
        <>
          {selectedTransfer?.returnTransfer.remarks.map((remark, index) => (
            <div key={index}>
              <span
                key={index}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(remark),
                }}
              />
            </div>
          ))}
        </>
      )}
      <S.FormFooter>
        {isLoadingSearch ? (
          <S.FlexGrow>
            <Skeleton />
          </S.FlexGrow>
        ) : (
          <S.FormPriceWrap>
            <p>{t("searchTransferBook.content.totalPrice")}</p>
            <MarkupPrice
              value={{
                total: selectedTransfer?.price as number,
                net: selectedTransfer?.priceNet as number,
              }}
            >
              <S.PriceTotal>
                <Price value={selectedTransfer?.price as number} />
              </S.PriceTotal>
            </MarkupPrice>
          </S.FormPriceWrap>
        )}
        <FormContext<any>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoadingSearch}
              onClick={handleSubmit(onSubmit)}
            >
              {t("searchTransferBook.content.form.submit")}
            </Button>
          )}
        />
      </S.FormFooter>
    </FormLayout>
  );
};

const SearchTransferBookForm = () => {
  const [query] = useTransferSearchParams();

  const formValues = useMemo(() => {
    if (!query) return defaultValues;
    const children = Array.isArray(query.children) ? query.children : [];

    return entity2Form(children, query as TransferSearchPayload);
  }, [query]);

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => (
        <SearchTransferBookFormInner
          query={query as TransferSearchPayload}
          {...formMethods}
        />
      )}
    </Form>
  );
};

export default SearchTransferBookForm;
