import { isTimeValid } from "@hotelspoint/utils";
import { IconClock } from "@tabler/icons-react";
import padStart from "lodash/padStart";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TimeKeeper from "react-timekeeper";

import Popover from "../../composites/Popover";
import Button from "../../elements/Button";
import FormClearableInput from "../FormClearableInput";
import { type FormControlInjectedProps } from "../FormControl";

interface FormTimePickerProps
  extends FormControlInjectedProps,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      "id" | "name" | "value" | "onChange"
    > {}

const FormTimePicker = ({
  value,
  onChange,
  disabled = false,
  readOnly = false,
  ...rest
}: FormTimePickerProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  const handleOpen = () => {
    setIsOpen(!disabled && !readOnly);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} placement="bottom-start">
      <Popover.Trigger onClick={handleOpen}>
        <FormClearableInput
          value={value}
          onChange={onChange}
          startAdornment={<IconClock size={18} />}
          style={{ userSelect: "none" }}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </Popover.Trigger>
      <Popover.Content>
        <TimeKeeper
          time={isTimeValid(value) ? value : undefined}
          onChange={data => {
            const { hour, minute } = data;
            const hours = padStart(`${hour}`, 2, "0");
            const minutes = padStart(`${minute}`, 2, "0");

            onChange(`${hours}:${minutes}`);
          }}
          hour24Mode
          switchToMinuteOnHourSelect
          closeOnMinuteSelect
          doneButton={() => (
            <Button fullWidth onClick={handleClose}>
              {t("components.formTimePicker.done")}
            </Button>
          )}
          onDoneClick={handleClose}
        />
      </Popover.Content>
    </Popover>
  );
};

export default FormTimePicker;
