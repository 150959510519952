import { zIndex } from "@hotelspoint/theme";

import { Menu } from "../../../composites/Menu";
import * as S from "./HeaderMenu.styled";

interface HeaderMenuProps {
  menuButton: React.ReactNode;
  children: React.ReactNode;
  withCaret?: boolean;
  menuStyle?: React.CSSProperties;
}

const HeaderMenu = ({
  menuButton,
  children,
  withCaret = true,
  menuStyle = {},
}: HeaderMenuProps) => {
  return (
    <Menu
      transition
      arrow
      direction="bottom"
      align="center"
      viewScroll="close"
      initialMounted
      menuStyle={{ zIndex: zIndex.headerMenu, ...menuStyle }}
      menuButton={({ open }: { open: boolean }) => (
        <S.ButtonWrapper type="button">
          {menuButton}
          {withCaret && (
            <S.CaretIcon
              size={18}
              style={{
                transform: open ? "rotate(180deg)" : undefined,
              }}
            />
          )}
        </S.ButtonWrapper>
      )}
    >
      {children}
    </Menu>
  );
};

export default HeaderMenu;
