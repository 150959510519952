import {
  handleError,
  useAdminSupplierStatements,
  useDeleteAdminSupplierStatement,
} from "@hotelspoint/api";
import {
  Button,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import getColumns from "./AdminSupplierStatements.columns";
import AdminSupplierStatementsFilters from "./AdminSupplierStatementsFilters";
import useAdminSupplierStatementsParams from "./useAdminSupplierStatementsParams";

const AdminSupplierStatements = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminSupplierStatements.pageTitle"));

  const [targetId, setTargetId] = useState<number | null>(null);

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useAdminSupplierStatementsParams();
  const filters = useMemo(() => pickFilterValues(query), [query]);

  const [statements, isLoading] = useAdminSupplierStatements({
    pageIndex,
    pageSize,
    ...filters,
  });

  const [deleteStatement, isDeleting] = useDeleteAdminSupplierStatement();

  const handleDelete = useCallback(
    async (id: number) => {
      try {
        setTargetId(id);
        await deleteStatement(id);
        toast.success(t("toast.adminSupplierStatement.delete"));
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetId(null);
      }
    },
    [t, deleteStatement],
  );

  const data = useMemo(() => statements?.results ?? [], [statements]);

  const columns = useMemo(() => {
    return getColumns({ t, targetId, handleDelete, isDeleting });
  }, [t, targetId, handleDelete, isDeleting]);

  return (
    <Panel
      title={t("adminSupplierStatements.pageTitle")}
      actions={
        <Link to="/admin/statements/suppliers/create">
          <Button small variant="secondary">
            {t("adminSupplierStatements.actions.create")}
          </Button>
        </Link>
      }
    >
      <AdminSupplierStatementsFilters isLoading={isLoading} />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          useReactTableProps={{
            state: {
              pagination: { pageIndex, pageSize: TABLE_PAGE_SIZE_DEFAULT },
              columnPinning: { left: ["id"], right: ["actions"] },
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={statements?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={statements?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminSupplierStatements;
