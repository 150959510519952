import { Paper } from "@hotelspoint/components";
import { borderRadius, mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { prop, theme } from "styled-tools";

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(3)}px;
  padding: ${spacing(2)}px;
`;

export const TransferCardWrapper = styled.div`
  display: flex;
  gap: ${spacing(2)}px;
  justify-content: space-between;
`;

export const TransferCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(1)}px;
`;

export const Title = styled.h5`
  margin-bottom: ${spacing(1.25)}px;
  font-weight: 700;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${spacing(2)}px;
  row-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(3)}px;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
  font-size: ${pxToRem(14)};
  line-height: 1;
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(1)}px;
  h6 {
    font-weight: 600;
    font-size: ${pxToRem(16)};
  }
`;

export const TransferWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${spacing(2)}px;
`;

export const TransferDirectionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const TransferDirection = styled.p`
  font-weight: 700;
  text-transform: uppercase;
  color: ${theme("palette.ocean")};
  opacity: 0.5;
`;

export const TransferDateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(1.25)}px;
`;

export const TransferDateTime = styled.div`
  background-color: ${theme("palette.whiteSand")};
  padding: 0 ${spacing(0.625)}px;
  border-radius: ${borderRadius.medium}px;
`;

export const TransferPlaceTitle = styled.h5`
  margin-bottom: ${spacing(2)}px;
  font-weight: 600;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${spacing(1)}px;
  column-gap: ${spacing(1)}px;
  @media ${mediaQuery.desktop} {
    flex-wrap: nowrap;
    row-gap: 0;
  }
`;

export const PriceList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(1)}px;
  justify-content: space-between;
`;

export const PriceItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: ${spacing(0.5)}px;
  font-size: ${pxToRem(16)};
  font-weight: 600;
`;

export const PriceTitle = styled.p`
  font-weight: 600;
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: ${pxToRem(20)};
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
`;

export const Thumbnail = styled.div<{ $src: string | null }>`
  margin-left: 0;
  background: url(${prop("$src")}) no-repeat;
  background-size: contain;
  background-position: center center;
  @media ${mediaQuery.tablet} {
    min-width: 150px;
    height: 100%;
    margin-left: ${spacing(1.5)}px;
  }
`;

export const Separator = styled.div`
  width: 1px;
  background-color: ${theme("palette.silver")};
  margin: ${spacing(0.625)}px 0;
  align-self: stretch;
`;
