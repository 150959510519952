import { Breakpoint, spacing } from "../utils";
import palette from "./palette";

export { default as borderRadius } from "./borderRadius";
export { default as palette } from "./palette";
export { default as timing } from "./timing";

const theme = {
  palette,

  body: {
    bg: palette.whiteSand,
    text: palette.ocean,
  },

  header: {
    bg: palette.ocean,
  },
  footer: {
    bg: palette.mineShaft,
    text: "rgba(255, 255, 255, 0.5)",

    link: {
      text: "rgba(255, 255, 255, 0.7)",
      hover: palette.sun,
    },
  },

  primary: {
    bg: palette.ocean,
    hover: palette.sky,
  },
  secondary: {
    bg: palette.sun,
    hover: palette.sorbus,
  },
  tertiary: {
    bg: palette.sky,
    hover: palette.ocean,
  },

  // States
  success: {
    primary: palette.malachite,
    secondary: palette.peppermint,
  },
  info: {
    primary: palette.sky,
    secondary: palette.frenchPass,
  },
  warning: {
    primary: palette.buttercup,
    secondary: palette.picasso,
  },
  error: {
    primary: palette.cinnabar,
    secondary: palette.yourPink,
  },

  disabled: {
    bg: palette.silver,
    text: palette.nevada,
  },

  link: {
    text: palette.sky,
    hover: palette.royalBlue,
  },

  // Sizings
  custom: {
    body: {
      fontSize: 16,
    },
    container: {
      maxWidth: 1150,
      maxWidthAdmin: 1550,
    },
    header: {
      heightS: 50,
      heightL: 65,
    },
    sidebar: {
      width: 290,
      widthWide: 400,
    },
    modal: {
      widthS: 320,
      widthL: 768,
    },
  },

  // Breakpoints & spacings for grid system - `@rebass/grid`
  breakpoints: [
    Breakpoint.Tablet,
    Breakpoint.Desktop,
    Breakpoint.AppMaxWidth,
  ].map(value => `${value}px`),
  // Generate twelve spacing values e.g. [0, 8, 16, ..]
  space: Array.from({ length: 12 }, (_, index) => spacing(index)),
};

export default theme;
