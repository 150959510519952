import {
  TransferSearchPayload,
  usePlace,
  useTransferAvailabilitySearch,
} from "@hotelspoint/api";
import { Button, MarkupPrice, Price } from "@hotelspoint/components";
import { PlaceType } from "@hotelspoint/types";
import {
  formatDateTimeHyphenUtc,
  getImagePathFromCDN,
  ImageType,
} from "@hotelspoint/utils";
import {
  IconCalendar,
  IconCalendarX,
  IconTagFilled,
  IconUsersGroup,
} from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import TransferDetailsModal from "../../SearchTransfersResults/TransferDetailsModal";
import useTransferSearchParams from "../../useTransferSearchParams";
import thumbPlaceholder from "./assets/thumbPlaceholder.jpg";
import * as S from "./SearchTransferBookSidebar.styled";

interface SearchTransferBookSidebarParams extends Record<string, string> {
  id: string;
}

const SearchTransferBookSidebar = () => {
  const { t } = useTranslation();

  const { id } = useParams<SearchTransferBookSidebarParams>();
  const transferId = Number(id);

  const [query] = useTransferSearchParams();

  const [pickUpPlace, isLoadingPickUpPlace] = usePlace({
    id: query.pickUp?.placeId as number,
    type: query.pickUp?.placeType as PlaceType,
  });

  const [dropOffPlace, isLoadingDropOffPlace] = usePlace({
    id: query.dropOff?.placeId as number,
    type: query.dropOff?.placeType as PlaceType,
  });

  const isLoadingPlaces = isLoadingPickUpPlace || isLoadingDropOffPlace;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [search, isLoadingSearch] = useTransferAvailabilitySearch(
    query as TransferSearchPayload,
  );

  const selectedTransfer = search?.results?.find(
    transfer => transfer.id === Number(transferId),
  );

  return (
    <div>
      <TransferDetailsModal
        transfer={selectedTransfer}
        isOpen={isModalOpen}
        isLoading={false}
        onClose={() => setIsModalOpen(false)}
      />
      <S.Card>
        <S.TransferCardWrapper>
          <S.TransferCardDetails>
            <S.Title>
              {isLoadingSearch ? <Skeleton /> : selectedTransfer?.name}
            </S.Title>
            {isLoadingSearch ? <Skeleton /> : selectedTransfer?.vehicle}
            <S.ListItem>
              {isLoadingSearch ? (
                <Skeleton />
              ) : (
                <>
                  <IconUsersGroup size={16} />
                  <span>
                    {t(
                      "searchTransferBook.sidebar.transferDetails.maxPassengers",
                      {
                        count: selectedTransfer?.maxPassengers,
                      },
                    )}
                  </span>
                </>
              )}
            </S.ListItem>
            <div>
              {isLoadingSearch ? (
                <Skeleton />
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  {t("searchTransferBook.sidebar.transferDetails.label")}
                </Button>
              )}
            </div>
          </S.TransferCardDetails>
          <S.ThumbnailWrapper>
            <S.Thumbnail
              $src={
                selectedTransfer?.image
                  ? getImagePathFromCDN(
                      selectedTransfer?.image as string,
                      ImageType.Transfer,
                    )
                  : thumbPlaceholder
              }
            />
          </S.ThumbnailWrapper>
        </S.TransferCardWrapper>
      </S.Card>
      <S.HeadlineWrapper>
        <IconCalendar size={18} />
        <h6>{t("searchTransferBook.sidebar.outboundTransfer.label")}</h6>
      </S.HeadlineWrapper>{" "}
      {isLoadingPlaces ? (
        <Skeleton />
      ) : (
        <S.Card>
          <S.TransferWrapper>
            <S.TransferDirectionWrapper>
              <S.TransferDirection>
                {t("searchTransferBook.sidebar.outboundTransfer.from")}
              </S.TransferDirection>
              <S.TransferPlaceTitle>{pickUpPlace?.name}</S.TransferPlaceTitle>
            </S.TransferDirectionWrapper>
            <S.Separator />
            <S.TransferDirectionWrapper>
              <S.TransferDirection>
                {t("searchTransferBook.sidebar.outboundTransfer.to")}
              </S.TransferDirection>
              <S.TransferPlaceTitle>{dropOffPlace?.name}</S.TransferPlaceTitle>
            </S.TransferDirectionWrapper>
          </S.TransferWrapper>
          <S.TransferDateWrapper>
            <span>{t("searchTransferBook.sidebar.date")}</span>
            <S.TransferDateTime>
              {query.outboundDate
                ? formatDateTimeHyphenUtc(query.outboundDate as string)
                : undefined}
            </S.TransferDateTime>
          </S.TransferDateWrapper>
        </S.Card>
      )}
      <S.HeadlineWrapper>
        <IconCalendar size={18} />
        <h6>{t("searchTransferBook.sidebar.returnTransfer.label")}</h6>
      </S.HeadlineWrapper>
      {isLoadingPlaces ? (
        <Skeleton />
      ) : (
        <S.Card>
          <S.TransferWrapper>
            <S.TransferDirectionWrapper>
              <S.TransferDirection>
                {t("searchTransferBook.sidebar.returnTransfer.from")}
              </S.TransferDirection>
              <S.TransferPlaceTitle>{dropOffPlace?.name}</S.TransferPlaceTitle>
            </S.TransferDirectionWrapper>
            <S.Separator />
            <S.TransferDirectionWrapper>
              <S.TransferDirection>
                {t("searchTransferBook.sidebar.returnTransfer.to")}
              </S.TransferDirection>
              <S.TransferPlaceTitle>{pickUpPlace?.name}</S.TransferPlaceTitle>
            </S.TransferDirectionWrapper>
          </S.TransferWrapper>
          <S.TransferDateWrapper>
            <span>{t("searchTransferBook.sidebar.date")}</span>
            <S.TransferDateTime>
              {query.returnDate
                ? formatDateTimeHyphenUtc(query.returnDate as string)
                : undefined}
            </S.TransferDateTime>
          </S.TransferDateWrapper>
        </S.Card>
      )}
      <S.HeadlineWrapper>
        <IconCalendarX size={18} />
        <h6>{t("searchTransferBook.sidebar.cancellationPolicy.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <span>
          {t("searchTransferBook.sidebar.cancellationPolicy.cancellationTime", {
            hours: selectedTransfer?.cancellationTime,
          })}
        </span>
      </S.Card>
      <S.HeadlineWrapper>
        <IconTagFilled size={18} />
        <h6>{t("searchTransferBook.sidebar.priceBreakdown.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <S.Flex>
          <S.PriceTitle>
            <p>
              {t(
                "searchTransferBook.sidebar.priceBreakdown.outboundTransferPrice",
              )}
            </p>
            <p>
              {t(
                "searchTransferBook.sidebar.priceBreakdown.returnTransferPrice",
              )}
            </p>
          </S.PriceTitle>
          <S.PriceList>
            <S.PriceItem>
              <Price
                value={selectedTransfer?.outboundTransfer.priceNet as number}
              />
            </S.PriceItem>
            <S.PriceItem>
              <Price
                value={selectedTransfer?.returnTransfer.priceNet as number}
              />
            </S.PriceItem>
          </S.PriceList>
        </S.Flex>
        <S.Flex>
          <S.PriceTitle>
            {t("searchTransferBook.sidebar.totalPrice")}
          </S.PriceTitle>
          <MarkupPrice
            value={{
              total: selectedTransfer?.price as number,
              net: selectedTransfer?.priceNet as number,
            }}
          >
            <S.Price>
              <Price value={selectedTransfer?.price as number} />
            </S.Price>
          </MarkupPrice>
        </S.Flex>
      </S.Card>
    </div>
  );
};

export default SearchTransferBookSidebar;
