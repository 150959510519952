import {
  Box,
  Flex,
  FormAdapter,
  FormClearableInput,
  FormDatePickerSingle,
  FormTimePicker,
} from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

const ReturnTransferInfo = () => {
  const { t } = useTranslation();

  return (
    <Flex mx={[0, 0, -1, -1]}>
      <Box width={[1, 1, 6 / 14]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="returnFlightNumber"
          label={t("searchTransferBook.content.form.returnFlightNumber.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t(
                "searchTransferBook.content.form.returnFlightNumber.placeholder",
              )}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 4 / 14]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="returnDate"
          label={t("searchTransferBook.content.form.returnDate.label")}
        >
          {props => <FormDatePickerSingle {...props} readOnly />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 2 / 14]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="returnTime"
          label={t("searchTransferBook.content.form.returnTime.label")}
        >
          {props => <FormTimePicker {...props} readOnly />}
        </FormAdapter>
      </Box>
    </Flex>
  );
};

export default ReturnTransferInfo;
