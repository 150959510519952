import {
  Container,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
} from "@hotelspoint/components";
import { Navigate, useParams } from "react-router-dom";

import SearchHotelBookContent from "./SearchHotelBookContent";
import SearchHotelBookSidebar from "./SearchHotelBookSidebar";
import useSearchHotelBookParams from "./useSearchHotelBookParams";

interface SearchHotelBookParams extends Record<string, string> {
  id: string;
}

const SearchHotelBook = () => {
  const { id } = useParams<SearchHotelBookParams>();

  const hotelId = Number(id);
  const [{ searchId, rateId }] = useSearchHotelBookParams();

  if (!hotelId || !searchId || !rateId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <PageSplitLayout wide>
            <PageSplitLayout.Sidebar>
              <SearchHotelBookSidebar
                hotelId={hotelId}
                searchId={searchId as number}
                rateId={rateId as number}
              />
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <SearchHotelBookContent
                hotelId={hotelId}
                searchId={searchId as number}
                rateId={rateId as number}
              />
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchHotelBook;
