import { borderRadius, pxToRem, spacing, timing } from "@hotelspoint/theme";
import styled, { css } from "styled-components";
import { ifProp, theme, withProp } from "styled-tools";

const Adornment = styled.div<{
  $hasError: boolean;
  $adornmentStyle?: "none" | "filled";
}>`
  position: absolute;
  top: 1px;
  color: ${(ifProp("$hasError", theme("error.primary")), theme("body.text"))};
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 2px);
  background: ${ifProp(
    { $adornmentStyle: "filled" },
    theme("palette.concrete"),
  )};
  border-style: solid;
  border-color: ${ifProp(
    { $adornmentStyle: "filled" },
    theme("palette.silver"),
    "transparent",
  )};
  width: 36px;
  font-weight: ${ifProp({ $adornmentStyle: "filled" }, 600)};
  font-size: ${ifProp({ $adornmentStyle: "filled" }, pxToRem(14))};
`;

export const StartAdornment = styled(Adornment)`
  left: 1px;
  border-width: 0 1px 0 0;
`;

export const EndAdornment = styled(Adornment)`
  right: 1px;
  border-width: 0 0 0 1px;
`;

export const Wrapper = styled.div<{ $inputDisabled?: boolean }>`
  position: relative;
  line-height: 1;
`;

export const baseStyles = css`
  width: 100%;
  border-radius: ${borderRadius.normal}px;
  color: ${theme("body.text")};
  transition: border ${timing.normal}ms;
  border-width: 1px;
  border-style: solid;
  border-color: ${ifProp(
    "$hasError",
    theme("error.primary"),
    theme("palette.silver"),
  )};

  &::placeholder {
    font-style: italic;
    color: ${ifProp(
      "$hasError",
      theme("error.primary"),
      theme("palette.silverChalice"),
    )};
    font-size: ${pxToRem(15)};
  }

  &:focus:not(:read-only) {
    border-color: ${ifProp(
      "$hasError",
      theme("error.primary"),
      theme("palette.dodgerBlue"),
    )};
    /* @todo */
    box-shadow: ${ifProp(
      "$hasError",
      `0 0 0 1px #E74C3C`,
      `0 0 0 1px #2684FF`,
    )};
  }

  &:hover:not(:focus):not(:read-only):not(:disabled) {
    border-color: ${ifProp(
      "$hasError",
      theme("error.primary"),
      theme("palette.dustyGrey"),
    )};
  }

  &:read-only {
    color: ${theme("palette.silverChalice")};
    background: ${theme("palette.concrete")};

    &::placeholder {
      color: ${theme("palette.silverChalice")};
    }
  }

  &:disabled {
    user-select: none;
    color: ${theme("disabled.text")};
    background: ${theme("disabled.bg")};
    border-color: ${theme("palette.silverChalice")};

    &::placeholder {
      color: ${theme("disabled.text")};
    }
  }
`;

export const Input = styled.input<{
  $hasError: boolean;
  $hasStartAdornment: boolean;
  $hasEndAdornment: boolean;
}>`
  ${baseStyles};

  height: 40px;
  line-height: 40px;
  padding: ${withProp(
    ["$hasStartAdornment", "$hasEndAdornment"],
    (hasStartAdornment, hasEndAdornment) => {
      if (hasStartAdornment && hasEndAdornment) {
        return `0 ${spacing(4.5)}px`;
      }

      if (hasStartAdornment) {
        return `0 ${spacing(1.25)}px 0 ${spacing(4.5)}px`;
      }

      if (hasEndAdornment) {
        return `0 ${spacing(4.5)}px 0 ${spacing(1.25)}px`;
      }

      return `0 ${spacing(1.25)}px`;
    },
  )};
`;
