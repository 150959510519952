import {
  ActivitySearchPayload,
  useActivityAvailabilitySearch,
  useActivityDetails,
  useActivityOptions,
  usePlace,
} from "@hotelspoint/api";
import {
  ActivityContent,
  Breadcrumb,
  BreadcrumbContainer,
  CalendarSelector,
  Container,
  GalleryOverlay,
  MosaicGallery,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import { PlaceType } from "@hotelspoint/types";
import { ImageType, useDocumentTitle } from "@hotelspoint/utils";
import { IconMapPinFilled, IconMapStar } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import ActivityPickupLocation from "../components/ActivityPickupLocation";
import ActivityTicketsSection from "../components/ActivityTicketsSection";
import SearchActivitiesForm from "../SearchActivitiesResults/SearchActivitiesForm";
import useActivitySearchParams from "../useActivitySearchParams";
import * as S from "./SearchActivityPage.styled";

interface SearchActivityPageParams extends Record<string, string> {
  id: string;
}

const SearchActivityPage = () => {
  const { t } = useTranslation();

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

  useDocumentTitle(t("searchActivityPage.pageTitle"));

  const { id } = useParams<SearchActivityPageParams>();
  const activityId = Number(id);
  const [query] = useActivitySearchParams();

  // @todo: why are we using state here?
  const [selectedDate, setSelectedDate] = useState<string>(
    query.dateIn as string,
  );
  const [location, setLocation] = useState<string | undefined>(undefined);

  const [, , { refetch }] = useActivityAvailabilitySearch(
    query as ActivitySearchPayload,
  );

  const [activityDetails, isLoadingActivityDetails] = useActivityDetails(
    query.searchId as number,
    activityId,
  );

  const [activityOptions, isLoadingActivityOptions] = useActivityOptions({
    searchId: query.searchId as number,
    id: activityId,
    date: selectedDate,
    pickUpLocation: location,
    pickUpRequired: !!activityDetails?.activity.pickUpLocations,
  });

  const { activity } = activityDetails || {};

  const [place, isLoadingPlace] = usePlace({
    id: query.placeId as number,
    type: query.placeType as PlaceType,
  });

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchActivities.pageTitle"),
        href: "/search/activities",
        icon: () => <IconMapStar size={18} />,
      },
      ...(place?.parents
        ? place.parents.map(placeParent => ({
            title: placeParent.name as string,
            icon: () => (
              <PlaceTypeIcon
                type={placeParent.type as PlaceType}
                iconProps={{ size: 18 }}
              />
            ),
          }))
        : []),
      {
        title: place?.name as string,
        icon: () => (
          <PlaceTypeIcon
            type={place?.type as PlaceType}
            iconProps={{ size: 18 }}
          />
        ),
      },
      {
        title: activityDetails?.activity.name as string,
        icon: () => <IconMapStar size={18} />,
      },
    ],
    [t, place, activityDetails],
  );

  const shouldShowCalendar = useMemo(() => {
    return activity?.pickUpLocations && location;
  }, [activity?.pickUpLocations, location]);

  const handleMosaicClick = (index: number) => {
    setIsGalleryOpen(true);
    setInitialGalleryIndex(index);
  };

  return (
    <>
      <GalleryOverlay
        isOpen={isGalleryOpen}
        images={activity?.images ?? []}
        imageType={ImageType.Activity}
        initialSlideIndex={initialGalleryIndex}
        isLoading={isLoadingActivityDetails}
        onClose={() => setIsGalleryOpen(false)}
      />
      <PageWrapper>
        <Container>
          <PageSpacer>
            <BreadcrumbContainer>
              {isLoadingPlace ? (
                <Skeleton width={120} height={10} />
              ) : (
                <Breadcrumb items={breadcrumbs} />
              )}
            </BreadcrumbContainer>
            <PageSplitLayout>
              <PageSplitLayout.Sidebar>
                <S.Wrapper>
                  <SearchActivitiesForm refetch={refetch} />
                </S.Wrapper>
              </PageSplitLayout.Sidebar>
              <PageSplitLayout.Content>
                <S.Wrapper>
                  <MosaicGallery
                    images={activityDetails?.activity.images ?? []}
                    isLoading={isLoadingActivityDetails}
                    imageType={ImageType.Activity}
                    handleImageClick={handleMosaicClick}
                  />
                  <h3>
                    {isLoadingActivityDetails ? <Skeleton /> : activity?.name}
                  </h3>
                  <S.LocationWrapper>
                    {isLoadingActivityDetails ? (
                      <S.FlexGrow>
                        <Skeleton />
                      </S.FlexGrow>
                    ) : (
                      <>
                        <IconMapPinFilled size={16} />
                        <p>{activity?.location}</p>
                      </>
                    )}
                  </S.LocationWrapper>
                  <S.Divider />
                  <ActivityContent
                    isLoading={isLoadingActivityDetails}
                    content={activity?.content}
                  />
                  {activity?.pickUpLocations && (
                    <ActivityPickupLocation
                      locations={activity.pickUpLocations}
                      onChange={setLocation}
                    />
                  )}

                  {shouldShowCalendar && (
                    <>
                      <CalendarSelector
                        value={selectedDate}
                        onChange={setSelectedDate}
                        range={{
                          from: new Date(query.dateIn as string),
                          to: new Date(query.dateOut as string),
                        }}
                        calendar={
                          activityOptions?.calendar as Record<string, number>
                        }
                        isLoading={isLoadingActivityOptions}
                      />
                      <S.Divider />
                    </>
                  )}
                  {activityOptions && (
                    <ActivityTicketsSection
                      id={activityId}
                      searchId={query.searchId as number}
                      options={activityOptions}
                      selectedDate={selectedDate}
                    />
                  )}
                </S.Wrapper>
              </PageSplitLayout.Content>
            </PageSplitLayout>
          </PageSpacer>
        </Container>
      </PageWrapper>
    </>
  );
};

export default SearchActivityPage;
