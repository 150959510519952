import { handleError, useSendReservationVoucher } from "@hotelspoint/api";
import {
  Button,
  Dialog,
  Form,
  FormAdapter,
  FormClearableInput,
  FormContext,
  FormLayout,
  Modal,
  ModalSize,
} from "@hotelspoint/components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  FormValues,
  validationSchema,
} from "./ProfileReservationSendVoucher.util";

interface ProfileReservationSendVoucherProps {
  id: number;
}

const ProfileReservationSendVoucher = ({
  id,
}: ProfileReservationSendVoucherProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [sendReservationVoucher, isLoading] = useSendReservationVoucher(id);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = {
          email: formValues.email,
        };

        await sendReservationVoucher(payload);
        setIsOpen(false);
        toast.success(t("toast.profileReservation.sendVoucher"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, sendReservationVoucher],
  );

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <Button small variant="outlined">
          {t("profileReservation.actions.vouchers.send")}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Form defaultValues={defaultValues} validationSchema={validationSchema}>
          <Modal
            title={t("profileReservation.sendVoucherDialog.title")}
            size={ModalSize.Small}
            onClose={() => setIsOpen(false)}
          >
            <FormLayout>
              <FormAdapter
                name="email"
                label={t(
                  "profileReservation.sendVoucherDialog.form.email.label",
                )}
              >
                {props => (
                  <FormClearableInput
                    {...props}
                    placeholder={t(
                      "profileReservation.sendVoucherDialog.form.email.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </FormLayout>
            <Modal.Actions>
              <Button variant="outlined" onClick={handleCancel}>
                {t("profileReservation.sendVoucherDialog.cancel")}
              </Button>
              <FormContext<FormValues>
                render={({ handleSubmit }) => (
                  <Button
                    isLoading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("profileReservation.sendVoucherDialog.confirm")}
                  </Button>
                )}
              />
            </Modal.Actions>
          </Modal>
        </Form>
      </Dialog.Content>
    </Dialog>
  );
};

export default ProfileReservationSendVoucher;
