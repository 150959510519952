import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

import Paper from "../../elements/Paper";

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(3)}px;
  padding: ${spacing(2)}px;
`;

export const Title = styled.h5`
  margin-bottom: ${spacing(2)}px;
  font-weight: 700;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${spacing(2)}px;
  row-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(3)}px;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
  font-size: ${pxToRem(14)};
  line-height: 1;
`;
