import { usePlaces } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormChildrenMethods,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormPersonSelector,
  FormTimePicker,
  OptionGroup,
  PlaceTypeIcon,
  Tab,
  Tabs,
} from "@hotelspoint/components";
import { useRecentTransferSearchesStore } from "@hotelspoint/store";
import { PlaceType, TransferTripType } from "@hotelspoint/types";
import {
  getPlaceTypeName,
  getTransferTripTypeName,
  isDateValid,
} from "@hotelspoint/utils";
import flatten from "lodash/flatten";
import { useCallback, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import useTransferSearchParams from "../useTransferSearchParams";
import {
  PERSON_SELECTOR_CONFIG,
  PLACE_TYPE_ORDER,
  PLACE_TYPES,
} from "./constants";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchTransfersForm.form";
import * as S from "./SearchTransfersForm.styled";

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

const SearchTransfersFormInner = ({
  setValue,
}: FormChildrenMethods<FormValues>) => {
  const { t } = useTranslation();
  const [, setQuery] = useTransferSearchParams();

  const dateToday = useMemo(() => new Date(), []);
  const currentYear = useMemo(() => dateToday.getFullYear(), [dateToday]);

  const [tripType, pickUp, dropOff, outboundDate, returnDate] = useWatch({
    name: ["tripType", "pickUp", "dropOff", "outboundDate", "returnDate"],
  });

  const [pickUpPlace, dropOffPlace] = useWatch({
    name: ["pickUpPlace", "dropOffPlace"],
  });

  const [debouncedSearchPickUp] = useDebounce(pickUp, 350);

  const [debouncedSearchDropOff] = useDebounce(dropOff, 350);

  const [pickUpPlaces, isLoadingPickUpPlaces] = usePlaces({
    types: PLACE_TYPES,
    search: debouncedSearchPickUp ?? "",
  });

  const [dropOffPlaces, isLoadingDropOffPlaces] = usePlaces({
    types: PLACE_TYPES,
    search: debouncedSearchDropOff ?? "",
  });

  const recentSearchesStore = useRecentTransferSearchesStore(state => state);

  const pickUpPlaceOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!pickUpPlaces || isLoadingPickUpPlaces) return [];
    const groups = Object.entries(pickUpPlaces)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);

    return groups.sort((a, b) => {
      return (
        PLACE_TYPE_ORDER.indexOf(a.value) - PLACE_TYPE_ORDER.indexOf(b.value)
      );
    });
  }, [t, pickUpPlaces, isLoadingPickUpPlaces]);

  const dropOffPlaceOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!dropOffPlaces || isLoadingDropOffPlaces) return [];
    const groups = Object.entries(dropOffPlaces)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);

    return groups.sort((a, b) => {
      return (
        PLACE_TYPE_ORDER.indexOf(a.value) - PLACE_TYPE_ORDER.indexOf(b.value)
      );
    });
  }, [t, dropOffPlaces, isLoadingDropOffPlaces]);

  const pickUpPlaceOptionsFlat = useMemo(() => {
    return flatten(pickUpPlaceOptions.map(group => group.options));
  }, [pickUpPlaceOptions]);

  const dropOffPlaceOptionsFlat = useMemo(() => {
    return flatten(dropOffPlaceOptions.map(group => group.options));
  }, [dropOffPlaceOptions]);

  useEffect(() => {
    if (isDateValid(outboundDate) && isDateValid(returnDate)) {
      if (new Date(outboundDate) > new Date(returnDate)) {
        setValue("returnDate", undefined);
      }
    }
  }, [outboundDate, returnDate, setValue]);

  const handleSelectPickUp = useCallback(
    (option: any) => {
      if (option) {
        setValue("pickUpPlace", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("pickUpPlace", {});
      }
    },
    [setValue],
  );

  const handleSelectDropOff = useCallback(
    (option: any) => {
      if (option) {
        setValue("dropOffPlace", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("dropOffPlace", {});
      }
    },
    [setValue],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const payload = form2Entity(formValues);

      const newQuery = {
        ...payload,
        // Make sure we make fresh search every time
        useCache: false,
      };

      // Find the place option and set the name inside the meta
      const pickUpPlaceOption = pickUpPlaceOptionsFlat.find(
        p => p?.value === payload.pickUp.placeId,
      );

      const dropOffPlaceOption = dropOffPlaceOptionsFlat.find(
        p => p?.value === payload.dropOff.placeId,
      );

      // Populate the recent searches store
      if (pickUpPlaceOption && dropOffPlaceOption) {
        recentSearchesStore.add({
          ...newQuery,
          meta: {
            pickUpPlaceName: pickUpPlaceOption?.label as string,
            dropOffPlaceName: dropOffPlaceOption?.label as string,
          },
        });
      }

      setQuery(payload);
    },
    [
      dropOffPlaceOptionsFlat,
      pickUpPlaceOptionsFlat,
      recentSearchesStore,
      setQuery,
    ],
  );

  return (
    <S.Wrapper>
      <FormAdapter name="tripType" label={null}>
        {props => (
          <Tabs value={props.value} onChange={props.onChange}>
            <Tab value={TransferTripType.RoundTrip}>
              {t(getTransferTripTypeName(TransferTripType.RoundTrip))}
            </Tab>
            <Tab value={TransferTripType.OneWay}>
              {t(getTransferTripTypeName(TransferTripType.OneWay))}
            </Tab>
          </Tabs>
        )}
      </FormAdapter>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="pickUp"
              label={t("searchTransfers.pickUp.label")}
            >
              {props => (
                <FormAutoComplete
                  {...props}
                  placeholder={t("searchTransfers.pickUp.placeholder")}
                  options={pickUpPlaceOptions}
                  isLoading={isLoadingPickUpPlaces}
                  renderOption={option => (
                    <S.PlaceOption key={option.value}>
                      <PlaceTypeIcon
                        type={option.meta.type}
                        iconProps={{ size: 22 }}
                      />
                      <span>{option.label}</span>
                    </S.PlaceOption>
                  )}
                  onSelect={handleSelectPickUp}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dropOff"
              label={t("searchTransfers.dropOff.label")}
            >
              {props => (
                <FormAutoComplete
                  {...props}
                  placeholder={t("searchTransfers.dropOff.placeholder")}
                  options={dropOffPlaceOptions}
                  isLoading={isLoadingDropOffPlaces}
                  renderOption={option => (
                    <S.PlaceOption key={option.value}>
                      <PlaceTypeIcon
                        type={option.meta.type}
                        iconProps={{ size: 22 }}
                      />
                      <span>{option.label}</span>
                    </S.PlaceOption>
                  )}
                  onSelect={handleSelectDropOff}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="outboundDate"
              label={
                pickUpPlace?.type === PlaceType.Hotel &&
                dropOffPlace?.type === PlaceType.Airport
                  ? t("searchTransfers.outboundDate.label.hotelToAirport")
                  : pickUpPlace?.type === PlaceType.Airport ||
                      dropOffPlace?.type === PlaceType.Airport
                    ? t("searchTransfers.outboundDate.label.airport")
                    : t("searchTransfers.outboundDate.label.default")
              }
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t("searchTransfers.outboundDate.placeholder")}
                  dayPickerProps={{
                    defaultMonth: isDateValid(outboundDate)
                      ? new Date(
                          outboundDate.getFullYear(),
                          outboundDate.getMonth(),
                        )
                      : new Date(dateToday.getFullYear(), dateToday.getMonth()),
                    disabled: [{ before: dateToday }],
                    fromYear: currentYear,
                  }}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="outboundTime"
              label={
                pickUpPlace?.type === PlaceType.Hotel &&
                dropOffPlace?.type === PlaceType.Airport
                  ? t("searchTransfers.outboundTime.label.hotelToAirport")
                  : pickUpPlace?.type === PlaceType.Airport ||
                      dropOffPlace?.type === PlaceType.Airport
                    ? t("searchTransfers.outboundTime.label.airport")
                    : t("searchTransfers.outboundTime.label.default")
              }
            >
              {props => (
                <FormTimePicker
                  {...props}
                  placeholder={t("searchTransfers.outboundTime.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          {tripType === TransferTripType.RoundTrip && (
            <>
              <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
                <FormAdapter
                  name="returnDate"
                  label={
                    pickUpPlace?.type === PlaceType.Hotel &&
                    dropOffPlace?.type === PlaceType.Airport
                      ? t("searchTransfers.returnDate.label.hotelToAirport")
                      : pickUpPlace?.type === PlaceType.Airport ||
                          dropOffPlace?.type === PlaceType.Airport
                        ? t("searchTransfers.returnDate.label.airport")
                        : t("searchTransfers.returnDate.label.default")
                  }
                >
                  {props => (
                    <FormDatePickerSingle
                      {...props}
                      placeholder={t("searchTransfers.returnDate.placeholder")}
                      dayPickerProps={{
                        defaultMonth: isDateValid(returnDate)
                          ? new Date(
                              returnDate.getFullYear(),
                              returnDate.getMonth(),
                            )
                          : isDateValid(outboundDate)
                            ? new Date(
                                outboundDate.getFullYear(),
                                outboundDate.getMonth(),
                              )
                            : new Date(
                                dateToday.getFullYear(),
                                dateToday.getMonth(),
                              ),
                        disabled: [{ before: outboundDate || dateToday }],
                        fromYear: currentYear,
                      }}
                      readOnly={!isDateValid(outboundDate)}
                    />
                  )}
                </FormAdapter>
              </Box>
              <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
                <FormAdapter
                  name="returnTime"
                  label={
                    pickUpPlace?.type === PlaceType.Hotel &&
                    dropOffPlace?.type === PlaceType.Airport
                      ? t("searchTransfers.returnTime.label.hotelToAirport")
                      : pickUpPlace?.type === PlaceType.Airport ||
                          dropOffPlace?.type === PlaceType.Airport
                        ? t("searchTransfers.returnTime.label.airport")
                        : t("searchTransfers.returnTime.label.default")
                  }
                >
                  {props => (
                    <FormTimePicker
                      {...props}
                      placeholder={t("searchTransfers.returnTime.placeholder")}
                    />
                  )}
                </FormAdapter>
              </Box>
            </>
          )}
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="travellers"
              label={t("searchTransfers.travellers.label")}
            >
              {props => (
                <FormPersonSelector
                  {...props}
                  placeholder={t("searchTransfers.travellers.placeholder")}
                  config={PERSON_SELECTOR_CONFIG}
                />
              )}
            </FormAdapter>
          </Box>
          <Box
            width={[1, 1, 1 / 2, 1 / 2]}
            px={[0, 0, 1, 1]}
            py={1}
            as={S.ActionWrapper}
            display="flex"
            alignItems="flex-end"
          >
            <FormContext<FormValues>
              render={({ handleSubmit }) => (
                <Button
                  type="submit"
                  variant="secondary"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("searchTransfers.submit")}
                </Button>
              )}
            />
          </Box>
        </Flex>
      </FormLayout>
    </S.Wrapper>
  );
};

interface SearchTransfersFormProps {
  initialValues?: Partial<FormValues>;
}

const SearchTransfersForm = ({
  initialValues = {},
}: SearchTransfersFormProps) => {
  // Load initial values from the outside - outdated recent search queries without dates
  const formValues = useMemo(
    () => ({
      ...defaultValues,
      ...initialValues,
    }),
    [initialValues],
  );

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => <SearchTransfersFormInner {...formMethods} />}
    </Form>
  );
};

export default SearchTransfersForm;
