import {
  handleError,
  useAdminCommission,
  useUpdateAdminCommissions,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSelect,
  LoaderBlock,
} from "@hotelspoint/components";
import { COMMISSION_STATUS_OPTIONS } from "@hotelspoint/constants";
import { getCommissionStatusColor } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminCommissionForm.util";

interface AdminCommissionFormProps {
  id: number;
}

const AdminCommissionForm = ({ id }: AdminCommissionFormProps) => {
  const { t } = useTranslation();

  const [commission, isLoadingCommission] = useAdminCommission(id);
  const [updateCommission, isUpdatingCommission] =
    useUpdateAdminCommissions(id);

  const formValues = useMemo(() => {
    if (!commission) return defaultValues;

    return entity2Form(commission);
  }, [commission]);

  const statusOptions = useMemo(
    () =>
      COMMISSION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getCommissionStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await updateCommission(payload);

        toast.success(t("toast.adminCommission.update"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, updateCommission],
  );

  if (isLoadingCommission) {
    return <LoaderBlock />;
  }

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="userName"
              label={t("adminCommission.form.userName.label")}
            >
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="amount"
              label={t("adminCommission.form.amount.label")}
            >
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateCreated"
              label={t("adminCommission.form.dateCreated.label")}
            >
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminCommission.form.status.label")}
            >
              {props => <FormSelect {...props} options={statusOptions} />}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isUpdatingCommission}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminCommission.form.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminCommissionForm;
