import { handleError, useAdminMeals, useMapMealToMain } from "@hotelspoint/api";
import {
  Button,
  Dialog,
  Form,
  FormAdapter,
  FormContext,
  FormLayout,
  FormSelect,
  Modal,
  ModalSize,
} from "@hotelspoint/components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminMealConfigurationMapModal.util";

interface AdminMealConfigurationMapModalProps {
  id: number | null;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  handleSuccess: () => void;
}

const AdminMealConfigurationMapModal = ({
  id,
  isOpen,
  setIsOpen,
  handleSuccess,
}: AdminMealConfigurationMapModalProps) => {
  const { t } = useTranslation();

  const [mainMeals, isLoadingMainMeals] = useAdminMeals({
    isMain: true,
  });

  const [mapMeal, isMappingMeal] = useMapMealToMain(id as number);

  const mainMealOptions = useMemo(() => {
    if (!mainMeals) return [];

    return mainMeals.results.map(meal => ({
      label: meal.name,
      value: meal.id,
    }));
  }, [mainMeals]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await mapMeal(payload);
        setIsOpen(false);

        handleSuccess();

        toast.success(t("toast.adminMealConfiguration.update"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [mapMeal, setIsOpen, handleSuccess, t],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Form defaultValues={{}} validationSchema={validationSchema}>
          <Modal
            title={t("adminMealConfiguration.columns.actions.map.modal.title")}
            size={ModalSize.Small}
            onClose={() => setIsOpen(false)}
          >
            <FormLayout>
              <p>
                {t(
                  "adminMealConfiguration.columns.actions.map.modal.description",
                )}
              </p>
              <FormAdapter
                name="mainId"
                label={t(
                  "adminMealConfiguration.columns.actions.map.modal.mainId.label",
                )}
              >
                {props => (
                  <FormSelect
                    {...props}
                    options={mainMealOptions}
                    isLoading={isLoadingMainMeals}
                  />
                )}
              </FormAdapter>
            </FormLayout>
            <Modal.Actions>
              <Button variant="outlined" onClick={() => setIsOpen(false)}>
                {t("components.confirmDialog.cancel")}
              </Button>
              <FormContext<FormValues>
                render={({ handleSubmit }) => (
                  <Button
                    isLoading={isMappingMeal}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("components.confirmDialog.confirm")}
                  </Button>
                )}
              />
            </Modal.Actions>
          </Modal>
        </Form>
      </Dialog.Content>
    </Dialog>
  );
};

export default AdminMealConfigurationMapModal;
