import { useHotelDetails } from "@hotelspoint/api";
import {
  AccordionSummary,
  AccordionTriggerBase,
  Box,
  Checklist,
  Flex,
} from "@hotelspoint/components";
import { HotelAmenity } from "@hotelspoint/types";
import { IconBed, IconBuildingCommunity } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import * as S from "./HotelAmenities.styled";

const AMENITIES_LIMIT = 4;

interface HotelAmenitiesProps {
  id: number;
}

const mapAmenitiesToChecklist = (amenities: HotelAmenity[]) => {
  return amenities.map(amenity => ({
    id: amenity.id,
    title: amenity.name,
    available: true,
  }));
};

const HotelAmenities = ({ id }: HotelAmenitiesProps) => {
  const { t } = useTranslation();

  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(id);

  const hotelAmenitiesMap = useMemo(
    () => mapAmenitiesToChecklist(hotelDetails?.amenities.hotel ?? []),
    [hotelDetails],
  );

  const roomAmenitiesMap = useMemo(
    () => mapAmenitiesToChecklist(hotelDetails?.amenities.room ?? []),
    [hotelDetails],
  );
  return (
    <AccordionSummary
      head={({ isOpen }) => {
        if (isLoadingHotelDetails) return null;

        return (
          <AccordionTriggerBase
            isOpen={isOpen}
            showText={t("searchHotelResults.showTheAmenities")}
            hideText={t("searchHotelResults.hideTheAmenities")}
          />
        );
      }}
      summary={
        <Flex mx={[0, 0, 0, -2]}>
          <Box
            width={[1, 1 / 2, 1 / 2, 1 / 2]}
            px={[0, 0, 0, 2]}
            py={[1, 0, 0, 0]}
          >
            <S.FlexWrapper>
              <IconBuildingCommunity size={18} />
              <S.Headline>
                {t("components.hotelAmenities.hotelAmenities")}
              </S.Headline>
            </S.FlexWrapper>
            {isLoadingHotelDetails ? (
              <Skeleton count={4} height={16} style={{ marginBottom: 4 }} />
            ) : (
              <Checklist
                items={
                  hotelAmenitiesMap.length > AMENITIES_LIMIT
                    ? hotelAmenitiesMap.slice(0, AMENITIES_LIMIT)
                    : hotelAmenitiesMap
                }
              />
            )}
          </Box>
          <Box
            width={[1, 1 / 2, 1 / 2, 1 / 2]}
            px={[0, 0, 0, 0]}
            py={[1, 0, 0, 0]}
          >
            <S.FlexWrapper>
              <IconBed size={18} />
              <S.Headline>
                {t("components.hotelAmenities.roomAmenities")}
              </S.Headline>
            </S.FlexWrapper>
            {isLoadingHotelDetails ? (
              <Skeleton count={4} height={16} style={{ marginBottom: 4 }} />
            ) : (
              <Checklist
                items={
                  roomAmenitiesMap.length > AMENITIES_LIMIT
                    ? roomAmenitiesMap.slice(0, AMENITIES_LIMIT)
                    : roomAmenitiesMap
                }
              />
            )}
          </Box>
        </Flex>
      }
    >
      <Flex>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} py={[0, 0, 0, 1]}>
          <Checklist items={hotelAmenitiesMap.slice(AMENITIES_LIMIT)} />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} py={[0, 0, 0, 1]}>
          <Checklist items={roomAmenitiesMap.slice(AMENITIES_LIMIT)} />
        </Box>
      </Flex>
    </AccordionSummary>
  );
};

export default HotelAmenities;
