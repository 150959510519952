import {
  handleError,
  PaymentCreatePayload,
  PaymentStatus,
  PaymentType,
  ReservationStatus,
  useCreatePayment,
  useReservation,
} from "@hotelspoint/api";
import {
  Button,
  Chip,
  Dialog,
  LinkExternal,
  Modal,
  PaymentOptions,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import {
  createForm,
  formatDate,
  getApiUrl,
  getPaymentStatusColor,
  getPaymentStatusName,
  getReservationStatusColor,
  getReservationStatusName,
} from "@hotelspoint/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import ProfileReservationSendVoucher from "../ProfileReservationSendVoucher";
import * as S from "./ReservationSummary.styled";

interface ProfileReservationSummaryProps {
  id: number;
}

const ProfileReservationSummary = ({ id }: ProfileReservationSummaryProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const token = useUserTokenStore(state => state.token);

  const [reservation, isLoading] = useReservation(id);

  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);

  const [createPayment, isCreatingPayment] = useCreatePayment();

  const handlePayment = async (
    values: Omit<PaymentCreatePayload, "reservationId">,
  ) => {
    try {
      const payload = {
        ...values,
        reservationId: id,
      };

      const response = await createPayment(payload);

      // Create a form with the payment URL and params, and submit it
      if (response.paymentType === PaymentType.CreditCard) {
        const form = createForm(response.paymentUrl, response.paymentUrlParams);

        document.body.appendChild(form);

        form.submit();
      }

      setIsPaymentDialogOpen(false);
    } catch (err: any) {
      handleError({ t, error: err });
    } finally {
      // Invalidate the reservation query on payment creation
      queryClient.invalidateQueries({
        queryKey: ["reservations", id],
      });
    }
  };

  return (
    <>
      <Dialog open={isPaymentDialogOpen} onOpenChange={setIsPaymentDialogOpen}>
        <Dialog.Content>
          <Modal
            title={t("profileReservation.actions.makePayment.modal.title")}
            onClose={() => setIsPaymentDialogOpen(false)}
          >
            <PaymentOptions
              amount={reservation?.itemData?.priceNet as number}
              onConfirm={handlePayment}
              onCancel={() => setIsPaymentDialogOpen(false)}
              isLoading={isCreatingPayment}
            />
          </Modal>
        </Dialog.Content>
      </Dialog>
      <S.VouchersWrapper>
        <div>
          <S.Title>{t("profileReservation.metricCards.bookingNumber")}</S.Title>
          <h5>{isLoading ? <Skeleton /> : id}</h5>
        </div>
        <S.VerticalDivider />
        <div>
          <S.Title>{t("profileReservation.metricCards.vouchers")}</S.Title>
          <S.VoucherActions>
            <LinkExternal
              to={getApiUrl(`reservations/${id}/vouchers/preview`, {
                token,
              })}
            >
              <Button small variant="outlined" isLoading={isLoading}>
                {t("profileReservation.actions.vouchers.download")}
              </Button>
            </LinkExternal>
            <ProfileReservationSendVoucher id={id} />
          </S.VoucherActions>
        </div>
      </S.VouchersWrapper>
      <S.Divider />
      <S.InformationWrapper>
        <S.InformationBlockWrapper>
          <S.InformationBlock>
            <S.InformationBlockRow>
              <p>{t("profileReservation.bookingStatus")}</p>
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <Chip
                  $color={getReservationStatusColor(
                    reservation?.status as ReservationStatus,
                  )}
                >
                  {t(
                    getReservationStatusName(
                      reservation?.status as ReservationStatus,
                    ),
                  )}
                </Chip>
              )}
            </S.InformationBlockRow>
            <S.InformationBlockRow>
              <p>{t("profileReservation.deadline")}</p>
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <p>{formatDate(reservation?.dateDeadline as string)}</p>
              )}
            </S.InformationBlockRow>
          </S.InformationBlock>
          <S.InformationBlock>
            <S.InformationBlockRow>
              <p>{t("profileReservation.paymentStatus")}</p>
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <Chip
                  $color={getPaymentStatusColor(
                    reservation?.paymentStatus as PaymentStatus,
                  )}
                >
                  {t(
                    getPaymentStatusName(
                      reservation?.paymentStatus as PaymentStatus,
                    ),
                  )}
                </Chip>
              )}
            </S.InformationBlockRow>
            <S.InformationBlockRow>
              <p>{t("profileReservation.paymentDeadline")}</p>
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <p>{formatDate(reservation?.dateDeadline as string)}</p>
              )}
              {/* when Dido is ready this should be change with payment deadline */}
            </S.InformationBlockRow>
          </S.InformationBlock>
        </S.InformationBlockWrapper>
        <Button
          variant="success"
          onClick={() => setIsPaymentDialogOpen(true)}
          isLoading={isLoading}
          isDisabled={reservation?.paymentStatus === PaymentStatus.Confirmed}
        >
          {t("profileReservation.actions.makePayment.button")}
        </Button>
      </S.InformationWrapper>
      <S.Divider />
    </>
  );
};

export default ProfileReservationSummary;
