import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const ModalWrapper = styled.div`
  max-width: 800px;
  width: 100vw;
`;

export const BaseInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: ${spacing(2)}px;
`;

export const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
`;

export const ActivityPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: ${pxToRem(18)};
`;

export const Emphasized = styled.em`
  margin-right: ${spacing(0.5)}px;
  font-size: ${pxToRem(16)};
`;

export const Divider = styled.hr`
  margin: ${spacing(2)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const FlexGrow = styled.div`
  flex: 1;
`;
