import {
  Box,
  Flex,
  FormAdapter,
  FormClearableInput,
} from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

const TransferContactsInfo = () => {
  const { t } = useTranslation();

  return (
    <Flex mx={[0, 0, -1, -1]}>
      <Box width={[1, 1, 2 / 14]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="phoneCode"
          label={t("searchTransferBook.content.form.phoneCode.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t(
                "searchTransferBook.content.form.phoneCode.placeholder",
              )}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 6 / 14]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="phoneNumber"
          label={t("searchTransferBook.content.form.phoneNumber.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t(
                "searchTransferBook.content.form.phoneNumber.placeholder",
              )}
            />
          )}
        </FormAdapter>
      </Box>
    </Flex>
  );
};

export default TransferContactsInfo;
