import {
  Button,
  HotelBaseInfo,
  HotelDetailsGalleryModal,
} from "@hotelspoint/components";
import {
  SearchHotelsResultsModalState,
  SearchHotelsResultsModalType,
} from "@hotelspoint/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./ReservationHotelInfo.styled";

interface ReservationHotelInfoProps {
  id: number;
}

const ReservationHotelInfo = ({ id }: ReservationHotelInfoProps) => {
  const { t } = useTranslation();

  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);
  const [activeModal, setActiveModal] = useState<
    SearchHotelsResultsModalState | undefined
  >(undefined);

  return (
    <>
      <HotelDetailsGalleryModal
        id={id}
        modalType={activeModal?.type as SearchHotelsResultsModalType}
        setActiveModal={setActiveModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
      />
      <S.Card>
        <HotelBaseInfo id={id} />
        <Button
          variant="outlined"
          onClick={() =>
            setActiveModal({
              id,
              type: SearchHotelsResultsModalType.Details,
            })
          }
        >
          {t("profileReservation.hotelDetails")}
        </Button>
      </S.Card>
    </>
  );
};

export default ReservationHotelInfo;
