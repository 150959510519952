import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: ${spacing(2)}px;
  padding: ${spacing(4)}px 0;
  text-align: center;
`;

export const Title = styled.h4`
  font-weight: 600;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: ${spacing(0.5)}px;
`;
