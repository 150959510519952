import { ActivityOption, ActivityOptionPrice } from "@hotelspoint/api";

interface Prices {
  totalPriceNet: number;
  totalPrice: number;
}

export const calculateTotalPrice = (
  option?: ActivityOption,
  tickets?: string | null,
) => {
  if (!option || !tickets) return { totalPriceNet: 0, totalPrice: 0 };

  const ticketEntries = tickets.split(",");

  return ticketEntries.reduce(
    (totals: Prices, ticket: string) => {
      const [key, numTickets] = ticket.split(":").map(Number);

      const matchingOption = option.prices.find(
        (option: { key: number }) => option.key === key,
      );

      if (matchingOption) {
        totals.totalPriceNet += matchingOption.priceNet * numTickets;
        totals.totalPrice += matchingOption.price * numTickets;
      }

      return totals;
    },
    { totalPriceNet: 0, totalPrice: 0 },
  );
};

export const selectedPrices = (
  prices?: ActivityOptionPrice[],
  tickets?: string | null,
) => {
  if (!prices || !tickets) return [];

  return tickets
    .split(",")
    .map(ticket => {
      const [key, numTickets] = ticket.split(":").map(Number);

      const matchingOption = prices.find(price => price.key === key);

      if (matchingOption && !("numTickets" in matchingOption)) {
        (matchingOption as ActivityOptionPrice).numTickets = numTickets;
      }

      return matchingOption;
    })
    .filter(Boolean)
    .sort((a, b) => {
      const standaloneA = (a as ActivityOptionPrice).standalone ? 1 : 0;
      const standaloneB = (b as ActivityOptionPrice).standalone ? 1 : 0;
      if (standaloneB !== standaloneA) {
        return standaloneB - standaloneA;
      }

      return (
        (b as ActivityOptionPrice).price - (a as ActivityOptionPrice).price
      );
    });
};
