import { type FormControlInjectedProps } from "../FormControl";
import * as S from "./FormCheckbox.styled";

export interface FormCheckboxProps
  extends FormControlInjectedProps,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "disabled" | "checked"> {}

const FormCheckbox = ({ value, ...inputProps }: FormCheckboxProps) => {
  return (
    <S.Checkbox type="checkbox" checked={value} value={value} {...inputProps} />
  );
};

export default FormCheckbox;
