import { HotelRoomRate } from "@hotelspoint/types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import Price, { add, priceToDecimal } from "../../misc/Price";
import MarkupPrice from "../MarkupPrice";
import * as S from "./HotelBookPriceBreakdown.styled";

interface HotelBookPriceBreakdownProps {
  rooms: HotelRoomRate[];
  isLoading: boolean;
}

const HotelBookPriceBreakdown = ({
  rooms,
  isLoading = false,
}: HotelBookPriceBreakdownProps) => {
  const { t } = useTranslation();

  const prices = rooms?.map(room => room.price) as number[];
  const netPrices = rooms?.map(room => room.priceNet) as number[];

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        rooms.map(room => (
          <S.Flex key={room.id}>
            <p>
              {room.numRooms}x {room.roomName}
            </p>
            <MarkupPrice value={{ total: room.price, net: room.priceNet }}>
              <S.Price>
                <Price value={room.price} />
              </S.Price>
            </MarkupPrice>
          </S.Flex>
        ))
      )}
      {isLoading ? (
        <Skeleton />
      ) : (
        <S.Flex>
          <p>{t("components.hotelBookPriceBreakdown.totalPrice")}</p>
          <MarkupPrice
            value={{
              total: priceToDecimal(add(...prices)),
              net: priceToDecimal(add(...netPrices)),
            }}
          >
            <S.PriceTotal>
              <Price value={add(...prices)} />
            </S.PriceTotal>
          </MarkupPrice>
        </S.Flex>
      )}
    </>
  );
};

export default HotelBookPriceBreakdown;
