import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  dateFrom: date(),
  dateTo: date(),
  status: number(),
  supplierId: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  dateFrom: undefined,
  dateTo: undefined,
  status: undefined,
  supplierId: "",
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    status: query.status,
    supplierId: query.supplierId,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    status: formValues.status,
    supplierId: isNumeric(formValues.supplierId)
      ? Number(formValues.supplierId)
      : undefined,
  };
};
