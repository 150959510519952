import { pxToRem } from "@hotelspoint/theme";
import { IconStar, IconStarFilled } from "@tabler/icons-react";
import { Link as LinkComponent } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.3);
`;

export const Link = styled(LinkComponent)`
  color: ${theme("secondary.bg")};

  &:hover {
    color: ${theme("secondary.hover")};
  }
`;

export const Count = styled.sup`
  top: -1em;
  font-size: ${pxToRem(12)};
  font-weight: 700;
`;

export const IconActive = styled(IconStarFilled)`
  min-width: 26px;
`;

export const IconInactive = styled(IconStar)`
  min-width: 26px;
`;
