import { InferType, object, string } from "yup";

export const validationSchema = object({
  name: string().required("profileOffer.form.name.validation.required"),
  client: string().required("profileOffer.form.client.validation.required"),
  prices: object({}).required(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

const defaultValues: FormValues = {
  name: "",
  client: "",
  prices: {},
};

export const entity2Form = (entity: any): FormValues => {
  return {
    ...defaultValues,
    prices: entity?.prices,
  };
};
