import { RateType } from "@hotelspoint/types";
import { formatDate } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { StatusFlavour } from "../../common";
import StatusText from "../../elements/StatusText";
import * as S from "./CancelationPolicy.styled";

interface CancelationPolicyProps {
  rateType: number;
  deadline: string;
  footer?: React.ReactNode;
  isLoading?: boolean;
}

const CancelationPolicy = ({
  rateType,
  deadline,
  footer,
  isLoading = false,
}: CancelationPolicyProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      {isLoading ? (
        <S.FlexGrow>
          <Skeleton count={2} />
        </S.FlexGrow>
      ) : (
        <>
          {rateType === RateType.NonRefundable ? (
            <StatusText status={StatusFlavour.Error}>
              {t("components.cancelationPolicy.nonRefundable")}
            </StatusText>
          ) : (
            <>
              <StatusText status={StatusFlavour.Success}>
                {t("components.cancelationPolicy.freeCancelationUntil")}
              </StatusText>
              <S.Strong>{formatDate(deadline)}</S.Strong>
            </>
          )}
          {footer}
        </>
      )}
    </S.Wrapper>
  );
};

export default CancelationPolicy;
