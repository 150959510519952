import * as S from "./FormChapter.styled";

interface FormChapterProps {
  title: string;
  children: React.ReactNode;
}

const FormChapter = ({ title, children }: FormChapterProps) => {
  return (
    <div>
      <h6>{title}</h6>
      <S.InnerWrapper>{children}</S.InnerWrapper>
    </div>
  );
};

export default FormChapter;
