import { ActivityContent } from "@hotelspoint/api";
import { GalleryOverlay } from "@hotelspoint/components";
import {
  SearchActivitiesResultsModalState,
  SearchActivitiesResultsModalType,
} from "@hotelspoint/types";
import { ImageType } from "@hotelspoint/utils";

import ActivityDetailsModal from "../ActivityDetailsModal";

interface ActivityDetailsGalleryModalProps {
  id?: number;
  name: string;
  place: string;
  price: number;
  priceNet: number;
  content: ActivityContent[];
  images: string[];
  isLoading: boolean;
  modalType: SearchActivitiesResultsModalType;
  initialGalleryIndex?: number;
  setInitialGalleryIndex?: (index: number) => void;
  setActiveModal: (
    modal: SearchActivitiesResultsModalState | undefined,
  ) => void;
}

const ActivityDetailsGalleryModal = ({
  id,
  name,
  place,
  price,
  priceNet,
  content,
  images,
  isLoading,
  modalType,
  initialGalleryIndex = 0,
  setInitialGalleryIndex,
  setActiveModal,
}: ActivityDetailsGalleryModalProps) => {
  const handleImageClick = (index: number) => {
    setInitialGalleryIndex && setInitialGalleryIndex(index);
    setActiveModal({
      id,
      type: SearchActivitiesResultsModalType.Gallery,
    });
  };

  const handleSelectActivity = () => {
    setActiveModal({
      id,
      type: SearchActivitiesResultsModalType.Book,
    });
  };

  const handleCloseModal = () => {
    setActiveModal(undefined);
  };

  return (
    <>
      <GalleryOverlay
        isOpen={
          modalType === SearchActivitiesResultsModalType.Gallery &&
          images?.length !== 0
        }
        images={images ?? []}
        imageType={ImageType.Activity}
        initialSlideIndex={initialGalleryIndex}
        isLoading={isLoading}
        onClose={handleCloseModal}
      />
      <ActivityDetailsModal
        name={name}
        place={place}
        price={price}
        priceNet={priceNet}
        content={content}
        images={images}
        isLoading={isLoading}
        handleImageClick={handleImageClick}
        handleSelectActivity={handleSelectActivity}
        isOpen={modalType === SearchActivitiesResultsModalType.Details}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default ActivityDetailsGalleryModal;
