import { zIndex } from "@hotelspoint/theme";
import { IconMoodPuzzled } from "@tabler/icons-react";
import {
  Column,
  flexRender,
  getCoreRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import TableSkeleton from "../TableSkeleton";
import * as S from "./Table.styled";

const getCommonPinningStyles = (column: Column<any>): React.CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-4px 0 4px -4px rgba(0,0,0,0.28) inset"
      : isFirstRightPinnedColumn
        ? "4px 0 4px -4px rgba(0,0,0,0.28) inset"
        : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    position: isPinned ? "sticky" : "relative",
    minWidth: column.getSize() !== 0 ? column.getSize() : undefined,
    zIndex: isPinned ? zIndex.tablePinned : undefined,
  };
};

interface TableEmptyState {
  title: string;
  description: string;
}

export interface TableProps<T>
  extends Pick<TableOptions<T>, "data" | "columns"> {
  isLoading?: boolean;
  useReactTableProps?: Omit<
    TableOptions<T>,
    "data" | "columns" | "getCoreRowModel" | "getRowId"
  >;
  emptyState?: Partial<TableEmptyState>;
}

const Table = <T,>({
  data,
  columns,
  isLoading,
  useReactTableProps,
  emptyState,
}: TableProps<T>) => {
  const { t } = useTranslation();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row: T) => (row as any)?.id,
    // https://github.com/TanStack/table/discussions/4179#discussioncomment-3631326
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
    ...useReactTableProps,
  });

  const rows = table.getRowModel().rows;

  return (
    <S.Wrapper>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <S.Table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      style={getCommonPinningStyles(header.column)}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {rows.length !== 0 &&
                rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td
                        key={cell.id}
                        style={getCommonPinningStyles(cell.column)}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </S.Table>
          {rows.length === 0 && (
            <S.EmptyState>
              <IconMoodPuzzled size={32} />
              <div>
                <S.EmptyStateTitle>
                  {emptyState?.title || t("components.table.emptyState.title")}
                </S.EmptyStateTitle>
                <S.EmptyStateDescription>
                  {emptyState?.description ||
                    t("components.table.emptyState.description")}
                </S.EmptyStateDescription>
              </div>
            </S.EmptyState>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default Table;
