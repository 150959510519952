import { ActivityPickupLocation as Locations } from "@hotelspoint/api";
import {
  Box,
  Form,
  FormAdapter,
  FormChildrenMethods,
  FormSelect,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  defaultValues,
  FormValues,
  validationSchema,
} from "./ActivityPickupLocation.util";

interface ActivityPickupLocationProps {
  locations: Locations[];
  onChange: (value: string) => void;
}

interface ActivityPickupLocationPropsInnerProps
  extends FormChildrenMethods<FormValues>,
    ActivityPickupLocationProps {}

const mapLocations = (locations: Locations[]) => {
  return locations.map(location => ({
    label: location.name,
    value: location.code,
  }));
};

const ActivityPickupLocationInner = ({
  locations,
  onChange,
}: ActivityPickupLocationPropsInnerProps) => {
  const { t } = useTranslation();

  const [location] = useWatch({ name: ["locations"] });

  const mappedLocations = useMemo(() => {
    return mapLocations(locations);
  }, [locations]);

  useEffect(() => {
    onChange(location);
  }, [location, onChange]);

  return (
    <>
      <Box width={[1, 1, 1 / 2, 2 / 4]} mb={2}>
        <FormAdapter
          name="locations"
          label={t("searchActivityResult.location.form.label")}
        >
          {props => (
            <FormSelect
              {...props}
              options={mappedLocations}
              isSearchable={false}
            />
          )}
        </FormAdapter>
      </Box>
      {!location && (
        <StatusBlock status={StatusFlavour.Info}>
          <h6>{t("searchActivityResult.location.statusBlock.title")}</h6>
          <p>{t("searchActivityResult.location.statusBlock.description")}</p>
        </StatusBlock>
      )}
    </>
  );
};

const ActivityPickupLocation = ({
  locations,
  onChange,
}: ActivityPickupLocationProps) => {
  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema}>
      {formMethods => (
        <ActivityPickupLocationInner
          {...formMethods}
          locations={locations}
          onChange={onChange}
        />
      )}
    </Form>
  );
};

export default ActivityPickupLocation;
