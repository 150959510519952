import * as S from "./HeaderImage.styled";

interface HeaderImageProps {
  src: string;
  children: React.ReactNode;
  height?: number;
}

const HeaderImage = ({ src, children, height }: HeaderImageProps) => {
  return (
    <S.Wrapper $src={src} $height={height}>
      {children}
    </S.Wrapper>
  );
};

export default HeaderImage;
