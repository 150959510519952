import {
  handleError,
  useAddAdminAreaLocationItem,
  usePlaces,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormChildrenMethods,
  FormContext,
  FormLayout,
  OptionGroup,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import { PlaceType } from "@hotelspoint/types";
import { getPlaceTypeName } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";

import * as S from "./AdminAreaLocationAddItem.styled";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminAreaLocationAddItem.util";
import { PLACE_TYPE_ORDER } from "./constants";

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

interface AdminAreaLocationAddItemProps {
  id: number;
}

interface AdminAreaLocationAddItemInnerProps
  extends AdminAreaLocationAddItemProps,
    FormChildrenMethods<FormValues> {}

const AdminAreaLocationAddItemInner = ({
  id,
  setValue,
  reset,
}: AdminAreaLocationAddItemInnerProps) => {
  const { t } = useTranslation();

  const [addLocation, isAddingLocation] = useAddAdminAreaLocationItem(id);

  const [search] = useWatch({ name: ["search"] });
  const [debouncedSearch] = useDebounce(search, 350);

  const [places, isLoadingPlaces] = usePlaces({
    types: [PlaceType.Destination, PlaceType.City],
    search: debouncedSearch ?? "",
  });

  const placeGroupOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!places || isLoadingPlaces) return [];

    const groups = Object.entries(places)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);

    return groups.sort((a, b) => {
      return (
        PLACE_TYPE_ORDER.indexOf(a.value) - PLACE_TYPE_ORDER.indexOf(b.value)
      );
    });
  }, [t, places, isLoadingPlaces]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await addLocation(payload);
        toast.success(t("toast.adminAreaLocation.locationItem.addLocation"));
        // Reset the form on successful addition
        reset();
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, addLocation, reset],
  );

  const handleSelectPlace = useCallback(
    (option: any) => {
      if (option) {
        setValue("place", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("place", {});
      }
    },
    [setValue],
  );

  return (
    <FormLayout>
      <FormAdapter
        name="search"
        label={t("adminAreaLocation.add.search.label")}
      >
        {props => (
          <Flex mx={[0, 0, -1, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAutoComplete
                {...props}
                placeholder={t("adminAreaLocation.add.search.placeholder")}
                options={placeGroupOptions}
                isLoading={isLoadingPlaces}
                disabled={isAddingLocation}
                renderOption={option => (
                  <S.PlaceOption key={option.value}>
                    <PlaceTypeIcon
                      type={option.meta.type}
                      iconProps={{ size: 22 }}
                    />
                    <span>{option.label}</span>
                  </S.PlaceOption>
                )}
                onSelect={handleSelectPlace}
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormContext<FormValues>
                render={({ handleSubmit }) => (
                  <Button
                    type="submit"
                    variant="secondary"
                    isLoading={isAddingLocation}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("adminAreaLocation.add.submit")}
                  </Button>
                )}
              />
            </Box>
          </Flex>
        )}
      </FormAdapter>
    </FormLayout>
  );
};

const AdminAreaLocationAddItem = ({ id }: AdminAreaLocationAddItemProps) => {
  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      {formMethods => (
        <AdminAreaLocationAddItemInner id={id} {...formMethods} />
      )}
    </Form>
  );
};

export default AdminAreaLocationAddItem;
