import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  ReservationService,
  ReservationUpdateAssigneePayload,
} from "../../services";

const useUpdateReservationAssignee = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["reservations", "update_assignee"],
    mutationFn: (payload: ReservationUpdateAssigneePayload) => {
      return ReservationService.updateAssignee(id, payload);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["reservations", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["reservations", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["reservations"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUpdateReservationAssignee;
