import { toFixedPrecise } from "@hotelspoint/utils";
import { toDecimal } from "dinero.js";

function createFormatter(transformer: any): any {
  return function formatter(dineroObject: any) {
    return toDecimal(dineroObject, transformer);
  };
}

export const format = createFormatter(({ value, currency }: any) => {
  return `${toFixedPrecise(value, 2)} ${currency.code}`;
});
