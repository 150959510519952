import { MessageType, useReservation } from "@hotelspoint/api";
import {
  ReservationInvoicesTable,
  ReservationMessages,
  ReservationPaymentsTable,
} from "@hotelspoint/components";

// @todo: all the components bellow need to be renamed to `ProfileReservation***`
import ProfileReservationBaseInfo from "../ProfileReservationBaseInfo";
import ReservationFlightsInfo from "../ReservationFlightsInfo";

interface ProfileReservationContentProps {
  id: number;
}

const ProfileReservationHotelContent = ({
  id,
}: ProfileReservationContentProps) => {
  const [reservation, isLoadingReservation] = useReservation(id);

  return (
    <>
      <ProfileReservationBaseInfo id={id} />
      {!isLoadingReservation && reservation?.itemData?.hasTransfers && (
        <ReservationFlightsInfo flights={reservation.itemData.flights} />
      )}
      <ReservationPaymentsTable id={id} />
      <ReservationInvoicesTable id={id} />
      {Object.values(MessageType).map(type => (
        <ReservationMessages key={type} id={id} type={type} />
      ))}
    </>
  );
};

export default ProfileReservationHotelContent;
